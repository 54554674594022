import { gql } from '@apollo/client';
import { userContactFragment } from 'graphql/fragments';

const userContactQuery = gql`
  query userContactQuery($_id: ID!, $initializationConfigDate: DateTime) {
    contact(_id: $_id, initializationConfigDate: $initializationConfigDate) {
      ...userContactFragment
    }
  }
  ${userContactFragment}
`;

export default userContactQuery;
