import { useTranslation } from 'react-i18next';
import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

/**
 * Renders the Formik input field for show static items text within global settings.
 * @returns {JSX.Element} Formik input field for static items text
 * @component
 */
const ShowStaticItemsTextFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem
      name="showStaticItemsText"
      label={t('admin.GlobalTexts.globalSettings.inputs.showStaticItemsText.label')}
      tooltip={t('admin.GlobalTexts.globalSettings.inputs.showStaticItemsText.tooltip')}
    >
      <Input name="showStaticItemsText" />
    </FormItem>
  );
};

export default ShowStaticItemsTextFormikInput;
