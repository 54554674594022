import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * First name field for contact form
 * @returns {JSX.Element} A field to enter the contact first name
 */
const ContactFirstNameField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="firstName"
      label={t('ClientsListPage.addContactModal.form.firstName.label')}
      placeholder={t('ClientsListPage.addContactModal.form.firstName.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ContactFirstNameField;
