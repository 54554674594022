import { Transforms, Node, Element as SlateElement } from 'slate';

const REMOVING_STYLE_OPTIONS = [
  'bulleted-list',
  'heading-one',
  'heading-three',
  'heading-two',
  'list-item',
  'numbered-list',
  'title',
  'paragraph',
  'div',
];

const removeStylingElements = (value) => {
  const flatArray = [];
  const processFn = (element) => {
    if (element.type === 'mention') {
      const newElement = {};
      Object.assign(newElement, element, { bold: false, italic: false, underline: false });
      flatArray.push(newElement);
    } else if (REMOVING_STYLE_OPTIONS.includes(element.type)) flatArray.push('\n');
    if (typeof element.text === 'string') flatArray.push(element.text);
    element.children?.forEach((child) => processFn(child));
  };
  value.forEach((element) => processFn(element));
  return flatArray;
};

const mergeStrings = (flattedArray) => {
  const jsonArr = [];
  flattedArray.forEach((element) => {
    const lastEl = jsonArr[jsonArr.length - 1];

    if (element === '\n' && (!jsonArr.length || lastEl?.endsWith?.('\n'))) return;
    if (typeof lastEl === 'string' && typeof element === 'string') {
      jsonArr[jsonArr.length - 1] = lastEl + element;
    } else jsonArr.push(element);
  });
  return jsonArr;
};

const getParagraph = (paragraph, rootElement) => ({
  type: rootElement,
  children: [
    {
      text: paragraph,
    },
  ],
});

const mapStringsToObject = (jsonArray, rootElement) => {
  const resultArray = [];
  jsonArray.forEach((element) => {
    if (typeof element === 'string') {
      resultArray.push(
        ...element
          .split('\n')
          .filter((e) => e.length)
          .map((paragraph) => getParagraph(paragraph, rootElement)),
      );
    } else if (typeof element === 'object' && element.type === 'mention') {
      resultArray.push({
        type: rootElement,
        children: [element],
      });
    } else resultArray.push(element);
  });
  return resultArray;
};

export const resetRichText = ({ value, rootElement = 'div' }) => {
  const flattedArray = removeStylingElements(value);
  const jsonArray = mergeStrings(flattedArray);
  const resultArray = mapStringsToObject(jsonArray, rootElement);

  if (resultArray.length === 0) return [getParagraph('')];

  return resultArray;
};

export const getSelectionRootBlocks = (editor) => {
  const arr = [editor?.selection?.anchor?.path?.[0] || 0, editor?.selection?.focus?.path?.[0] || 0];
  const min = Math.min(...arr);
  const max = Math.max(...arr);
  return {
    anchor: { offset: 0, path: [min, 0] },
    focus: { offset: 9999, path: [max, 9999] },
  };
};

/**
 * Add a tab space at the current cursor position.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {void} Returns nothing.
 */
export const addTabSpace = (editor) => {
  editor.insertText('\t');
};

/**
 * Remove the last tab space in the current block.
 * @param {Editor} editor - The Slate editor instance.
 * @param {Path} path - The path to the current block.
 * @returns {void} Returns nothing.
 */
export const removeLastTabSpace = (editor, path) => {
  const currentBlock = Node.get(editor, path);
  const { text } = currentBlock.children[0];
  const lastTabIndex = text.lastIndexOf('\t');
  if (lastTabIndex !== -1) {
    Transforms.delete(editor, {
      at: { path: [...path, 0], offset: lastTabIndex },
      distance: 1,
    });
  }
};

/**
 * Focus the next input, textarea, or contenteditable element in the form.
 * @param {Event} event - The event object.
 * @returns {void} Returns nothing.
 */
export const focusNextField = (event) => {
  const form = event.target.closest('form');
  if (!form) return;
  const fields = Array.from(form.querySelectorAll('input, textarea, [contenteditable="true"]'));
  const index = fields.indexOf(event.target);
  if (index > -1 && index < fields.length - 1) {
    fields[index + 1].focus();
  }
};

/**
 * Focus the previous input, textarea, or contenteditable element in the form.
 * @param {Event} event - The event object.
 * @returns {void} Returns nothing.
 */
export const focusPreviousField = (event) => {
  const form = event.target.closest('form');
  if (!form) return;
  const fields = Array.from(form.querySelectorAll('input, textarea, [contenteditable="true"]'));
  const index = fields.indexOf(event.target);
  if (index > 0) {
    fields[index - 1].focus();
  }
};

/**
 * Check if the current block is a list block.
 * @param {Object} currentBlock - The current block object.
 * @returns {boolean} Returns true if the block is a list block, otherwise false.
 */
export const isListBlock = (currentBlock) => {
  return currentBlock && (currentBlock.type === 'list-item' || currentBlock.type === 'checked-list');
};

/**
 * Insert a soft break (newline) at the current cursor position.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {void} Returns nothing.
 */
export const insertSoftBreak = (editor) => {
  editor.insertText('\n');
};

/**
 * Delete block at the current cursor position on backspace.
 * @reference https://github.com/ianstormtaylor/slate/issues/3456#issuecomment-635985399
 *
 * @param {Editor} editor - The Slate editor instance.
 * @param {Event} event - The event object.
 * @param {Range} selection - The current selection range.
 * @returns {void} Returns nothing.
 */
export const deleteBlockOnBackspace = (editor, event, selection) => {
  const parent = Node.parent(editor, selection.anchor.path);
  if (SlateElement.isElement(parent) && editor.isVoid(parent)) {
    event.preventDefault();
    editor.deleteBackward('block');
  }
};
