import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FileTextOutlined } from '@ant-design/icons';
import Card from 'components/common/Card';
import PageContainer from 'components/layout/PageContainer';
import GlobalTextsInputFields from './GlobalTextsInputFields';

/**
 * Renders the page for managing global texts in the admin settings, including form input fields for global label texts.
 * @returns {JSX.Element} Page containing the global text management form
 * @component
 */
const GlobalTexts = () => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.GlobalTexts.title')}>
      <Card icon={<FileTextOutlined />} title={t('admin.GlobalTexts.globalSettings.title')}>
        <GlobalTextsInputFields />
      </Card>
    </PageContainer>
  );
};
export default memo(GlobalTexts, equal);
