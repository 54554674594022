import AddButton from 'components/common/AddButton';
import { useTranslation } from 'react-i18next';
import InsertContactForm from './InsertContactForm';
import ContactSelect from './ContactSelect';

/**
 * Component that displays the content for inserting a new contact associated with a client.
 * @param {object} props - The properties passed to the component.
 * @param {object} props.formikRef - Reference to Formik for form control and submission.
 * @param {function} props.setSubmitting - Function to set the submitting state.
 * @param {object} props.errorRef - Reference to handle form error state.
 * @param {function} props.onClickHandle - Handler for the button click to add a new contact.
 * @param {string} props.clientId - ID of the client for which the contact is being added.
 * @returns {JSX.Element} The rendered InsertContactContent component.
 */
const InsertContactContent = ({ formikRef, setSubmitting, errorRef, onClickHandle, clientId, setContactSelected }) => {
  const { t } = useTranslation();

  return (
    <>
      <InsertContactForm formikRef={formikRef} setSubmitting={setSubmitting} errorRef={errorRef} clientId={clientId}>
        <ContactSelect clientId={clientId} setContactSelected={setContactSelected} />
      </InsertContactForm>
      <AddButton onClick={onClickHandle} text={t('ClientsListPage.addContactModal.insertNewContactButton')} />
    </>
  );
};

export default InsertContactContent;
