import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormikForm from 'components/common/FormComponents/Formik/FormikForm';
import addNewContact from 'ProjectCardManagement/ClientManagement/methods/addNewContact';
import editContact from 'ProjectCardManagement/ClientManagement/methods/editContact';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import useContact from 'ProjectCardManagement/ClientManagement/hooks/useContact';

/**
 * Schema for new contact form
 * @returns {Yup.ObjectSchema<object>}
 */
const ContactSchema = (t) =>
  Yup.object().shape({
    firstName: Yup.string().required(t('ClientsListPage.addContactModal.form.firstName.required')),
    lastName: Yup.string().required(t('ClientsListPage.addContactModal.form.lastName.required')),
    email: Yup.string().email().required(t('ClientsListPage.addContactModal.form.email.required')),
  });

/**
 * Formik form component for adding a new contact, which renders the fields got as childrens
 * @param {object} inputProperties - Input properties of the component
 * @param {React.RefObject} inputProperties.formikRef - Formik reference
 * @param {Function} inputProperties.setSubmitting - Set submitting function
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @returns {JSX.Element} AddOrEditContactForm component
 */
const AddOrEditContactForm = ({ children, setSubmitting, formikRef, errorRef, contactId, clientId }) => {
  const { t } = useTranslation();
  const { contact } = useContact(contactId);
  const [submitError, setSubmitError] = useState(null);

  const initialValues = useMemo(
    () => ({
      clientId: clientId || null,
      _id: contact?._id,
      salutation: contact?.salutation || '',
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      fullSalutation: contact?.fullSalutation || '',
      email: contact?.email || '',
      position: contact?.position || '',
      phoneNumber: contact?.phoneNumber || '',
      phoneNumberAlternative: contact?.phoneNumberAlternative || '',
    }),
    [clientId, contact],
  );

  const contactSchema = useMemo(() => ContactSchema(t), [t]);
  const onSubmit = useCallback(
    (values) => {
      const submitMethod = contactId ? editContact : addNewContact;
      return submitMethod(values)
        .then(setSubmitting(false))
        .catch((error) => {
          setSubmitError(error);
          throw error;
        });
    },
    [setSubmitting, contactId],
  );

  useEffect(() => {
    if (errorRef && !errorRef.current) {
      // eslint-disable-next-line no-param-reassign
      errorRef.current = { resetError: () => setSubmitError(null) };
    }
  }, [errorRef]);

  return (
    <FormikForm
      formikRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={contactSchema}
      enableReinitialize
    >
      <QueryErrorAlert error={submitError} />
      {children}
    </FormikForm>
  );
};

export default AddOrEditContactForm;
