import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal/Modal';
import AddOrEditContactContent from './AddContactModal/AddOrEditContactContent';
import InsertContactContent from './AddContactModal/InsertContactContent';
import classes from './AddClientModal.module.less';

/**
 * Determines the title of the modal based on the selection form state and the contact ID.
 * @param {function} t - The translation function used for text localization.
 * @param {boolean} isSelectionForm - A flag indicating if the form is in selection mode.
 * @param {string} [contactId] - The ID of the contact being edited. If provided, the modal is in edit mode.
 * @returns {string} The localized title string for the modal.
 */
const getTitle = (t, isSelectionForm, contactId) => {
  if (isSelectionForm) return t('ClientsListPage.addContactModal.insertTitle');
  if (contactId) return t('ClientsListPage.addContactModal.editTitle');
  return t('ClientsListPage.addContactModal.addTitle');
};

/**
 * Determines the text for the OK button based on the selection form state.
 * @param {function} t - The translation function used for text localization.
 * @param {boolean} isSelectionForm - A flag indicating if the form is in selection mode.
 * @returns {string} The localized text for the OK button.
 */
const getOkText = (t, isSelectionForm) => {
  if (isSelectionForm) return t('ClientsListPage.addContactModal.insertContactButton');
  return t('ClientsListPage.addContactModal.okButton');
};

/**
 * Determines if the OK button should be disabled based on the selection form state and whether a contact is selected.
 * @param {boolean} isSelectionForm - A flag indicating if the form is in selection mode.
 * @param {boolean} contactSelected - A flag indicating whether a contact has been selected.
 * @returns {boolean} Returns `true` if the OK button should be disabled, otherwise `false`.
 */
const isOkButtonDisabled = (isSelectionForm, contactSelected) => {
  if (isSelectionForm && !contactSelected) return true;
  return false;
};

/**
 * Handles the logic for the "OK" button click.
 * Submits the form, validates the data, and resets the form if successful.
 * @param {object} formikRef - Reference to the Formik instance.
 * @param {object} errorRef - Reference to the error handler.
 * @param {function} setSubmitting - Function to update the submitting state.
 * @param {function} setIsSelectionForm - Function to update the selection form state.
 * @param {function} closeModal - Function to close the modal.
 * @param {string} [contactId] - The ID of the contact being edited, if applicable.
 */
const handleOk = async (formikRef, errorRef, setSubmitting, setIsSelectionForm, closeModal, contactId) => {
  if (!formikRef.current) {
    return;
  }
  setSubmitting(true);
  try {
    await formikRef.current.submitForm();
    if (!formikRef.current.isValid) {
      setSubmitting(false);
      return;
    }
    errorRef.current.resetError();
    formikRef.current.resetForm();
    if (!contactId) setIsSelectionForm(true);
    closeModal();
  } catch (err) {
    // not empty
  }
};

/**
 * This component displays a modal dialog for inserting, adding or editing a contact associated with a client.
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.visible - Determines if the modal is visible.
 * @param {function} props.closeModal - Function to close the modal.
 * @param {string} props.clientId - ID of the client associated with the contact.
 * @param {string} props.contactId - ID of the contact to be edited. If provided, the modal defaults to editing a contact.
 * @returns {JSX.Element} The rendered AddOrEditContactModal component.
 */
const AddOrEditContactModal = ({ visible, closeModal, clientId, contactId }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [isSelectionForm, setIsSelectionForm] = useState(!contactId);
  const [contactSelected, setContactSelected] = useState();
  const formikRef = useRef();
  const errorRef = useRef();

  return (
    <Modal
      visible={visible}
      className={classes.Modal}
      title={getTitle(t, isSelectionForm, contactId)}
      okText={getOkText(t, isSelectionForm)}
      okButtonProps={{ disabled: isOkButtonDisabled(isSelectionForm, contactSelected) }}
      onCancel={() => {
        if (!contactId) setIsSelectionForm(true);
        if (submitting) return;
        if (formikRef.current) {
          errorRef.current.resetError();
          formikRef.current.resetForm();
        }
        closeModal();
      }}
      onOk={() => handleOk(formikRef, errorRef, setSubmitting, setIsSelectionForm, closeModal, contactId)}
      confirmLoading={submitting}
    >
      {isSelectionForm ? (
        <InsertContactContent
          formikRef={formikRef}
          errorRef={errorRef}
          setSubmitting={setSubmitting}
          onClickHandle={() => setIsSelectionForm(false)}
          clientId={clientId}
          setContactSelected={setContactSelected}
        />
      ) : (
        <AddOrEditContactContent
          formikRef={formikRef}
          errorRef={errorRef}
          setSubmitting={setSubmitting}
          contactId={contactId}
          clientId={clientId}
        />
      )}
    </Modal>
  );
};

export default AddOrEditContactModal;
