import { useMemo } from 'react';
import { adminGlobalTenantSettingsQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';

/**
 * Custom hook to retrieve dynamic label texts from global tenant settings.
 * Fetches tenant data using a cached GraphQL query and extracts label texts.
 * @returns {object} labelTexts - An object containing label texts from global tenant settings
 * @component
 */
const useDynamicTexts = () => {
  const { data: tenantData } = useCachedQuery(adminGlobalTenantSettingsQuery);
  const globalSettings = grabFirstGQLDataResult(tenantData);
  const labelTexts = useMemo(() => globalSettings?.labelTexts ?? {}, [globalSettings]);

  return labelTexts;
};

export default useDynamicTexts;
