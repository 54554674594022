import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classes from './AddButton.module.less';

/**
 * A button component that renders a "+" icon with customizable text.
 * @component
 * @param {object} props - Component properties
 * @param {string} props.text - The text displayed on the button
 * @param {function} props.onClick - Click handler function for the button
 * @returns {JSX.Element} The rendered add button component
 */
const AddButton = ({ text, onClick }) => {
  return (
    <Button
      type="link"
      className={classes.addButton}
      icon={<PlusOutlined className={classes.icon} />}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default AddButton;
