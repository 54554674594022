import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';
import apollo from 'graphql/apollo';
import { updateCompanyMutation } from 'graphql/mutations';
import { userCompanyQuery, userContactsQuery } from 'graphql/queries';
import i18n from 'i18n';

/**
 * Adds a contact to the specified client's contact list remotely
 * @param {object} contactData - Data required for inserting the contact
 * @param {string} contactData.clientId - ID of the client to update
 * @param {string} contactData.contactId - ID of the contact to insert
 * @param {string[]} contactData.contacts - Array of existing contact IDs for the client
 * @returns {Promise<void>} A promise that resolves when the contact is added or rejects with an error
 */
const insertContact = async ({ clientId, contactId, contacts }) => {
  return apollo
    .mutate({
      mutation: updateCompanyMutation,
      variables: {
        _id: clientId,
        modifier: {
          contactIds: [...contacts, contactId],
        },
      },
      refetchQueries: [
        {
          query: userCompanyQuery,
          variables: { _id: clientId },
        },
        {
          query: userContactsQuery,
        },
      ],
    })
    .then(() => {
      message.success(
        generateMessageConfig(i18n.t('ClientsListPage.addContactModal.successMessageInsert'), 'addClientSuccess', 5),
      );
    });
};

export default insertContact;
