import { useState, useEffect } from 'react';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import ViewDocumentsAndSignContent from './ViewDocumentsAndSign/ViewDocumentsAndSignContent';
import WaitForDocuments from './ViewDocumentsAndSign/WaitForDocuments';

/**
 * ViewDocumentsAndSign component is a step component for view and sign documents in the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue and onBack function of the step
 * @param {Boolean} inputParameters.hasDocumentGenerationTriggered - Has a previous step triggered the generation of new documents
 * @param {Object} inputParameters.stepState - Saved state of the step
 * @param {Function} inputParameters.updateCurrentStepState - Function to update current step state
 * @returns {JSX.Element} step for view and sign documents in the signing process
 */
const ViewDocumentsAndSign = ({
  submitReference,
  hasDocumentGenerationTriggered,
  stepState,
  updateCurrentStepState,
}) => {
  const [threeSecondLoadingState, setThreeSecondLoadingState] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const { shoppingCart } = useSigningShoppingCart();
  const { pdfs } = shoppingCart || { pdfs: [] };
  const hasUnfinishedPdfs = pdfs.find((document) => document.isGenerating);

  useEffect(() => {
    const timer = setTimeout(() => {
      setThreeSecondLoadingState(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hasDocumentGenerationTriggered || hasUnfinishedPdfs) {
      setLoadingDocuments(true);
    }
  }, [hasDocumentGenerationTriggered, hasUnfinishedPdfs]);

  if (threeSecondLoadingState || loadingDocuments)
    return <WaitForDocuments loadingDocuments={loadingDocuments} setLoadingDocuments={setLoadingDocuments} />;

  return (
    <ViewDocumentsAndSignContent
      submitReference={submitReference}
      stepState={stepState}
      updateCurrentStepState={updateCurrentStepState}
    />
  );
};

export default ViewDocumentsAndSign;
