import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';
import apollo from 'graphql/apollo';
import { createContactMutation } from 'graphql/mutations';
import { userCompanyQuery } from 'graphql/queries';
import i18n from 'i18n';

/**
 * Adds a new contact to the tenant instance remotely
 * @param {object} contactValues - Data of the new contact
 * @param {string} contactValues.salutation - Salutation of the contact
 * @param {string} contactValues.firstName - First name of the contact (required)
 * @param {string} contactValues.lastName - Last name of the contact (required)
 * @param {string} contactValues.fullSalutation - Full salutation of the contact
 * @param {string} contactValues.email - Email address of the contact (required)
 * @param {string} contactValues.position - Position or title of the contact
 * @param {string} contactValues.phoneNumber - Primary phone number of the contact
 * @param {string} contactValues.phoneNumberAlternative - Alternative phone number of the contact
 * @param {string} contactValues.clientId - Client id of the contact
 * @returns {Promise<void>} A promise that resolves when the contact is added or rejects with an error
 */
const addNewContact = async ({
  salutation,
  firstName,
  lastName,
  fullSalutation,
  email,
  position,
  phoneNumber,
  phoneNumberAlternative,
  clientId,
}) => {
  return apollo
    .mutate({
      mutation: createContactMutation,
      variables: {
        contact: {
          salutation,
          firstName,
          lastName,
          fullSalutation,
          email,
          position,
          phoneNumber,
          phoneNumberAlternative,
        },
        _id: clientId,
      },
      refetchQueries: [
        {
          query: userCompanyQuery,
          variables: { _id: clientId },
        },
      ],
    })
    .then(() => {
      message.success(
        generateMessageConfig(i18n.t('ClientsListPage.addContactModal.successMessageAdd'), 'addClientSuccess', 5),
      );
    });
};

export default addNewContact;
