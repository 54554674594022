import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * Email field for contact form
 * @returns {JSX.Element} A field to enter the contact email
 */
const ContactEmailField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="email"
      label={t('ClientsListPage.addContactModal.form.email.label')}
      placeholder={t('ClientsListPage.addContactModal.form.email.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ContactEmailField;
