import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import Select from 'components/common/FormComponents/Formik/Select';
import salutations from 'constants/salutations';

const { Option } = Select;

/**
 * Contact salutation field for the add contact form
 * @returns {JSX.Element} A radio button group to select the contact salutation
 * @component
 */
const ContactSalutationSelect = () => {
  const { t } = useTranslation();
  const mapSalutations = Object.values(salutations)
    .map((e) => ({
      _id: e,
      label: e ? t(`ClientsListPage.addContactModal.form.salutation.options.${e}`) : '',
    }))
    .filter(({ _id }) => !(t('localeCode') === 'de' && _id === 'Mrs'));
  return (
    <FormItem name="salutation" label={t('ClientsListPage.addContactModal.form.salutation.label')}>
      <Select name="salutation" placeholder={t('ClientsListPage.addContactModal.form.salutation.placeholder')}>
        {mapSalutations.map(({ _id, label }) => (
          <Option value={_id} key={_id}>
            {label}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default ContactSalutationSelect;
