import { gql } from '@apollo/client';
import { userContactFragment } from 'graphql/fragments';

const userContactsQuery = gql`
  query userContactsQuery($initializationConfigDate: DateTime) {
    contacts(initializationConfigDate: $initializationConfigDate) {
      ...userContactFragment
    }
  }
  ${userContactFragment}
`;

export default userContactsQuery;
