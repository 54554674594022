import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { Form } from 'formik-antd';
import { updateTenantSettings } from 'graphql/methods';
import useDynamicTexts from 'graphql/hooks/useDynamicTexts';
import StaticItemsTextFormikInput from './StaticItemsTextFormikInput';
import ShowStaticItemsTextFormikInput from './ShowStaticItemsTextFormikInput';

/**
 * Renders the input fields for managing global texts within tenant settings.
 * @returns {JSX.Element} Form with input fields for editing texts in global settings
 * @component
 */
const GlobalTextsInputFields = () => {
  const { t } = useTranslation();
  const labelTexts = useDynamicTexts();
  const initialValues = useMemo(
    () => ({
      staticItemsText:
        labelTexts?.staticItemsText || t('admin.GlobalTexts.globalSettings.inputs.staticItemsText.default'),
      showStaticItemsText:
        labelTexts?.showStaticItemsText || t('admin.GlobalTexts.globalSettings.inputs.showStaticItemsText.default'),
    }),
    [labelTexts, t],
  );

  const onSubmit = useCallback(async ({ showStaticItemsText, staticItemsText }) => {
    const labelTextsObject = {
      showStaticItemsText,
      staticItemsText,
    };
    await updateTenantSettings({
      settings: {
        labelTexts: labelTextsObject,
      },
    });
  }, []);

  return (
    <GraphQLLoadingWrapper data={initialValues}>
      <I18nFormik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        <Form layout="vertical">
          <AlertFromFormik />
          <StaticItemsTextFormikInput />
          <ShowStaticItemsTextFormikInput />
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </GraphQLLoadingWrapper>
  );
};

export default GlobalTextsInputFields;
