import { useQuery } from '@apollo/client';
import { userContactQuery } from 'graphql/queries';

/**
 * Custom hook to fetch contact information by contact ID
 * @param {string} contactId - The ID of the contact to fetch
 * @param {string} [fetchPolicy='cache-and-network'] - The Apollo fetch policy for the query
 * @returns {object} An object containing the contact data, loading state, and any error that occurred
 * @returns {object|null} return.contact - The contact data or null if not found
 * @returns {boolean} return.loading - Loading state of the query
 * @returns {object|null} return.error - Error object if the query fails, otherwise null
 */
const useContact = (contactId, fetchPolicy = 'cache-and-network') => {
  if (!contactId) {
    return { contact: null, error: null, loading: false };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, error, loading } = useQuery(userContactQuery, { variables: { _id: contactId }, fetchPolicy });
  const contact = data?.contact;
  return { contact, error, loading };
};

export default useContact;
