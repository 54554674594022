import { gql } from '@apollo/client';

const adminLabelTextsFragment = gql`
  fragment adminLabelTextsFragment on LabelTexts {
    staticItemsText
    showStaticItemsText
  }
`;

export default adminLabelTextsFragment;
