import { useTranslation } from 'react-i18next';
import ContactCard from './ContactCard';

/**
 * List of contacts of a client
 * @param {object} inputProperties - The input properties for the component
 * @param {object[]} inputProperties.contacts - The contacts to display
 * @param {string} inputProperties.clientId - The ID of the client whose contacts are being displayed
 * @returns {JSX.Element} List of contacts of client
 * @component
 */
const ContactList = ({ contacts, clientId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });

  return (
    <>
      {contacts.length === 0 && <p>{t('noContacts')}</p>}
      {contacts.map((contact) => (
        <ContactCard key={contact._id} contact={contact} clientId={clientId} />
      ))}
    </>
  );
};

export default ContactList;
