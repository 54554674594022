import PhoneNumberOriginal from '@uplab/antd-country-phone-input';
import i18n from 'i18n';
import FormItem from './FormItem';
import usePhoneNumber from './usePhoneNumber';
import classes from './PhoneInput.module.less';

/**
 * PhoneInput component for rendering a phone number input field with optional tooltip, label, and styling.
 * @param {object} props - Component properties
 * @param {string} props.name - Name of the form item, used as a key for the field
 * @param {string} props.label - Label text for the phone input field
 * @param {string} [props.tooltip] - Tooltip text for additional information, displayed with the label
 * @param {boolean} [props.labelUnderlined] - Flag to display the label with an underline style
 * @param {object} [props.inputProps] - Additional properties to pass to the phone input field
 * @param {object} [props.formItemProps] - Additional properties to pass to the form item container
 * @returns {JSX.Element} PhoneInput - A styled input field component for phone numbers
 * @component
 */
const PhoneInput = ({ name, label, tooltip, labelUnderlined, inputProps, formItemProps }) => {
  const { phoneValue, onChange } = usePhoneNumber(name);

  return (
    <FormItem name={name} label={label} tooltip={tooltip} labelUnderlined={labelUnderlined} {...formItemProps}>
      <PhoneNumberOriginal
        value={phoneValue}
        lang={i18n.language}
        inputProps={{
          ...inputProps,
        }}
        containerProps={{ className: classes.phoneInput }}
        onChange={onChange}
      />
    </FormItem>
  );
};

export default PhoneInput;
