import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import ContactList from './ContactList';
import AddContactButton from '../../AddContactModal/AddContactButton';
import classes from './ContactList.module.less';

/**
 * Contact details card
 * @param {object} inputProperties - The input properties for the component
 * @param {object[]} inputProperties.contacts - The contacts to display
 * @param {object[]} inputProperties.clientId - The current clientId
 * @returns {JSX.Element} Contact details card
 * @component
 */
const ContactsSection = ({ contacts, clientId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });

  return (
    <div className={classes.contact_list}>
      <h3>{t('label.contactPersons')}</h3>
      <div className={concatClassNames(classes.contact_list_list, 'custom-scrollbar')}>
        {contacts !== undefined && <ContactList contacts={contacts} clientId={clientId} />}
        <AddContactButton clientId={clientId} />
      </div>
    </div>
  );
};

export default ContactsSection;
