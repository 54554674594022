import SelectWithLabel from 'components/common/FormComponents/Formik/SelectWithLabel';
import { useFormikContext } from 'formik';
import { Select } from 'formik-antd';
import useContactsNotInCompany from 'ProjectCardManagement/ClientManagement/hooks/useContactsNotInCompany';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * A dropdown select component for choosing a contact that is not already associated with a specific client.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.clientId - ID of the client for which to retrieve contacts not yet associated.
 * @returns {JSX.Element} The rendered ContactSelect component.
 */
const ContactSelect = ({ clientId, setContactSelected }) => {
  const { contactsNotInCompany, loading } = useContactsNotInCompany(clientId);
  const { t } = useTranslation();
  const { values } = useFormikContext();

  useEffect(() => {
    if (values?.contactId) {
      setContactSelected(true);
    } else {
      setContactSelected(false);
    }
  }, [setContactSelected, values]);

  return (
    <SelectWithLabel
      name="contactId"
      label={t('ClientsListPage.addContactModal.form.insertContact.label')}
      placeholder={t('ClientsListPage.addContactModal.form.insertContact.placeholder')}
      inputProps={{
        loading,
        disabled: loading,
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'title',
      }}
    >
      {loading ||
        contactsNotInCompany?.map(({ _id, firstName, lastName }) => (
          <Option key={_id} value={_id} title={firstName}>
            {`${firstName} ${lastName}`}
          </Option>
        ))}
    </SelectWithLabel>
  );
};
export default ContactSelect;
