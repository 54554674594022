import { useCallback, useEffect, useMemo, useState } from 'react';
import FormikForm from 'components/common/FormComponents/Formik/FormikForm';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import insertContact from 'ProjectCardManagement/ClientManagement/methods/insertContact';
import useClient from 'ProjectCardManagement/ClientManagement/hooks/useClient';

/**
 * Formik-based form component for inserting a contact associated with a client.
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - Elements to be rendered inside the form.
 * @param {function} props.setSubmitting - Function to set the submitting state.
 * @param {object} props.formikRef - Reference to Formik for form control and submission.
 * @param {object} props.errorRef - Reference to handle form error state.
 * @param {string} props.clientId - ID of the client associated with the contact.
 * @returns {JSX.Element} The rendered InsertContactForm component.
 */
const InsertContactForm = ({ children, setSubmitting, formikRef, errorRef, clientId }) => {
  const [submitError, setSubmitError] = useState(null);
  const { client } = useClient(clientId);

  const initialValues = useMemo(
    () => ({
      clientId: clientId || null,
      contacts: client?.contacts.map((contact) => contact._id) || [],
      contactId: '',
    }),
    [clientId, client],
  );

  const onSubmit = useCallback(
    (values) => {
      const submitMethod = insertContact;
      return submitMethod(values)
        .then(setSubmitting(false))
        .catch((error) => {
          setSubmitError(error);
          setSubmitting(false);
          throw error;
        });
    },
    [setSubmitting],
  );

  useEffect(() => {
    if (errorRef && !errorRef.current) {
      // eslint-disable-next-line no-param-reassign
      errorRef.current = { resetError: () => setSubmitError(null) };
    }
  }, [errorRef]);

  return (
    <FormikForm formikRef={formikRef} onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      <QueryErrorAlert error={submitError} />
      {children}
    </FormikForm>
  );
};

export default InsertContactForm;
