import { useTranslation } from 'react-i18next';
import PhoneInput from 'components/common/FormComponents/Formik/PhoneInput';

/**
 * Phone number field for contact form
 * @returns {JSX.Element} A field to enter the contact phone number
 */
const ContactPhoneNumberAlternativeField = () => {
  const { t } = useTranslation();
  return (
    <PhoneInput
      name="phoneNumberAlternative"
      label={t('ClientsListPage.addContactModal.form.phoneNumberAlternative.label')}
      placeholder={t('ClientsListPage.addContactModal.form.phoneNumberAlternative.placeholder')}
    />
  );
};

export default ContactPhoneNumberAlternativeField;
