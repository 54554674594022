import { gql } from '@apollo/client';
import userInputFieldFragment from './userInputFieldFragment';

const userItemFragment = gql`
  fragment userItemFragment on CategoryItem {
    _id
    _apolloCacheKey
    active
    benefits
    calculationMode
    companyTypeIds
    descriptionForContract
    graduatedScaleMode
    highlightBindingness
    infoText
    minPrice
    maxPrice
    name
    notesForFeeAgreement
    notesToEmployee
    paymentInterval
    pleaseNote
    pricingFormula
    roundPriceId
    scalePricingFormulaTitle
    scaleTitle
    scalesEnabled
    subTitle
    recursiveFormulaInfo {
      _id
      _apolloCacheKey
      usedItemIdsInFormula
      usedIdsInFormula
      usedCategoryTotalIds
      usedCategoryTotalItemIds
      formulaRequiredIds
      formulaRequiredItemIds
      moreInfo
    }
    scales {
      _id
      _apolloCacheKey
      value
      pricingFormula
    }
    inputFields {
      ...userInputFieldFragment
    }
    pricingFormulaExtended {
      _id
      _apolloCacheKey
      raw
      formatted
      styledFormatted
      inputFields {
        _id
        name
      }
    }
  }
  ${userInputFieldFragment}
`;

export default userItemFragment;
