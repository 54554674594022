import { useTranslation } from 'react-i18next';
import PhoneInput from 'components/common/FormComponents/Formik/PhoneInput';

/**
 * Phone number field for contact form
 * @returns {JSX.Element} A field to enter the contact phone number
 */
const ContactPhoneNumberField = () => {
  const { t } = useTranslation();
  return (
    <PhoneInput
      name="phoneNumber"
      label={t('ClientsListPage.addContactModal.form.phoneNumber.label')}
      placeholder={t('ClientsListPage.addContactModal.form.phoneNumber.placeholder')}
    />
  );
};

export default ContactPhoneNumberField;
