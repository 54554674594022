import { gql } from '@apollo/client';
import userViewShoppingCartInputFieldFragment from './userViewShoppingCartInputFieldFragment';

const userViewShoppingCartItemFragment = gql`
  fragment userViewShoppingCartItemFragment on CategoryItem {
    _id
    _apolloCacheKey
    calculationMode
    highlightBindingness
    minPrice
    maxPrice
    name
    notesToEmployee
    paymentInterval
    minPrice
    inputFields {
      ...userViewShoppingCartInputFieldFragment
    }
  }
  ${userViewShoppingCartInputFieldFragment}
`;

export default userViewShoppingCartItemFragment;
