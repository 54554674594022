import { RichEditorField } from 'components/common/RichEditor';
import { t } from 'i18next';

/**
 * Richtext editor with formik for file name inputs
 * @param {object} props - Input properties of the component
 * @returns {JSX.Element} Rich editor field for file name inputs with formik support
 * @component
 */
export const DocumentFileNameRichEditor = ({ ...props }) => {
  const modifyPlaceholderSchema = (placeholderSchema) => {
    const allowedFields = [
      'shoppingCartCompanyOrFullname',
      'tenantCompanyName',
      'tenantCompanyCurrentUserName',
      'documentNumber',
      'documentCreatedAt',
      'currentDate',
      'projectName',
    ];

    const filteredPlaceholderSchema = placeholderSchema.map((element) => {
      if (element.fields) {
        return {
          ...element,
          fields: element.fields.filter((field) => allowedFields.includes(field.name)),
        };
      }
      return element;
    });

    const nonEmptySchemas = filteredPlaceholderSchema.filter((element) => element.fields && element.fields.length > 0);

    return nonEmptySchemas;
  };

  return (
    <RichEditorField
      allowedModifiers={[]}
      modifyPlaceholderSchema={modifyPlaceholderSchema}
      {...props}
      showPdfFileEnding
      placeholder={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.fileNameDefaultPlaceholder')}
      name="fileName"
      rootElement="paragraph"
    />
  );
};
