import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'utils/toast';

/**
 * Perform a health check for the application.
 * @returns {Promise<boolean>} true if the application is healthy, false otherwise
 */
const checkHealth = async () => {
  try {
    const response = await fetch('/health');
    return response.status === 200;
  } catch (error) {
    console.error('Health check failed:', error);
    return false;
  }
};

/**
 * Calculate the next check time based on the number of previous checks
 * @param {number} checkCount - The number of checks performed so far
 * @returns {number} The number of seconds to wait before the next check
 */
const getNextCheckTime = (checkCount) => {
  if (checkCount < 6) return 10;
  if (checkCount < 12) return 20;
  return 60;
};

/**
 * A hook to check if the application is healthy.
 *
 * It will keep checking the application health until it is healthy.
 * If the application is healthy, it will navigate to the specified redirectUrl.
 * If the application is not healthy, it will show a countdown until the next health check.
 *
 * @returns {{
 *   isChecking: boolean,
 *   countdown: number,
 *   handleButtonClick: () => void,
 * }} an object containing the health check state
 */
const useHealthCheck = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [lastCheckTime, setLastCheckTime] = useState(0);
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const attemptReload = useCallback(() => {
    const redirectUrl = searchParams.get('redirect') || '/';
    navigate(redirectUrl);
  }, [navigate, searchParams]);

  const runHealthCheck = useCallback(async () => {
    setIsChecking(true);
    const isHealthy = await checkHealth();

    if (isHealthy) {
      attemptReload();
    } else {
      setCheckCount((prevCount) => prevCount + 1);
      setIsChecking(false);
      setLastCheckTime(Date.now());
    }
  }, [attemptReload]);

  useEffect(() => {
    let timer;
    let countdownTimer;

    const scheduleNextCheck = () => {
      const nextCheckTime = getNextCheckTime(checkCount);
      setCountdown(nextCheckTime);

      timer = setTimeout(runHealthCheck, nextCheckTime * 1000);

      countdownTimer = setInterval(() => {
        setCountdown((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownTimer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    };

    if (!isChecking) {
      scheduleNextCheck();
    }

    return () => {
      clearTimeout(timer);
      clearInterval(countdownTimer);
    };
  }, [checkCount, isChecking, runHealthCheck]);

  /**
   * Handles the button click event to start the health check.
   * If the button is clicked within 5 seconds of the last check,
   * it will show a warning message instead of starting the check.
   */
  const handleButtonClick = () => {
    const now = Date.now();
    if (now - lastCheckTime < 5000) {
      toast.info(t('global.outOfService.checkSpamWarning'));
      return;
    }
    runHealthCheck();
  };

  return {
    isChecking,
    countdown,
    handleButtonClick,
  };
};

export default useHealthCheck;
