export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Bereich ist nicht vorhanden',
        disallowInternalInputField: 'Internes Eingabefeld kann nicht zum Bereich hinzugefügt werden.',
      },
      changesWereNotSaved: {
        unknownError: 'Die Änderungen wurden NICHT gespeichert (Serverfehler). Bitte versuche es erneut',
      },
      company: {
        companyIdentifierExists: 'Die Mandantennummer existiert bereits.',
        countCompanies100: '$t(Warnings.countCompanies100)',
      },
      contact: {
        contactAlreadyExists:
          'Trage bitte eine andere E-Mail Adresse ein. Es existiert bereits ein Mandant mit dieser E-Mail',
      },
      digitalSignature: {
        sendMails: {
          resendPasswordMail: 'Passwort konnte nicht erfolgreich zugestellt werden.',
          sendCompanySigneesStartMail:
            'Nicht alle Signierer des Mandanten wurden erfolgreich benachrichtigt. Folgende Signierer konnten nicht benachrichtigt werden: {{failedCompanySignees}} (E-Mails können über die Digitale Signatur Karte der Projektkarte für jeden Signierer einzeln nochmals versendet werden)',
          sendNewLinkMail:
            'Link wurde erfolgreich generiert, konnte aber nicht an Signierer zugestellt werden (E-Mail kann über die Digitale Signatur Karte der Projektkarte nochmals versendet werden)',
          sendNewPasswordMail:
            'Passwort wurde erfolgreich generiert, konnte aber nicht an Signierer zugestellt werden (E-Mail kann über die Digitale Signatur Karte der Projektkarte nochmals versendet werden)',
          sendPasswordMail:
            'Nicht alle Zugangspasswörter konnten erfolgreich versendet werden. Für folgende Signierer konnte das Passwort nicht versendet werden: {{failedPasswordMailRecipients}} (E-Mails können über die Digitale Signatur Karte der Projektkarte für jeden Signierer einzeln nochmals versendet werden)',
          sendReminderMail: 'Erinnerung konnte nicht erfolgreich zugestellt werden.',
          sendRevokeNotificationMail:
            'Nicht alle Signierier wurden erfolgreich über den Abbruch des Signiervorganges von "{{projectName}}" informiert. Folgende Signierer konnten nicht benachrichtigt werden: {{failedCompanySignees}}',
          sendStartMails: 'Digitale Signatur wurde für "{{projectName}}" gestartet.',
          sendTenantSigneesStartMail:
            'Nicht alle Signierer der Kanzlei wurden erfolgreich benachrichtigt. Folgende Signierer konnten nicht benachrichtigt werden: {{failedTenantSignees}} (E-Mails können über die Digitale Signatur Karte der Projektkarte für jeden Signierer einzeln nochmals versendet werden)',
        },
        startingProcessFailed: 'Der Signaturprozess konnte nicht gestartet werden',
      },
      discount: {
        notFound: 'Nachlass wurde NICHT gefunden.',
      },
      documentTemplate: {
        documentTemplateDeleted: 'Dokumentenvorlage wurde NICHT gefunden.',
        documentTemplateIsUsed: 'Es gibt Projektkarten, die diese Dokumentenvorlage verwenden',
        documentTemplateNameIsDuplicateError:
          'Eine Dokumentenvorlage mit den Namen {{existingName}} existiert bereits. Bitte wähle einen anderen Namen für diese Dokumentenvorlage.',
      },
      input: {
        notFound: 'Eingabefeld wurde NICHT gefunden.',
      },
      item: {
        minMaxPrice: 'Das Mindesthonorar kann nicht größer als das Maximalhonorar sein.',
        notFound: 'Leistung wurde NICHT gefunden.',
      },
      permissionDenied: 'Zugriff verweigert',
      projectCard: {
        deleteOnActiveDigitalSignature:
          'Das Löschen ist nicht möglich, da die betroffenen Projektkarten aktive Signiervorgänge haben. Bitte breche alle Signiervorgänge ab und versuche es erneut.',
      },
      settings: {
        attachmentsSizeLimit:
          'Please, enter another attachments size limit because attachments size limit exceeded range',
      },
      shoppingCart: {
        bookmarkedServicesInvalid: 'Einige Leistungen sind nicht im Leistungskatalog vorhanden.',
        digitaleSignatureInWrongState: 'Digitale Signatur ist in einem falschen Status',
        monthlyPaymentDecisionDone:
          'Ein vorheriger Signierer hat bereits eine Auswahl getroffen. Bitte überprüfen Sie die getroffene Auswahl. Wenn Sie Anpassungen oder Änderungen vornehmen möchten, stimmen Sie diese gerne mit uns ab. Klicken Sie anschließend auf "Weiter".',
        noDigitalSignature: 'Keine digitale Signatur für diese Projektkarte vorhanden',
        notFound: 'Projektkarte wurde NICHT gefunden.',
        sendEmailFailedPdfGenerationFailed:
          'Die Email konnte nicht verschickt werden, da mindestens ein Dokument nicht generiert werden konnte.',
      },
      signees: {
        notValid: 'Signierer sind nicht gültig! (Kontakt/Benutzer existiert nicht oder besitzt keine E-Mail Adresse)',
      },
      staticItem: {
        notFound: 'Statische Leistung wurde NICHT gefunden.',
      },
      status: {
        removeDefaultStatus:
          'Du kannst den Standard-Status nicht löschen. Bitte setze zuerst einen anderen Status als Standard-Status und versuche es erneut',
      },
      success: {
        digitalSignature: {
          resendPasswordMail: 'Passwort wurde erfolgreich versendet.',
          sendCompanySigneesStartMail: 'Signierer des Mandanten wurden erfolgreich benachrichtigt.',
          sendNewLinkMail: 'Neuer Link wurde erfolgreich generiert und an Signierer zugesendet.',
          sendNewPasswordMail: 'Neues Passwort wurde erfolgreich generiert und an Signierer zugesendet.',
          sendPasswordMail: 'Passwörter für Signiervorgang wurden erfolgreich versendet.',
          sendReminderMail: 'Erinnerung wurde erfolgreich versendet.',
          sendRevokeNotificationMail: 'Benachrichtigungen über den Abbruch des Signiervorgangs erfolgreich versendet.',
          sendStartMails:
            'Digitale Signatur wurde für "{{projectName}}" erfolgreich gestartet. Alle Signierer wurden benachrichtigt und die erforderlichen Daten zugesendet.',
          sendTenantSigneesStartMail: 'Signierer der Kanzlei wurden erfolgreich benachrichtigt.',
          starting: 'Digitale Signatur wird gestartet...',
        },
      },
      usedInFormula: {
        calculationMode: '$t(common.Item.switchToOnActualCost)',
        category: 'in Kategorie',
        changePaymentInterval:
          'Du kannst das Intervall NICHT auf einmalig stellen, da dies hier die letzte aktive Leistung in dieser Kategorie ist und der Wert dieser Kategorie in der Formel der folgenden Leistung(en) verwendet wird: {{items}}. Die Lösung: Bitte korrigiere die Honorarformel in diesen Leistungen (entferne die Referenz auf diese Kategorie) und führe dann die Änderung hier durch.',
        changePaymentInterval_plural:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of items {{items}}',
        delete:
          'Leistung kann NICHT deaktiviert werden, weil sie in der Honorarformel der Leistungen {{items}} verwendet wird. Bitte ändere dies, um die Leistung anschließend deaktivieren zu können',
        deleteInput:
          'Eingabefeld kann NICHT gelöscht werden, da es in der Honorarformel der folgenden Leistungen verwendet wird: {{items}}',
        deleteLastItem:
          ' Du kannst diese Leistung nicht löschen, da sie die letzte aktive Leistung mit dem Zahlungsintervall „monatlich“ oder „jährlich“ in der Kategorie ist. Die Kategorie wir in der Honorarformel der folgenden Leistungen verwendet: {{items}}',
        deleteLastItem_plural:
          ' Du kannst diese Leistung nicht löschen, da sie die letzte aktive Leistung mit dem Zahlungsintervall „monatlich“ oder „jährlich“ in der Kategorie ist. Die Kategorie wir in der Honorarformel der folgenden Leistungen verwendet: {{items}}',
        delete_plural:
          'Leistung kann NICHT deaktiviert werden, weil sie in der Honorarformel der Leistungen {{items}} verwendet wird. Bitte ändere dies, um die Leistung anschließend deaktivieren zu können',
        inactive:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveCategory:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveCategory_plural:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveUsedItems:
          'Du kannst die Leistung NICHT aktivieren, weil in der Honorarformel die Leistungen {{items}} verwendet werden und inaktiv sind. Bitte aktiviere zunächst die Leistungen.',
        inactiveUsedItems_plural:
          'Du kannst die Leistung NICHT aktivieren, weil in der Honorarformel die Leistungen {{items}} verwendet werden und inaktiv sind. Bitte aktiviere zunächst die Leistungen.',
        inactive_plural:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        usedVariableInFormula: {
          emailSignature: 'Variable wird in E-Mail Signatur verwendet',
          emailTemplates_plural:
            'Variable wird im Betreff oder Textinhalten von E-Mail Vorlagen {{emailTemplates}} verwendet',
          items_plural: 'Variable wird in Honorarformel von Leistungen {{items}} verwendet',
          staticItems_plural:
            'Variable wird in statischem Leistungskatalog von Kategorien {{staticItemCategories}} verwendet',
        },
      },
      user: {
        cannotInviteUser: 'Nutzer kann NICHT eingeladen werden',
        noValidToken: 'Ungültiger Token (Serverfehler)',
        passwordIsNotValidOrExpired: 'Falsches Passwort. Bitte versuche es nochmal',
        resetTokenNotValid:
          'Der benutzte Code ist nicht gültig. Codes, die älter als 10 Minuten sind, sind automatisch nicht mehr gültig. Bitte starte den Passwort-Vergessen-Prozess erneut, um einen neuen Code zu erhalten.',
        tenantDisabled: 'Dein Account ist deaktiviert. Bitte kontaktiere das KanzleiPilot-Team',
        userExists: 'Nutzer mit dieser E-Mail Adresse existiert bereits. Bitte trage eine andere E-Mail ein',
        userHasNoPasswordSet: 'Der Benutzer hat KEIN Passwort',
        userNotFound: 'Benutzer wurde NICHT gefunden',
        userNotFoundForEmail: 'Benutzer mit der E-Mail {{email}} existiert NICHT',
      },
      variable: {
        duplicated: 'Variable mit diesem Namen existiert bereits',
        outsideRange:
          'Der Wert muss zwischen {{largestMinValue}} und {{lowestMaxValue}} liegen, de er als Standardwert in den Eingabefeldern {{itemNames}} verwende wird',
      },
    },
    BackendWarnings: {
      item: {
        removedFailedMentions: 'Einige Platzhalter sind nach den Kopieren ungültig. Diese werden durch 0 ersetzt',
        removedFailedMentionsFromFormula: '$t(BackendWarnings.item.removedFailedMentions)',
      },
    },
    ClientViewPage: {
      clientDetails: {
        bankAccountCard: {
          accountOwnerName: 'Kontoinhaber:',
          bank: 'Bank:',
          bankCity: 'Bankort:',
          bic: 'BIC:',
          downloadDocument: 'Dokument herunterladen',
          iban: 'IBAN:',
          noSignedSepa: 'Mandat noch nicht signiert',
          sepa: 'SEPA:',
        },
        buttons: {
          addProjectCard: 'Neue Projektkarte anlegen',
          editClient: 'Mandant bearbeiten',
        },
        cardTitle: 'Mandant',
        clientType: {
          company: 'Unternehmen',
          individual: 'Privatperson',
        },
        contactCard: {
          salutation: {
            Mr: 'Herr',
            Ms: 'Frau',
          },
        },
        label: {
          address: 'Anschrift:',
          bankAccounts: 'Bankverbindungen:',
          clientNumber: 'Mandantennummer:',
          clientType: 'Mandatsart:',
          commercialObject: 'Unternehmensgegenstand:',
          contactPersons: 'Kontaktpersonen:',
          name: 'Name:',
        },
        noBankAccounts: 'Keine Bankverbindungen vorhanden',
        noContacts: 'Keine Kontaktpersonen vorhanden',
      },
      clientHistoryTab: {
        actionLabels: {
          DigitalSignature: {
            allSignedFailed: 'Digitaler Signaturprozess fehlgeschlagen (Dokumente konnten nicht signiert werden.)',
            autoSigned: 'Projektkarte automatisch signiert für <gray>{{signeeFirstName}} {{signeeLastName}}</gray>',
            bookmarkedItemsAdded: 'Strategische Empfehlung dazugebucht durch {{signeeFirstName}} {{signeeLastName}}',
            linkExpirationDateChanged:
              'Gültigkeit des Linkes für <gray>{{signeeEmail}}</gray> verlängert von <gray>{{oldDate}}</gray> auf <gray>{{newDate}}</gray>',
            linkRequested: 'Link für <gray>{{signeeEmail}}</gray> wurde abgerufen',
            monthlyPaymentDecisionTakenNo:
              '<gray>{{signeeEmail}}</gray> hat sich gegen eine monatliche Zahlung entschieden',
            monthlyPaymentDecisionTakenYes:
              '<gray>{{signeeEmail}}</gray> hat sich für eine monatliche Zahlung entschieden',
            newLink: 'Neuer Link für <gray>{{signeeEmail}}</gray> wurde erstellt',
            newPassword: 'Neues Passwort für <gray>{{signeeEmail}}</gray> wurde erstellt',
            passwordMailSend:
              'E-Mail für Signierpasswort für digitalen Signaturprozess gesendet an <gray>{{recipient}}</gray>',
            passwordRequested: 'Passwort für <gray>{{signeeEmail}}</gray> wurde abgerufen',
            passwordResended: 'Passwortemail für <gray>{{signeeEmail}}</gray> wurde angefordert',
            remindAll: 'Erinnerungsemails für <gray>{{signeeEmails}}</gray> wurde angefordert',
            remindSignee: 'Erinnerungsmail für <gray>{{signeeEmail}}</gray> wurde angefordert',
            reminderMailSend:
              'E-Mail für Erinnerung eines Signierers für digitalen Signaturprozess gesendet an <gray>{{recipient}}</gray>',
            revokeNotificationMailSend:
              'E-Mail für das Zurückziehen der Menükarte gesendet an <gray>{{recipient}}</gray>',
            revoked: 'Menükarte wurde zurückgezogen',
            sepaMandateGranted: 'Mandant hat SEPA-Lastschriftmandat erteilt',
            sepaMandateGrantedCard: {
              accountOwnerName: 'Name des Kontoinhabers',
              bankaccountInformation: 'Bankaccount-Informationen',
            },
            sepaMandateNotGranted: 'Mandant hat SEPA-Lastschriftmandat abgelehnt',
            signed: 'Signiert durch {{signeeFirstName}} {{signeeLastName}} <gray>({{signeeEmail}})</gray>',
            signedDocsMailSend:
              'E-Mail für erfolgreichen Abschluss des digitalen Signaturprozess gesendet an <gray>{{recipient}}</gray>',
            signeeOpenedNotificationMailSend:
              'E-Mail für Benachrichtigung über das erste Öffnen durch einen Signierer gesendet an <gray>{{recipient}}</gray>',
            signeeSignedNotificationMailSend:
              'E-Mail Benachrichtigung darüber, dass ein Signierer signiert hat, gesendet an <gray>{{recipient}}</gray>',
            startFailedPdf:
              'Starten des Signaturprozesses fehlgeschlagen (Mindestens ein Dokument konnte nicht generiert werden.)',
            startMailSendForCompanySignee:
              'E-Mail für Mandantenbenachrichtigung für digitalen Signaturprozess gesendet an <gray>{{recipient}}</gray>',
            startMailSendForTenantSignee:
              'E-Mail für Kanzleimitgliederbenachrichtigung für digitalen Signaturprozess gesendet an <gray>{{recipient}}</gray>',
            started: 'Digitaler Signaturprozess gestartet',
            successfulFinished: 'Digitaler Signaturprozess erfolgreich abgeschlossen',
          },
          ShoppingCart: {
            changeStatus: 'Status einer Projektkarte geändert',
            created: 'Projektkarte erstellt',
            createdRevision: 'Projektkartenrevision erstellt',
            deleted: 'Projektkarte gelöscht',
            sentByEmail: 'E-Mail erfolgreich gesendet',
          },
        },
        additionalTextLabels: {
          accountOwnerName: 'Name des Kontoinhabers',
          bic: 'BIC',
          iban: 'IBAN',
        },
        additionalTextTitle: {
          bookmarkedItemsAdded: 'Hinzugebuchte Strategische Empfehlungen',
          revoked: 'Grund für Zurückziehen',
          sepaMandateGranted: 'SEPA-Lastschriftmandat Informationen',
        },
        email: 'E-Mail Inhalt anzeigen',
        title: 'Aktivitäten',
        viewProjectCard: 'Relevante Projektkarte ansehen',
      },
      documentsTab: {
        badges: {
          generating: 'Wird generiert',
          signed: 'Signiert',
        },
        title: 'Dokumente',
      },
      help: {
        buttonLabel: 'Anleitung: So verwendest du die Mandantenansicht',
        helpText: '',
        imageUrl: '',
        title: 'Anleitung für die Mandantenansicht',
        videoCaption: 'Erfahre, wie du die Mandantenansicht verwenden kannst',
        videoUrl: 'https://vimeo.com/542067268',
      },
      projectCardTab: {
        listLabels: {
          clientName: 'Mandantenname',
          clientNumber: 'Mandantennummer',
          contactPersons: 'Kontaktpersonen',
          createdAt: 'Erstellt am',
          digitalSignatureState: 'Digitaler Signatur Status',
          monthly: 'Monatlich',
          name: 'Name',
          oneOff: 'Einmalig',
          state: 'Status',
          yearly: 'Jährlich',
        },
        title: 'Projektkarten',
      },
      title: 'Mandant betrachten',
    },
    ClientsListPage: {
      addClient: 'Neuen Mandanten anlegen',
      addClientModal: {
        editSuccessMessage: 'Mandant erfolgreich aktualisiert',
        editTitle: 'Mandant bearbeiten',
        form: {
          address: {
            addition: {
              label: 'Adresszusatz',
              placeholder: 'Adresszusatz',
            },
            city: {
              label: 'Ort',
              placeholder: 'Ort',
            },
            cityCode: {
              label: 'Postleitzahl',
              placeholder: 'Postleitzahl',
            },
            country: {
              label: 'Land',
              placeholder: 'Land',
            },
            houseNumber: {
              label: 'Hausnummer',
              placeholder: 'Hausnummer',
            },
            street: {
              label: 'Straße',
              placeholder: 'Straße',
            },
          },
          commercialObject: {
            label: 'Unternehmensgegenstand',
            placeholder: 'Unternehmensgegenstand',
          },
          identifier: {
            label: 'Mandantennummer',
            placeholder: 'Mandantennummer',
          },
          name: {
            label: 'Name',
            placeholder: 'Name',
            required: 'Name ist ein Pflichtfeld',
          },
          type: {
            label: 'Mandantentyp',
            options: {
              company: 'Unternehmen',
              individual: 'Privatperson',
            },
            required: 'Mandantentyp ist ein Pflichtfeld',
            tooltip:
              'Hier kannst du einstellen, ob es sich um ein Privatmandat oder ein Unternehmen handelt. Das hat wiederum Auswirkungen auf verschiedene Funktionen.',
          },
        },
        okButton: 'Mandant speichern',
        successMessage: 'Mandant erfolgreich angelegt',
        title: 'Mandant anlegen',
      },
      addContactModal: {
        addTitle: 'Kontaktperson anlegen',
        editTitle: 'Kontaktperson bearbeiten',
        form: {
          email: {
            label: 'E-Mail Adresse',
            placeholder: 'E-Mail Adresse',
            required: 'E-Mail Adresse ist ein Pflichtfeld',
          },
          firstName: {
            label: 'Vorname',
            placeholder: 'Vorname',
            required: 'Vorname ist ein Pflichtfeld',
          },
          fullSalutation: {
            label: 'Briefanrede',
            placeholder: 'Briefanrede',
            tooltip: 'Briefanrede tooltip',
          },
          insertContact: {
            label: 'Wähle existierende Kontaktperson aus',
            placeholder: 'Suche Kontaktperson',
          },
          lastName: {
            label: 'Nachname',
            placeholder: 'Nachname',
            required: 'Nachname ist ein Pflichtfeld',
          },
          phoneNumber: {
            label: 'Telefon (geschäftlich)',
            placeholder: 'Telefon (geschäftlich)',
          },
          phoneNumberAlternative: {
            label: 'Telefon (alternativ)',
            placeholder: 'Telefon (alternativ)',
          },
          position: {
            label: 'Position',
            placeholder: 'Position',
          },
          salutation: {
            label: 'Anrede',
            options: {
              Mr: 'Herr',
              Mrs: 'Frau',
              Ms: 'Frau',
            },
            placeholder: 'Frau',
          },
        },
        insertContactButton: 'Kontaktperson hinzufügen',
        insertNewContactButton: 'Neue Kontaktperson hinzufügen',
        insertTitle: 'Kontaktperson hinzufügen',
        okButton: 'Kontaktperson speichern',
        successMessageAdd: 'Kontaktperson erfolgreich angelegt',
        successMessageEdit: 'Kontaktperson erfolgreich bearbeitet',
        successMessageInsert: 'Kontaktperson erfolgreich hinzugefügt',
      },
      deleteClient: {
        cancel: 'Abbrechen',
        content:
          'Der Mandant und dessen Daten einschließlich dessen Projektkarten werden unwiderruflich gelöscht. Bitte überprüfe, ob du deinen Aufbewahrungspflichten nachkommst.',
        ok: 'Mandant löschen',
        success: 'Mandant erfolgreich gelöscht',
        title: 'Möchtest du den Mandanten wirklich löschen?',
      },
      help: {
        buttonLabel: 'Anleitung: So verwaltest du deine Mandanten',
        helpText: '',
        imageUrl: '',
        title: 'Anleitung für das Verwalten der Mandanten',
        videoCaption: 'Erfahre, wie du die Mandanten verwaltest',
        videoUrl: 'https://vimeo.com/542067268',
      },
      list: {
        clientNumber: 'Mandantennummer',
        clientType: {
          all: 'Alle',
          company: 'Unternehmen',
          individual: 'Privatperson',
          label: 'Art',
          privatePerson: 'Privatpersonen',
        },
        contactPersons: 'Kontaktpersonen',
        digitalSignatureState: 'Digitaler Signatur Status',
        digitalSignatureStateValues: {
          allSigned: 'Alle Signaturprozesse abgeschlossen',
          multipleActive: '{{count}} aktive Signaturprozesse',
          noSignatureProcess: 'Kein Signaturprozess gestartet',
          oneActive: '1 aktiver Signaturprozess',
        },
        name: 'Name',
        searchPlaceholder: 'Suche nach Mandantennummer, Mandantennamen oder Namen eines Kontaktes',
        updatedAt: 'Geändert am',
      },
      title: 'Mandanten verwalten',
    },
    CurrentUserDropdown: {
      locale: 'Sprache: <1>$t(locale)</1>',
      loggedInAs: 'Eingelogged als <1>{{name}}</1>',
      logout: 'Ausloggen',
      noUserName: 'Unbekannter Name',
      role: 'Rolle: <1>{{role}}</1>',
    },
    DashboardPage: {
      HelperWidget: {
        howUseButton: 'Anleitung: Cockpit verwenden',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung für Cockpit',
          videoCaption: 'Erfahre, wie du das Cockpit verwendest',
          videoUrl: 'https://vimeo.com/541269734',
        },
      },
      IntroductionHelperWidget: {
        howUseButton: 'Erste Schritte in 5 Minuten erklärt',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Einführungsvideo',
          videoCaption: 'KanzleiPilot in 5 Minuten erklärt',
          videoUrl: 'https://vimeo.com/541269034',
        },
      },
      actionsBlock: {
        configureOurCatalogue: 'Leistungskatalog konfigurieren',
        createNewShoppingCart: 'Neue Projektkarte anlegen',
        manageShoppingCarts: 'Projektkarten verwalten',
        title: 'Was möchtest du gerne machen?',
      },
      digitalSignature: {
        noActiveSigningProcesses: 'Keine offenen Signiervorgänge für dich',
        table: {
          linksHeader: 'Links',
          nameOfClientHeader: 'Mandant',
          openShoppingCart: 'Öffne Projektkarte',
          projectNameHeader: 'Projekt',
          signShoppingCart: 'Signiere Projektkarte',
        },
        title: 'Offene Signatur-Vorgänge, bei denen ich noch signieren muss',
      },
      helpCard: {
        book: {
          calendly: '15 Minuten Zoom-Gespräch verabreden',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          sendEmail: 'Kontaktiere den KanzleiPilot-Support per E-Mail',
          sendEmailUrl: '$t(common.urls.sendEmailUrl)',
          title: 'Hast du noch Fragen? Dann buche gerne ein Zoom-Gespräch',
        },
        title: 'Benötigst du Hilfe?',
        tutorial: {
          title: 'Erklärvideos ansehen',
        },
      },
      onboardingChecklist: {
        infoAlert: {
          description:
            'Wenn du KanzleiPilot als Kanzleiinhaber gerade das erste Mal nutzt, empfehlen wir dir folgende Schritte:',
          title: 'Tipp für die leichte Einrichtung',
        },
        step: 'Schritt',
        step1: {
          description: 'Erhalte in 5 Minuten einen Überblick über die wichtigen Funktionen.',
          modalInfo: {
            title: '5 minute tour',
            videoUrl: 'https://vimeo.com/541269034',
          },
          title: 'Look the 5 minute demo',
        },
        step2: {
          description: 'Lade mindestens einen Mitarbeiter ein, der technisch versiert ist und dich unterstützt.',
          modalInfo: {
            title: 'How to invite user',
            videoUrl: 'https://vimeo.com/541649035',
          },
          title: 'Invite user',
        },
        step3: {
          description:
            'Generiere testweise ein Angebot, einen Steuerberatervertrag und eine Vergütungsvereinbarung. Drucke die Dokumente aus und sichte sie. Markiere deine Änderungswünsche (ganz analog) mit einem Textmarker.',
          modalInfo: {
            title: 'How to create a shopping cart',
            videoUrl: 'https://vimeo.com/541665850',
          },
          title: 'Create first shopping cart',
        },
        step4: {
          description: 'Passe nach Bedarf deinen Leistungskatalog an.',
          modalInfo: {
            title: 'How to edit catalogue',
            videoUrl: 'https://vimeo.com/541664949',
          },
          step: '',
          title: 'Edit your catalogue',
        },
        step5: {
          description: 'Passe nach Bedarf deinen Leistungskatalog an.',
          modalInfo: {
            title: 'How to edit documents',
            videoUrl: 'https://vimeo.com/541664771',
          },
          title: 'Edit documents',
        },
        title: 'Schritt für Schritt Anleitung für Kanzleiinhaber',
      },
      title: 'KanzleiPilot Cockpit',
    },
    ExternalServicesWarnings: {
      DEGRADED: {
        database:
          'Einschränkungen unserer Datenbank! Aktuell kann das Laden und Speichern von Daten länger dauern oder fehlschlagen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        documentGeneration:
          'Einschränkungen unserer Dokumenten-Generierung! Aktuell kann es länger dauern bis Dokumente fertig generiert sind bzw. die Generierung kann fehlschlagen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        emailAPI:
          'Einschränkungen unseres E-Mail-Services! Aktuell kann es länger dauern bis E-Mails versendet werden bzw. das Senden von E-Mails kann fehlschlagen. Kanzlei eigene SMTP Server sind davon nicht betroffen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        emailSMTP:
          'Einschränkungen unseres E-Mail-Services! Aktuell kann es länger dauern bis E-Mails versendet werden bzw. das Senden von E-Mails kann fehlschlagen. Kanzlei eigene SMTP Server sind davon nicht betroffen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        storage:
          'Einschränkungen unseres Speicherservices! Aktuell kann das Laden und Generieren von neuen Dokumenten länger dauern bzw. fehlschlagen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
      },
      UNREACHABLE: {
        database:
          'Ausfall unserer Datenbank! Aktuell können keine neuen Daten gespeichert oder geladen werden. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        documentGeneration:
          'Ausfall unserer Dokumenten-Generierung! Aktuell können keine neuen Dokumente generiert werden. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        emailAPI:
          'Ausfall unseres E-Mail-Services! Aktuell können keine E-Mails über unseren E-Mail-Service versendet werden. Kanzlei eigene SMTP Server sind davon nicht betroffen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        emailSMTP:
          'Ausfall unseres E-Mail-Services! Aktuell können keine E-Mails über unseren E-Mail-Service versendet werden. Kanzlei eigene SMTP Server sind davon nicht betroffen. Wir arbeiten an einer Lösung. Versuche es später erneut.',
        storage:
          'Ausfall unseres Speicherservices! Aktuell können keine neuen Dokumente generiert werden, bzw. bestehende Dokumente nicht abgerufen werden. Wir arbeiten an einer Lösung. Versuche es später erneut.',
      },
    },
    FooterComponent: {
      copyright: '© {{year}} Softwarepiloten GmbH',
    },
    FormulaMentions: {
      RVG_Value_Fee: 'RVG_Wertgebühr',
    },
    FormulaNames: {
      category: 'Monatshonorar der Kategorie "{{name}}"',
      inputField: 'Bereichseingabefeld "{{name}}"',
      inputFieldItem: 'Eingabefeld "{{name}}"',
      inputField_internal: 'Internes Eingabefeld "{{name}}" ({{default}})',
      item: 'Honorar der Leistung "{{name}}"',
      staticItem: 'Statische Leistung "{{name}}" des Bereichs "{{categoryName}}"',
      variable: 'Variablenwert "{{name}}"',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Ländercode ist ein Pflichtfeld',
        noPhone: 'Telefonnummer ist ein Pflichtfeld',
      },
    },
    Inputs: {
      country: {
        label: 'Land',
        notFound: 'Keine Land gefunden',
        placeholder: 'Land',
      },
    },
    List: {
      orderButton: 'Reihenfolge',
      searchAndFilterCard: {
        title: 'Suchen und Filtern',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        password: {
          error: 'Passwort ist falsch',
          label: 'Passwort',
          placeholder: 'Passwort',
        },
      },
      resetPassword: 'Ich habe mein Passwort vergessen',
      submit: 'Einloggen',
      title: 'Logge dich bei KanzleiPilot ein',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      clientManagement: {
        title: 'Mandanten verwalten',
        tooltip: 'Mandanten verwalten',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Gemeinsamen Leistungskatalog konfigurieren',
          titleSort: 'Gemeinsamen Leistungskatalog konfigurieren',
        },
        documentCommonLibrary: {
          title: 'Gemeinsame Dokumentenvorlagen konfigurieren',
          titleSort: 'Gemeinsame Dokumentenvorlagen konfigurieren',
        },
        emailCommonLibrary: {
          title: 'Gemeinsame E-Mail Vorlagen konfigurieren',
          titleSort: 'E-Mail Vorlagen',
        },
        title: 'Superadmin Einstellungen',
      },
      companyTenant: {
        title: '$t(admin.Setting.TenantCompany.title)',
      },
      constantsAndPlaceholders: {
        title: '$t(admin.ConstantsAndPlaceholders.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      dashboard: {
        title: '$t(DashboardPage.title)',
        tooltip: '$t(DashboardPage.title)',
      },
      digitalSignaturePreferences: {
        title: 'Digitale Signatur',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      emailSettings: {
        title: '$t(admin.Setting.Email.title)',
      },
      emailTemplates: {
        title: '$t(admin.EmailTemplates.title)',
      },
      globalTexts: {
        title: '$t(admin.GlobalTexts.title)',
      },
      importCompanies: {
        title: 'Mandanten importieren',
      },
      licenseOverview: {
        title: '$t(admin.Setting.LicenseOverview.title)',
      },
      logo: {
        tooltip: 'Menü aufklappen',
      },
      manageAllUsers: {
        title: '$t(superAdmin.ManageAllUsers.title)',
      },
      manageDigitalSignature: {
        title: 'Verwalte Digitale Signatur global',
      },
      managePersons: {
        title: '$t(admin.ManagePersons.title)',
      },
      manageShoppingCartStatus: {
        title: '$t(admin.ManageShoppingCartStatus.title)',
      },
      manageTenants: {
        title: '$t(superAdmin.ManageTenants.title)',
      },
      manageUsers: {
        title: '$t(admin.ManageUsers.title)',
      },
      monthlyPaymentPreferences: {
        title: 'Monatliche Zahlung',
      },
      otherSettings: {
        title: '$t(admin.OtherSettings.title)',
      },
      ourCompany: {
        title: 'Kanzlei & Team',
      },
      settingTitle: 'Einstellungen',
      shoppingCart: {
        title: 'Projektkarte',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
      shoppingCartPreferences: {
        title: '$t(admin.Setting.ShoppingCartPreferences.title) ',
      },
      warnings: {
        title: '$t(Warnings.title)',
        tooltip: '$t(Warnings.tooltip)',
      },
    },
    PdfDrawer: {
      closeButton: 'Schließen',
      downloadButton: 'Herunterladen',
      onErrorAdditionalText:
        'Versuchen Sie die Seite neuzuladen. Falls der Fehler weiterhin auftritt, versuchen Sie es später nochmal und kontaktieren Sie Ihre Kanzlei darüber.',
      onLoadingDocument: 'Dokument wird geladen...',
      printButton: 'Drucken',
    },
    ProjectCardManagement: {
      ClientManagement: {
        emailModal: {
          attachments: 'Anhänge',
        },
      },
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Passwort ändern',
        goToLogin: 'Zurück',
        verify: 'Verifizieren',
      },
      enterEmail: 'Bitte trage die E-Mail ein, für die du das Passwort zurück setzen möchtest',
      errors: {
        noMatch: 'Passwörter stimmen leider NICHT überein',
      },
      fields: {
        email: 'E-Mail',
        newPassword: 'Neues Passwort',
        otp: 'Einmaliges Passwort',
        passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
      },
      steps: {
        email: 'Passwort eintragen',
        setNewPassword: 'Neues Passwort eintragen',
        verification: 'Gib das einmalige Passwort ein',
      },
      title: 'Passwort neu setzen',
      verificationDescription:
        'Wir haben an die E-Mail {{email}} einen Bestätigungscode gesendet. Bitte trage deinen Bestätigungscode ein.',
    },
    ShoppingCartEntriesPage: {
      HelperWidget: {
        howUseButton: 'Anleitung: So verwaltest du deine Projektkarten',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung für das Verwalten der Projektkarten',
          videoCaption: 'Erfahre, wie du die Projektkarten verwaltest',
          videoUrl: 'https://vimeo.com/542067268',
        },
      },
      additionalFilter: {
        digitalSignatureState: {
          DRAFT: 'Entwurf',
          NO_DIGITAL_SIGNATURE: 'keine digitale Signatur',
          REVOKED: 'zurückgezogen',
          SIGNED: 'signiert',
          STARTED: 'gestartet',
        },
        digitalSignatureStateFilterLabel: 'Filter für digitalen Signatur Status:',
        header: 'Weitere Filter',
      },
      catalogue_configuration_button: 'Projektkarte konfigurieren',
      createShoppingCart: 'Neue Projektkarte anlegen',
      digitalSignatureFilterLabel: 'Status der digitalen Signatur',
      entriesTable: {
        columnTitles: {
          actions: 'Aktionen',
          companyIdentifier: 'Mandantennummer',
          companyName: 'Mandant',
          contact: 'Kontakt',
          createdAt: 'Erstellt am',
          digitalSignatureState: 'Digitaler Signatur Status',
          name: 'Projekt',
          relatedContacts: 'Kontaktpersonen',
          status: 'Status',
          valueMonthly: 'Wert monatlich',
          valueOneOff: 'Wert einmalig',
          valueYearly: 'Wert jährlich',
        },
        digitalSignatureState: {
          DRAFT: 'Entwurf',
          NO_DIGITAL_SIGNATURE: 'keine digitale Signatur',
          REVOKED: 'zurückgezogen',
          SIGNED: 'signiert',
          STARTED: 'gestartet',
        },
      },
      menuCartFilterLabel: 'Status der Projektkarte',
      noStatus: 'Kein Status',
      search: 'Suche nach einer Mandantennummer, dem Namen eines Mandanten oder Kontaktes...',
      searchTitle: 'Suche',
      statusAll: 'Alle',
      statusModal: {
        placeholder: 'Wähle den Status aus',
        title: 'Status der Projektkarte bearbeiten',
      },
      title: 'Projektkarten verwalten',
    },
    Warnings: {
      countCompanies100:
        'Du kannst leider KEINE weiteren Mandanten hinzufügen, weil du das Limit deines Mandantenkontingents erreicht hast. Bitte kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Mandantenkontingent zu erhöhen.',
      countCompanies80:
        'Du hast 80 % deines Mandantenkontingents aufgebraucht. Bitte kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Mandantenkontingent zu erhöhen.',
      countCompanies90:
        'Du hast 90 % deines Mandantenkontingents aufgebraucht. Bitte kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Mandantenkontingent zu erhöhen.',
      countUsers100:
        'Du kannst keine weiteren Nutzer hinzufügen, da du dein Limit erreicht hast. Willst du dennoch weitere Nutzer hinzufügen, kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Nutzungslizenz zu erhöhen.',
      countUsers80:
        'Du hast dein Nutzerlimit zu 80% ausgelastet. Bitte kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Nutzungslizenz zu erhöhen.',
      countUsers90:
        'Du hast dein Nutzerlimit zu 90% ausgelastet. Bitte kontaktiere uns via <a>support@kanzleipilot.de</a>, um deine Nutzungslizenz zu erhöhen.',
      title: 'Warnung',
      tooltip: 'Warnung',
    },
    admin: {
      CatalogueConfiguration: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: 'Anleitung: Sichtbarkeit nach Mandantentyp einstellen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Bearbeiten des Bereichs: Sichtbarkeit nach Mandantentyp',
            videoCaption: 'Erfahre, wie du den Bereich Sichtbarkeit nach Mandantentyp bearbeiten kannst ',
            videoUrl: 'https://vimeo.com/541286084',
          },
        },
        ConfigureCategoryProperties: {
          properties: {
            commonLibrary: {
              accessGroups: {
                label: 'Gemeinsame Bibliothek Zugriffsgruppen',
                placeholder: 'Zugrifssgruppen',
                tooltip:
                  'Hier kannst du auswählen, welche Zugriffsgruppen Zugriff auf diesen Bereich haben. Wenn du die Gruppe `Alle` zuweist, haben alle Kanzleien Zugriff auf diesen Bereich.',
              },
              title: 'Gemeinsame Bibliothek',
            },
            conditionalVisibility: {
              bodyTitle: 'Zeige diesen Bereich bei folgenden Mandantentypen:',
              title: 'Sichtbarkeit nach Mandantentyp',
            },
            discounts: {
              title: 'Nachlässe',
            },
            inputFields: {
              title: 'Eingabefelder',
            },
            items: {
              title: 'Leistungen',
            },
            staticItems: {
              title: 'Statischer Leistungskatalog',
            },
            texts: {
              title: 'Texte',
            },
          },
          title: 'Bereich bearbeiten',
        },
        DiscountsHelperWidget: {
          howUseButton: 'Anleitung: Nachlässe einrichten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Konfiguration von Nachlässen',
            videoCaption: 'Erfahre, wie du Nachlässe anlegen, sortieren und löschen kannst',
            videoUrl: 'https://vimeo.com/541285375',
          },
        },
        EditCategoryHelperWidget: {
          howUseButton: 'Anleitung: Bereich bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Bereich bearbeiten',
            videoCaption: 'Erfahre, wie du eine Bereich am einfachsten bearbeiten kannst',
            videoUrl: 'https://vimeo.com/541279223',
          },
        },
        EmailHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: So konfigurierst du den E-Mail Versand',
            videoCaption: 'Erfahre warum du einen eigenen SMTP Server einstellen solltest',
            videoUrl: 'https://vimeo.com/563388433',
          },
        },
        HelperWidget: {
          howUseButton: 'Anleitung: So konfigurierst du deinen Leistungkatalog',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Konfigurieren des Leistungskatalogs',
            videoCaption: 'Erfahre, wie du den Leistungskatalog konfigurieren kannst',
            videoUrl: 'https://vimeo.com/541270313',
          },
        },
        ImportHelperWidget: {
          howUseButton: 'Anleitung: Leistungskatalog importieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Leistungskatalog importieren',
            videoCaption: 'Erfahre, wie du aus der Bibliothek importieren kannst',
            videoUrl: 'https://vimeo.com/541270670',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: 'Anleitung: Eingabefelder verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Verwaltung von Eingabefeldern',
            videoCaption: 'Erfahre, wie du Eingabefelder verwalten kannst',
            videoUrl: 'https://vimeo.com/541287358',
          },
        },
        StaticItemsHelperWidget: {
          howUseButton: 'Anleitung: Statischen Leistungskatalog verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Konfiguration des statischen Leistungskatalogs',
            videoCaption: 'Erfahre, wie du Leistungen anlegen, bearbeiten oder löschen kannst',
            videoUrl: 'https://vimeo.com/957677516/3728167ac1?share=copy',
          },
        },
        StaticItemsTab: {
          alert: {
            description:
              'Der statische Leistungskatalog dient zur Darstellung von Stücklisten. Pro Leistung kannst du hier einen Titel und einen Wert eintragen. Trage als Wert bitte den Netto-Wert ein. Unternehmen sehen in den generierten Dokumenten die Netto-Werte. Privatpersonen sehen Brutto-Werte. Dies steuerst du über die Funktion “Umsatzsteuer anzeigen” in der Projektkarte.',
            message: 'Information zur Darstellung der Wert-Spalte in den Dokumenten',
          },
          inputs: {
            intro: {
              label: 'Einleitungstext für Dokumente',
              tooltip: 'Dieser Text erscheint oberhalb des statischen Leistungskatalog dieser Kategorie',
            },
            outro: {
              label: 'Abschlusstext für Dokumente',
              tooltip: 'Dieser Text erscheint unterhalb des statischen Leistungskatalog dieser Kategorie',
            },
          },
        },
        TextsHelperWidget: {
          howUseButton: 'Anleitung: Texte des Bereichs bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Bearbeiten von Texten',
            videoCaption: 'Erfahre, wie du Texte in einem Bereich bearbeiten kannst',
            videoUrl: 'https://vimeo.com/541287581',
          },
        },
        addCategory: 'Neuen Bereich anlegen',
        collapseOptions: {
          duplicate: 'Bereich duplizieren',
          edit: 'Bereich bearbeiten',
          remove: 'Bereich löschen',
        },
        copyItem: {
          addonAfter: '(Kopie)',
          cancel: '$t(common.cancel)',
          complexPricingFormula:
            '[{"type":"paragraph","children":[{"text":"Die Leistung oder ein Auswahlfeld der Leistung enthält eine \\"komplexe\\" Honorarformel. Die Leistung kann nur unter einigen Einschränkungen kopiert werden: "}]},{"type":"bulleted-list","listType":"disc","children":[{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Bereiche, werden die Bereichs-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]},{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Leistungen, werden die Leistungs-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]},{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Bereichs-Eingabefelder, werden die Bereichs-Eingabefeld-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]}]},{"type":"paragraph","children":[{"text":"Bitte überprüfe nach dem Kopiervorgang bitte die Honorarformel der Kopie. Die Texte und andere Einstellungen werden 1-zu-1 kopiert."}]}]',
          duplicatedErrorMessage:
            'Name des Duplikats von Leistungen sind in KanzleiPilot eindeutig. Bitte trage den Namen des Duplikats ein.',
          inputs: {
            category: {
              errorLabel: 'Kategorie',
              label: 'Bitte wähle den Bereich aus',
            },
            name: {
              label: 'Name des Duplikats',
            },
          },
          ok: 'Leistung kopieren',
          openModalButtonTitle: 'Kopiere in anderen Bereich',
          singleImport: 'importieren',
          success: {
            subTitle: 'Die Leistung wurde erfolgreich importiert',
            title: 'Import war erfolgreich',
          },
          title: 'In welchen Bereich möchtest du die Leistung kopieren?',
        },
        discounts: {
          title: 'Nachlässe',
        },
        emptyCategoryListMessage: 'Es gibt noch KEINEN Bereich. Bitte klicke auf "Neuen Bereich anlegen"',
        emptyDiscountListMessage: 'Es gibt noch KEINE Nachlässe. Bitte klicke auf "Neuen Nachlass anlegen"',
        emptyInputFieldListMessage: 'Es gibt noch KEINE Eingabefelder. Bitte klicke auf "Neues Eingabefeld anlegen"',
        emptyInputFieldOptionListMessage:
          'Es gibt noch KEINE Auswahlfeld Optionen. Bitte klicke auf "Neue Option anlegen"',
        emptyItemListMessage: 'Es gibt noch KEINE Leistungen. Bitte klicke auf "Neue Leistung anlegen"',
        emptyStaticItemListMessage:
          'Es sind keine statischen Leistungen vorhanden. Drücke auf "+ neue Leistung anlegen"',
        importCategoryPresets: 'Aus Bibliothek importieren',
        itemsValidation: {
          button: 'Alle Honorarformeln validieren',
          error: '{{itemName}} in {{categoryName}} hat eine ungültige Formel',
          success: 'Keine Fehler in den Honorarformeln gefunden',
        },
        title: 'Leistungskatalog konfigurieren',
        titleCommonLibrary: 'Gemeinsame Bibliothek',
      },
      ConstantsAndPlaceholders: {
        HelperWidget: {
          howUseButton: 'Anleitung: Variablen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Variablen verwalten',
            videoCaption: 'Erfahre, wie du die Variablen verwaltest',
            videoUrl: 'https://vimeo.com/541298165',
          },
        },
        addVariableButton: 'Neue Variable anlegen',
        addVariableModalTitle: 'Neue Variable anlegen',
        columns: {
          description: 'Beschreibung',
          mandatory: 'System-Variable',
          name: 'Name',
          type: 'Typ',
          value: 'Wert',
        },
        editVariableModalTitle: 'Variable bearbeiten',
        fields: {
          description: {
            label: '$t(common.fields.description.label)',
          },
          name: {
            label: '$t(common.fields.name.label)',
          },
          type: {
            label: '$t(common.fields.type.label)',
          },
          value: {
            label: '$t(common.fields.value.label)',
          },
        },
        title: 'Variablen verwalten',
        variableListTitle: 'Liste der Variablen',
      },
      DocumentTemplateConfigurationPage: {
        AddBlockHelperWidget: {
          howUseButton: 'Anleitung: Block hinzufügen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Hinzufügen von Blöcken',
            videoCaption: 'Erfahre, wie du Blöcke hinzufügen kannst',
            videoUrl: 'https://vimeo.com/542072198',
          },
        },
        BlockHelperWidget: {
          howUseButton: 'Anleitung: {{displayName}} einstellen',
          modalInfo: {
            FreeText: {
              title: 'Anleitung: Freien Text einstellen',
              videoCaption: 'Erfahre, was für Optionen du im Freien Text Block hast',
              videoUrl: 'https://vimeo.com/541297800',
            },
            FullPageImage: {
              title: 'Anleitung: Bildseite einstellen',
              videoCaption: 'Erfahre, wie der Bildseiten-Block funktioniert',
              videoUrl: 'https://vimeo.com/586189327',
            },
            Introduction: {
              title: 'Anleitung: Begrüßung einstellen',
              videoCaption: 'Erfahre, was für Optionen du im Begrüßung-Block hast',
              videoUrl: 'https://vimeo.com/541296373',
            },
            Investment: {
              title: 'Anleitung: Ihre Investition einstellen',
              videoCaption: 'Erfahre, wie die Kosten des Mandanten separat aufgelistet werden',
              videoUrl: 'https://vimeo.com/541296650',
            },
            ListBookmarkedItems: {
              title: 'Anleitung: Details zu Strategische Empfehlungen einstellen',
              videoCaption: 'Erfahre, wie der Block “Strategische Empfehlungen“ funktioniert',
              videoUrl: 'https://vimeo.com/563209294',
            },
            NextSteps: {
              title: 'Anleitung: Nächste Schritte einstellen',
              videoCaption: 'Erfahre, wie der nächste Schritte Block funktioniert',
              videoUrl: 'https://vimeo.com/541297365',
            },
            ServiceCatalogue: {
              title: 'Anleitung: Leistungskatalog einstellen',
              videoCaption: 'Erfahre, wie der Leistungskatalog-Block funktioniert',
              videoUrl: 'https://vimeo.com/715753797',
            },
            ServiceDetails: {
              title: 'Anleitung: Details zu den ausgewählten Dienstleistungen einstellen',
              videoCaption: 'Erfahre, wie die Details zu den ausgewählten Dienstleistungen funktionieren',
              videoUrl: 'https://vimeo.com/541296849',
            },
            ServiceDetailsForContract: {
              title: 'Anleitung: Auftragsumfang einstellen',
              videoCaption: 'Auftragsumfang listet alle ausgewählten Dienstleistungen auf',
              videoUrl: 'https://vimeo.com/541297050',
            },
            Signatures: {
              title: 'Anleitung: Signaturen einstellen',
              videoCaption: 'Erfahre, wie der Signaturen-Block funktioniert',
              videoUrl: 'https://vimeo.com/541297697',
            },
            SpecialAgreement: {
              title: 'Sondervereinbarung',
              videoCaption: 'Erfahre, wie du den Sondervereinbarung Bereich optimal nutzt',
              videoUrl: 'https://vimeo.com/568893139',
            },
            TableOfContent: {
              title: 'Anleitung: Inhaltsverzeichnis einstellen',
              videoCaption: 'Erfahre, wie der Inhaltsverzeichnis-Block funktioniert',
              videoUrl: 'https://vimeo.com/541296041',
            },
            Testimonial: {
              title: 'Anleitung: Testimonial einstellen',
              videoCaption: 'Erfahre, wie der Testimonial-Block funktioniert',
              videoUrl: 'https://vimeo.com/541295938',
            },
            Title: {
              title: 'Anleitung: Titel einstellen',
              videoCaption: 'Erfahre, wie der Titel-Block funktioniert',
              videoUrl: 'https://vimeo.com/541295693',
            },
            helpText: '',
          },
        },
        HelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlage bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Dokumentenvorlage bearbeiten',
            videoCaption: 'Erfahre, wie du eine Dokumentenvorlage bearbeitest',
            videoUrl: 'https://vimeo.com/541288371',
          },
        },
        addBlockModal: {
          addToBottomButton: 'füge unten hinzu',
          addToTopButton: 'füge oben hinzu',
          availableBlocksTitle: 'Verfügbare Blöcke',
          blockActionsColumnTitle: 'Aktionen',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Vorschau',
          title: 'Block hinzufügen',
        },
        backTitle: 'Zurück zu den Dokumentenvorlagen',
        blockSubtitles: {
          activeDigitalSignature: 'Bei aktiver Digitaler Signatur',
          catalogue: 'Leistungskatalog Filter',
          category: 'Bereichsebene:',
          decided: 'Entscheidung getroffen',
          decissionSection: 'Entscheidungsabschnitt',
          endOfBlock: 'Ende des Blocks',
          inactiveDigitalSignature: 'Bei inaktiver Digitaler Signatur',
          internal: 'Interne Einstellungen für Workshops, Onboarding und interne Anleitungen',
          introductions: 'Einführungstexte',
          item: 'Leistungsebene:',
          page: 'Allgemein',
          pricingSection: 'Honorarbereich',
        },
        blocks: {
          body: 'Personalisierter Text',
          introductionBlockNumber: '(introduction-block #{{number}})',
        },
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Möchtest du den Block wirklich löschen? Warnung: deine Block-Einstellungen gehen verloren (Texte, Einstellungen, etc.). Fortfahren?',
        },
        generalInformationSection: {
          accessGroups: {
            label: 'Gemeinsame Bibliothek Zugriffsgruppen',
            placeholder: 'Zugrifssgruppen',
            tooltip:
              'Hier kannst du auswählen, welche Zugriffsgruppen Zugriff auf diese Dokumentenvorlage haben. Wenn du die Gruppe `ALLE` zuweist, haben alle Kanzleien Zugriff auf diese Dokumentenvorlage.',
          },
          descriptionInputLabel: 'Beschreibung',
          descriptionInputTooltip:
            'Interne Beschreibung der Dokumentenvorlage. Gut um für dein Team zu dokumentieren, worum es sich hier genau handelt.',
          fileNameDefaultPlaceholder:
            '(Dokumentenvorlagenname) (Dokumentennummer) für (Vollständiger Name der Ansprechperson des Mandanten) von (Kanzleiname)',
          fileNameInputLabel: 'Dateiname',
          fileNameInputTooltip:
            'Hier kannst du einstellen, wie die Datei benannt werden soll. Dabei stehen dir verschiedene Platzhalter zur Verfügung. Wenn du dieses Feld leer lässt, wird unser Standard verwendet.',
          lastUsedDocumentNr: 'Aktuelle Dokumenten-Nummer: {{n}}',
          nameInputLabel: 'Name',
          nameInputTooltip: 'Name der Dokumentenvorlage. Erscheint so zur Auswahl in der interaktiven Projektkarte.',
          nrOfDocumentsCreated: '{{count}} Dokument erzeugt',
          nrOfDocumentsCreated_plural: '{{count}} Dokumente erzeugt',
          title: 'Allgemeine Information',
        },
        layoutSection: {
          addBlockButton: 'Block hinzufügen',
          emptyBlockList: 'Füge deine Dokumentenvorlage einen Block hinzu und drücke auf "Block hinzufügen"',
          infoSectionTitle: 'Info',
          title: 'Layout',
          tooltip:
            'Das Layout einer Dokumentenvorlage besteht aus Blöcke, die du hier bearbeiten,kannst. Klicke unten auf einen Block, um die Texte anzupassen. Klicke auf „ + neuen Block hinzufügen“ um einen neuen Block in das Layout einzufügen.',
        },
        selectItemsToShowOrHide: {
          switch: {
            hide: 'Verstecken',
            show: 'Anzeigen',
          },
          title: 'Setze den Sichtbarkeits-Modus für selektierte Leistungen',
          tooltip: 'Die selektierten Leistungen werden entweder angezeigt oder versteckt.',
          transfer: {
            leftTitle: 'Verfügbare Leistungen',
            rightTitle: {
              hide: 'Versteckte Leistungen',
              show: 'Angezeigte Leistungen',
            },
            staticPrefix: '(Statisch) ',
          },
        },
        title: 'Dokumentenvorlagen bearbeiten',
      },
      DocumentTemplateListPage: {
        HelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlagen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Nutzung von Dokumentenvorlagen',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/541287917',
          },
        },
        ImportHelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlagen importieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Dokumentenvorlagen aus Bibliothek importieren',
            videoCaption: 'Erfahre, wie du Dokumentenvorlagen aus der Bibliothek importieren kannst',
            videoUrl: 'https://vimeo.com/541288193',
          },
        },
        actions: {
          deleteDocumentTemplate: 'Dokumentenvorlage löschen',
          duplicateDocumentTemplate: 'Dokumentenvorlage duplizieren',
          editDocumentTemplate: 'Dokumentenvorlage bearbeiten',
        },
        addDocumentTemplateButton: 'Dokumentenvorlagen hinzufügen',
        createdAt: 'Erstellt am {{date}}',
        duplicateDocumentTemplateModal: {
          cancel: '$t(common.cancel)',
          duplicatedErrorMessage: 'Dokumentenvorlage mit diesem Namen existiert bereits',
          ok: '$t(common.ok)',
          title: 'Bitte trage den Namen für die neue Dokumentenvorlage ein',
        },
        emptyListMessage: 'Du hast noch keine Dokumentenvorlagen angelegt',
        errorMessageInModal: 'Dokumentenvorlagen mit den Namen {{duplicatedDocuments}} existieren bereits',
        importEmptyListMessage: 'Bis jetzt ist keine Dokumententemplate vorhanden',
        importFromLibraryButton: 'Aus gemeinsamer Bibliothek importieren',
        importFromLibraryModalTitle: 'Dokumentenvorlagen importieren',
        lastUsedDocumentNr: 'Aktuelle #:{{n}}',
        list: {
          all: 'Alle',
          countDocuments: 'Erstellte Dokumente',
          createdAt: 'Erstellt am',
          description: 'Beschreibung',
          name: 'Name',
          notSignable: 'Nicht signierbar',
          searchPlaceholder: 'Suche nach Namen',
          signable: 'Signierbar',
        },
        nrOfDocumentsCreated: '{{count}} Dokument erstellt',
        nrOfDocumentsCreated_plural: '{{count}} Dokumente erstellt',
        title: 'Dokumentenvorlagen',
        titleCommonLibrary: 'Gemeinsame Dokumentenvorlagen',
      },
      EmailTemplates: {
        add: 'E-Mail Vorlage hinzufügen',
        columns: {
          actions: '$t(common.columns.actions)',
          body: '$t(common.columns.body)',
          isDefault: 'Bei Neuanlage importieren?',
          name: '$t(common.columns.name)',
          subject: '$t(common.columns.subject)',
        },
        fields: {
          body: {
            label: '$t(common.fields.body.label)',
            placeholder: '$t(common.fields.body.placeholder)',
          },
          description: {
            label: 'Beschreibung',
            placeholder: 'Beschreibung',
          },
          isDefault: {
            label: 'Bei Neuanlage importieren?',
            no: '$t(common.no)',
            yes: '$t(common.yes)',
          },
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          shouldSendAttachments: {
            label: 'Dokumente als E-Mail Anhang mitsenden',
            off: 'AUS',
            on: 'AN',
          },
          subject: {
            label: '$t(common.fields.subject.label)',
            placeholder: '$t(common.fields.subject.placeholder)',
          },
        },
        import: 'Aus Bibliothek importieren',
        importEmptyEmailTemplateListMessage: 'There are no email templates yet.',
        importErrorMessage: 'E-Mail Vorlage mit dem Namen {{duplicatedEmailTemplates}} existieren bereits',
        importModalTitle: 'E-Mail Vorlagen aus Bibliothek importieren',
        list: {
          createdAt: 'Erstellt am',
          description: 'Beschreibung',
          name: 'Name',
          searchPlaceholder: 'Suche nach Namen oder Betreff',
          subject: 'Betreff',
        },
        modal: {
          addTitle: 'Neue E-Mail Vorlage anlegen',
          deleteTitle: 'Bist du sicher, dass du die E-Mail Vorlage löschen möchtest?',
          editTitle: 'E-Mail Vorlage bearbeiten',
          error: {
            delete: 'Fehler beim Löschen der E-Mail Vorlage',
            duplicatedErrorMessage: 'E-Mail Vorlage mit diesem Namen existiert bereits',
          },
        },
        title: 'E-Mail Vorlagen',
      },
      GlobalTexts: {
        globalSettings: {
          inputs: {
            showStaticItemsText: {
              default: 'Zeige statischen Leistungskatalog',
              label: 'Überschrift für “Zeige statischen Leistungskatalog“',
              tooltip: 'Dieser Text wird in der Menükartenansicht angezeigt',
            },
            staticItemsText: {
              default: 'Statischer Leistungskatalog',
              label: 'Überschrift für “Statischer Leistungskatalog“',
              tooltip: 'Dieser Text wird in den PDF Dateien angezeigt.',
            },
          },
          title: 'Globale Einstellungen',
        },
        title: 'Globale Texte',
      },
      ManagePersons: {
        HelperWidget: {
          howUseButton: 'Anleitung: Unterschriftsberechtigte Personen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Unterschriftsberechtigte Personen verwalten',
            videoCaption: 'Erfahre, wie und wozu du die Unterschriften einrichtest',
            videoUrl: 'https://vimeo.com/541301636',
          },
        },
        addPerson: 'Unterschriftsberechtigte Person anlegen',
        addPersonModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Unterschriftsberechtigte Person anlegen',
        },
        cardTitle: 'Unterschriftsberechtigte Person',
        deleteConfirmModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Möchtest du die unterschriftsberechtigte Person löschen?',
        },
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          position: {
            label: '$t(common.fields.position.label)',
            placeholder: '$t(common.fields.position.placeholder)',
          },
          signature: {
            label: 'Unterschrift',
            tooltip: 'Hinweis: Diese Unterschrift wird nur im Begrüßungsblock eines Dokumentes verwendet',
          },
        },
        title: 'Unterschriftsberechtigte Personen verwalten',
        uploadLabel: 'Unterschriftbild hochladen',
      },
      ManageShoppingCartStatus: {
        HelperWidget: {
          howUseButton: 'Anleitung: Status verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Status für Projektkarten verwenden und konfigurieren',
            videoCaption: 'Erfahre, alles über die Status. Wozu sind sie nützlich und wie verwaltest du sie?',
            videoUrl: 'https://vimeo.com/541298786',
          },
        },
        addStatus: 'Neuen Status anlegen',
        addStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Neuen Status anlegen',
        },
        columns: {
          name: '$t(common.columns.name)',
        },
        defaultStatusTitle: 'Standard Status',
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
        },
        statusListTitle: 'Status Liste',
        title: 'Status der Projektkarten verwalten',
        updateStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Status ändern',
        },
      },
      ManageUsers: {
        HelperWidget: {
          howUseButton: 'Anleitung: Nutzer verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Verwalten von Nutzern',
            videoCaption: 'Erfahre, wie du neue Nutzer einlädst und existierende Nutzer verwaltest',
            videoUrl: 'https://vimeo.com/541301373',
          },
        },
        buttons: {
          sendInvitation: 'Einladung senden',
        },
        modal: {
          editUser: 'Nutzer bearbeiten',
        },
        title: 'Nutzer verwalten',
        userInvitation: {
          inputs: {
            customSignatureImage: {
              emptyPlaceholder: 'Kein eigenes Signaturbild konfiguriert. Verwende generisches Signaturbild',
              label: 'Eigenes Signaturbild (optional):',
              resetButton: 'Signaturbild zurücksetzen',
              uploadButton: 'Lade Signaturbild hoch',
            },
            email: {
              label: 'E-Mail',
            },
            firstName: {
              label: 'Vorname',
            },
            lastName: {
              label: 'Nachname',
            },
            position: {
              label: 'Position',
            },
            role: {
              label: 'Berechtigung',
              tooltip:
                'Rolle bzw. Rechte des Nutzers. Wir unterscheiden 4 Rollen: 1) “Nutzer nur mit Lesezugriff” können Projektkarten betrachten - sinnvoll z.B. für den Empfang. 2) “Nutzer (darf Projektkarten anlegen)“ darf Projektkarten anlegen und bearbeiten (Projektkarten sind revisionssicher). 3) Administratoren haben Zugriff auf Einstellungen und können z.B. auch den Leistungskatalog bearbeiten 4) Inhaber dürfen alles',
            },
          },
          title: 'Nutzer einladen',
        },
        userList: {
          fields: {
            email: 'E-Mail',
            name: 'Name',
            onboarding: 'Onboarding',
            position: '$t(common.fields.position.label)',
            role: 'Berechtigung',
            status: 'Status',
          },
          title: 'Liste der Nutzer',
        },
      },
      OtherSettings: {
        globalSettings: {
          inputs: {
            hideCountryInAddresses: {
              label: 'Verstecke das Land in Adressen, wenn es im selben Land liegt, wie unsere Kanzlei',
            },
          },
          title: 'Globale Einstellungen',
        },
        title: 'Sonstige Einstellungen',
      },
      Placeholders: {
        accountOwnerForSepaMandate: {
          label: 'Name des Kontoinhabers für SEPA-Mandat',
        },
        allContactsSeparated: {
          label: 'Ansprechpartner (separiert mit Komma/und)',
        },
        allContactsWithFullSalutation: {
          label: 'Briefanrede für Ansprechpartner',
        },
        bankAccountInformations: {
          label: 'Bank Informationen',
        },
        bicForSepaMandate: {
          label: 'BIC für SEPA-Mandat',
        },
        categoryTotals: {
          deleted: 'Gelöschte Kategorie',
          label: 'Monatliche Zahlung: theoretisch - für einzelnen Bereich',
          mention: 'Monatliche Zahlung: theoretisch - für Bereich "{{name}}"',
        },
        client: {
          label: 'Mandant',
        },
        company: {
          label: 'Mandant',
        },
        currentDate: {
          label: 'Datum (tagesaktuell bei Generierung)',
        },
        digitalSignature: {
          label: 'Digitale Signatur',
        },
        digitalSignatureState: {
          label: 'Signaturprozessstatus',
        },
        document: {
          label: 'Dokument',
        },
        documentCreatedAt: {
          label: 'Erstellt am',
        },
        documentNumber: {
          label: 'Dokumentennummer',
        },
        effectiveTotalMonthlyFixedPrice: {
          label:
            'Monatliche Zahlung: effektiv (Monatliche Zahlung oder Unternehmerhonorar, monatliche Werte + jährliche Werte / 12 Monate)',
        },
        effectiveTotalMonthlyFixedPriceDetailed: {
          label:
            'Monatliche Zahlung: effektiv (Monatliche Zahlung oder Unternehmerhonorar, monatliche Werte + jährliche Werte / 12 Monate) - mit Infos über Zusammensetzung',
        },
        endOfContract: {
          label: 'Vertragsende',
        },
        environment: {
          label: 'Sonstiges',
        },
        ibanForSepaMandate: {
          label: 'IBAN für SEPA-Mandat',
        },
        linkButton: {
          label: 'Button für Signierprozess',
        },
        linkExpirationDate: {
          label: 'Link gültig bis',
        },
        linkValidityTime: {
          label: 'Übrige Zeit',
        },
        listOfAllNotSignedSignees: {
          label: 'Liste der Namen aller Signierer, die noch nicht signiert haben',
        },
        listOfAllSignedSignees: {
          label: 'Liste der Namen aller Signierer, die schon signiert haben',
        },
        meetingAt: {
          label: 'Datum des Honorargesprächs',
        },
        monthlyPaymentDecision: {
          label: 'Monatliche Zahlung: Mandantenentscheidung',
        },
        numberOfReadySignees: {
          label: 'Anzahl fertiger Signierer zur Anzahl aller Signierer',
        },
        openedSigneeName: {
          label: 'Name des Signierers, der gerade seinen Signiervorgang geöffnet hat',
        },
        projectName: {
          label: 'Projektname',
        },
        requirePassword: {
          label: 'Passwort benötigt',
        },
        revokeInternalNote: {
          label: 'Interne Notiz zum Widerruf',
        },
        revokeInternalNoteBox: {
          label: 'Box mit interner Notiz zum Widerruf',
        },
        sepa: {
          label: 'SEPA-Mandat',
        },
        sepaMandateText: {
          label: 'Mandatstext für SEPA-Mandat',
        },
        shoppingCart: {
          label: 'Projektkarte',
        },
        shoppingCartCompanyCity: {
          label: 'Ort Mandant',
        },
        shoppingCartCompanyCommercialObject: {
          label: 'Unternehmensgegenstand',
        },
        shoppingCartCompanyFullAddress: {
          label: 'Anschrift Mandant',
        },
        shoppingCartCompanyIdentifier: {
          label:
            'Mandatsnummer (speziell für SEPA Mandatsreferenz. Wenn nicht verfügbar, wird Platzhaltertext “wird nachgereicht” angezeigt)',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Firmierung Mandant',
        },
        shoppingCartVatType: {
          label: 'Bezeichnung Umsatzsteuer oder Mehrwertsteuer',
        },
        shoppingCartVatTypeWithPercentage: {
          label: 'Umsatzsteuer Infotext mit brutto-netto und Prozent-Angaben',
        },
        shortenedAccountOwnerForSepaMandate: {
          label: 'Verkürzter Name des Kontoinhabers für SEPA-Mandat',
        },
        shortenedBicForSepaMandate: {
          label: 'Verkürzte BIC für SEPA-Mandat',
        },
        shortenedIbanForSepaMandate: {
          label: 'Verkürzte IBAN für SEPA-Mandat',
        },
        signedSigneeName: {
          label: 'Names des Signierers, der gerade signiert hat',
        },
        signeeEmail: {
          label: 'E-Mail Adresse des Signierers',
        },
        signeeFirstName: {
          label: 'Vorname des Signierers',
        },
        signeeLastName: {
          label: 'Nachname des Signierers',
        },
        signeeSalutation: {
          label: 'Anrede des Signierers',
        },
        signeeState: {
          label: 'Status des Signierers',
        },
        signingPassword: {
          label: 'Passwort für Signierprozess',
        },
        signingUpdates: {
          label: 'Signaturprozess Updates',
        },
        startOfContract: {
          label: 'Vertragsstart',
        },
        tenantCompany: {
          label: 'Kanzlei',
        },
        tenantCompanyCity: {
          label: 'Sitz Kanzlei',
        },
        tenantCompanyCityCode: {
          label: 'PLZ Kanzlei',
        },
        tenantCompanyCountry: {
          label: 'Land Kanzlei',
        },
        tenantCompanyCreditorIdentNr: {
          label: 'Gläubiger-Identifikationsnummer',
        },
        tenantCompanyCurrentUserName: {
          label: 'Name des aktiven Benutzers',
        },
        tenantCompanyFullAddress: {
          label: 'Anschrift Kanzlei',
        },
        tenantCompanyHouseNumber: {
          label: 'Hausnummer Kanzlei',
        },
        tenantCompanyName: {
          label: 'Kanzleiname',
        },
        tenantCompanyOfficeEmail: {
          label: 'E-Mail Kanzlei',
        },
        tenantCompanyPhoneNumber: {
          label: 'Telefonnummer Kanzlei',
        },
        tenantCompanyPositionOfCurrentUserName: {
          label: 'Position des aktiven Benutzers (aus seinem Profil)',
        },
        tenantCompanyStreet: {
          label: 'Straße Kanzlei',
        },
        tenantCompanyWebsite: {
          label: 'Webseite Kanzlei',
        },
        totalMonthlyFixedPrice: {
          label: 'Monatliche Zahlung: theoretisch (monatliche Werte + jährliche Werte / 12 Monate)',
        },
        totalMonthlyFixedPriceDetailed: {
          label:
            'Monatliche Zahlung: theoretisch (monatliche Werte + jährliche Werte / 12 Monate) - mit Infos über Zusammensetzung',
        },
        totalMonthlyValue: {
          label: 'Monatlicher Wert',
        },
        totalMonthlyValueDetailed: {
          label: 'Monatlicher Wert - mit Infos über Zusammensetzung',
        },
        totalOneOffValue: {
          label: 'Einmaliger Wert',
        },
        totalOneOffValueDetailed: {
          label: 'Einmaliger Wert - mit Infos über Zusammensetzung',
        },
        totalYearlyFixedPrice: {
          label: 'Jährliche Zahlung (monatliche + jährliche Positionen)',
        },
        totalYearlyFixedPriceDetailed: {
          label: 'Jährliche Zahlung (monatliche + jährliche Positionen) - mit Infos über Zusammensetzung',
        },
        totalYearlyValue: {
          label: 'Jährlicher Wert',
        },
        totalYearlyValueDetailed: {
          label: 'Jährlicher Wert - mit Infos über Zusammensetzung',
        },
        variables: {
          deleted: 'Gelöschte Variable',
          label: 'Variablen',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Link hinzufügen',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link muss eine validide URL sein (z.B. https://www.kanzleipilot.de)',
            modalValueTooltipHTML:
              '<div><div>Beispiel:</div><div>https://www.kanzleipilot.de - Webadresse verlinken</div><div>mailto:name@gmail.com - E-Mail verlinken</div><div>tel:+49123456789 - Telefonnummer verlinken</div></div>',
          },
        },
      },
      Setting: {
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Farbe',
              fontFamily: 'Schriftart',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              bodyPrimary: 'Flächen',
              bodyPrimaryFooter: 'Flächen (Fußzeile)',
            },
            title: 'Flächen',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primäre Kontrastfarbe',
              fontFamily: 'Schriftart',
              primaryColor: 'Primärfarbe',
            },
            preview: 'Vorschau eines Buttons in Ihrer Farbgebung (Primärfarbe und Kontrastfarbe)',
            title: 'Konfiguration der Corporate Identify',
          },
          HelperWidget: {
            howUseButton: 'Anleitung: Corporate Identify einstellen',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung Corporate Identify',
              videoCaption: 'Erfahre, wie du KanzleiPilot an deine Farben anpassen kannst',
              videoUrl: 'https://vimeo.com/541302959',
            },
          },
          TableOfContent: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Textfarbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            title: 'Inhaltsverzeichnis',
          },
          TableTheme: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Textfarbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              marginBottom: 'Abstand oben',
              marginTop: 'Abstand unten',
            },
            inputs: {
              table: 'Tabelle',
              tableContent: 'Tabelleninhalt',
              tableFooter: 'Tabelle Fußzeile',
              tableHeaderPrimary: 'Tabellenkopf Primärfarbe',
              tableHeaderSecondary: 'Tabellenkopf Sekundärfarbe',
              tableLeftColumn: 'Linke Tabellenspalte',
            },
            title: 'Tabellen-Stil',
          },
          TenantLogo: {
            bigImage: 'Die Bilddatei ist zu groß. Bitte wähle Datei mit maximal 1000px Höhe und Breite',
            logo: 'Logo',
            remove: 'Logo entfernen',
            select: 'Logo auswählen',
            title: 'Logo der Kanzlei',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Farbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              lineHeight: 'Zeilenabstand',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              h1: 'h1',
              h2: 'h2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial kleiner Text',
              textBlock: 'Standard-Text',
              textLessImportant: 'Unwichtiger-Text',
              title: 'Titel',
            },
            title: 'Text-Stile für Dokumente',
          },
          confirmSubmit: 'Bitte bestätige kurz, dass du die Farbe ändern möchtest. Möchtest du die Farbe nun ändern?',
          customize: 'Detail-Einstellungen anzeigen',
          errors: {
            reset: 'Es gab ein Problem beim resetten der Stile',
          },
          inputs: {
            font: {
              placeholder: 'Wähle eine Schriftart',
            },
          },
          reset: 'Stile zurücksetzen',
          resetConfirmModalMessage:
            'Achtung: möchtest du wirklich die Stile zurücksetzen? Du wirst deine Farb-Einstellungen verlieren. Fortfahren?',
          title: 'Corporate Identity',
        },
        DigitalSignaturePreferences: {
          additionalNotificationSettings: {
            cardLabel: 'Weitere Benachrichtigungseinstellungen',
            notifySigneesAboutSignedBySignee: {
              label:
                'Sollen Signierer per E-Mail darüber benachrichtigt werden, wenn ein anderer Signierer im Signaturprozess signiert hat?',
              tooltip:
                'Hier kannst du einstellen, ob anderer Signierer per E-Mail darüber informiert werden sollen, wenn ein Signierer im selben Signaturprozess signiert hat. Du kannst in der nächsten Option einstellen, welche E-Mail Vorlage dafür verwendet wird.',
            },
            notifySigneesAboutSignedBySigneeTemplate: {
              label:
                'Welche E-Mail Vorlage soll bei der Benachrichtigung von Signieren verwendet werden, wenn ein anderer Signierer im Signaturprozess signiert hat?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage verwendet werden soll um Signierer benachrichtigen, wenn ein anderer Signierer seinen Signiervorgang abgeschlossen hat. Du kannst verschiedene Platzhalter zur Anreicherung der E-Mail verwenden, um den Namen des Signierers einzufügen oder die Namen der Signierer die schon oder noch nicht signiert haben.',
            },
            notifyTenantSigneesAboutOpenBySignee: {
              label:
                'Sollen Kanzleimitglieder per E-Mail informiert werden, wenn ein Signierer seinen Signierprozess öffnet?',
              tooltip:
                'Hier kannst du einstellen, ob Kanzleimitglieder, die Teil eines digitalen Signaturprozesses sind, per E-Mail benachrichtigt werden, wenn ein Signierer des Signaturprozesses seinen Signiervorgang zum ersten Mal sichtet.',
            },
            notifyTenantSigneesAboutOpenBySigneeTemplate: {
              label:
                'Welche E-Mail Vorlage soll bei der Benachrichtigung der Kanzleimitglieder über das Öffnen des Signiervorgangs durch einen Signierer verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du auswählen, welche E-Mail Vorlage für die Benachrichtigung von Kanzleimitgliedern über das Öffnen eines Signiervorgangs eines Signierers verwendet werden sollen. Du kannst einen Platzhalter verwenden, um den Namen des Signierers in die E-Mail einzufügen.',
            },
          },
          automateSignatureProcess: {
            automaticRemindSigneesAboutSignatureProcess: {
              label: 'Sollen Signierer automatisch per E-Mail über einen offenen Signierprozess erinnert werden?',
              tooltip:
                'Hier kannst du konfigurieren, ob Signierer automatisch regelmäßig per E-Mail erinnert werden, wenn sie einen offenen Signierprozess haben. Du kannst das Interval in der nächsten Option konfigurieren. Es wird die selbe Vorlage verwendet, wie für manuelle Erinnerungen. Dies wird sofort auf alle aktiven Signaturvorgänge angewendet.',
            },
            automaticRemindSigneesAboutSignatureProcessInterval: {
              label: 'Wie viele Tage sollen zwischen den einzelnen Erinnerungsemails liegen?',
              tooltip:
                'Hier kannst du einstellen, wie groß das Interval in Tagen zwischen den Erinnerungsemails sein soll. Die erste automatische Erinnerungsemail wird frühstens nach diesen Interval versendet. Manuelle Erinnerungsemail werden bei den Interval mitberücksichtigt. Dies wird sofort auf alle aktiven Signaturvorgänge angewendet.',
              unit: 'Tage',
            },
            cardLabel: 'Automatisierung des Signaturprozesses',
            notifySigneesForRevokeTemplate: {
              label:
                'Welche E-Mail Vorlage soll bei der Benachrichtigung der Signierer bei einen abgebrochenen Signaturprozesses verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage für die Benachrichtigung der Signierer bei einen abgebrochenen Signiervorgang verwendet werden soll. Mit dieser Vorlage werden Signierer bei einen Abbruch des Signiervorganges durch einen Benutzer informiert, falls die Funktion zur Benachrichtigung der Signierer aktiviert ist.',
            },
            notifySigneesForSuccessfulFinishTemplate: {
              label:
                'Welche E-Mail Vorlage soll bei der Benachrichtigung der Signierer bei einen erfolgreichen Signaturprozesses verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage für die Benachrichtigung der Signierer über einen erfolgreichen Abschluss eines Signiervorgangs verwendet werden soll. Stelle sicher, dass für diese Vorlage das Versenden von Dokumenten als Anhang aktiviert ist. Mit dieser Vorlage werden Signierer, nachdem alle Signierer erfolgreich signiert haben, benachrichtigt und die signierten Dokumente zugestellt.',
            },
            postSuccessfulFinishState: {
              label:
                'Zu welchen Status soll die Projektkarte gewechselt werden, wenn der Signaturprozess erfolgreich war?',
              placeholder: 'Status beibehalten',
              tooltip:
                'Hier kannst du einstellen, ob und zu welchen Status eine Projektkarte nach einen erfolgreichen Signaturprozess gewechselt werden soll. Mit der Option “Status nicht ändern” wird der aktuelle Status der Projektkarte beibehalten.',
            },
            remindSigneeAboutDigitalSignatureProcessTemplate: {
              label:
                'Welche E-Mail Vorlage soll für Erinnerungen über einen laufenden Signaturprozess verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage für die automatische und manuelle Erinnerung eines Signierers über einen laufenden Signaturprozess verwendet werden soll. Stelle sicher, dass die verwendete Vorlage einen Button Platzhalter für den Signierlink beinhaltet, ansonsten wird der aktuelle Signierlink für den Signierer nicht mitversendet. Es wird immer die Vorlage verwendet, die zum Zeitpunkt des Signaturstarts aktiv war.',
            },
          },
          generalSettings: {
            autoSignForTenantSigneesEnabled: {
              label: 'Soll für Kanzleiteam automatisch signiert werden?',
              tooltip:
                'Hier kannst du einstellen, ob für dein Kanzleiteam und dich automatisch signiert werden soll. Es ist zu empfehlen dies zunächst mit den Kanzleiteam abzusprechen. Wenn diese Option aktiv ist, wird für jeden ausgewählten Signierer der Kanzlei automatisch signiert und dieser muss nichts mehr tun für den Digitalen Signaturvorgang.',
            },
            cardLabel: 'Generell',
            defaultLinkValidityTime: {
              label: 'Wie lange soll ein Signiervorgangslink für Signierer standardmäßig gültig sein?',
              tooltip:
                'Hier kannst du einstellen, wie lange der Link zum Signiervorgang für den Signierer verfügbar ist. Nachdem diese Zeit abgelaufen ist und der Signierer noch nicht signiert hat, kann er nicht auf seinen Signiervorgang zugreifen. Du kannst die Zeit in der Projektkartenansicht manuell anpassen, wenn der Signierer mehr Zeit benötigt.',
              unit: 'Stunden',
            },
            defaultTenantSignees: {
              label: 'Wer soll als Signierer für die Kanzlei vorausgewählt sein?',
              placeholder: 'Keine Signierer vorausgewählt',
              specialOption: 'Aktueller Ersteller',
              tooltip:
                'Hier kannst du einstellen, wer als Signierer für die Kanzlei voreingestellt sein soll. Du kannst mehrere Benutzer auswählen. Du kannst außerdem die Option “Aktueller Ersteller” auswählen. Dann wird automatisch der Benutzer vorausgewählt, der die Projektkarte erstellt oder bearbeitet. Du kannst jederzeit in der Projektkarte mehr Signierer für die Kanzlei auswählen oder einen Signierer entfernen.',
            },
            digitalSignatureEnabled: {
              disabledInfo:
                'Diese Funktion ist im Moment auf Plattformebene abgeschaltet und kann nicht genutzt werden.',
              label: 'Aktiviere Digitale Signatur für deine Kanzlei',
              tooltip:
                'Hier kannst du einstellen, ob du Digitale Signatur für deine Kanzlei verwenden möchtest. Wenn du das hier aktivierst, musst du nicht alle neuen Projektkarten digital signieren lassen. Du hast die Option für jede Projektkarte zu entscheiden, ob du diese digital signieren lassen möchtest. Wenn du die Option hier deaktivierst, kannst du keine neue Projektkarte digital signieren lassen.',
              warning: {
                description:
                  'Du versuchst die digitale Signatur auszuschalten. Falls du die Einstellungen so abspeicherst, werden alle aktiven Signiervorgänge abgebrochen.',
                title: 'Warnung',
              },
            },
            notifyClientSigneesForDigitalSignatureStartTemplate: {
              label: 'Welche E-Mail Vorlage möchtest du für die Benachrichtigung an den Mandanten verwenden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage für die Benachrichtigung von Mandanten über den Start eines Digitalen Signatur Prozesses verwendet werden soll. Stelle sicher, dass die verwendete Vorlage einen Button Platzhalter für den Signierlink beinhaltet.',
            },
            notifyTenantSigneesForDigitalSignatureStartTemplate: {
              label: 'Welche E-Mail Vorlage möchtest du für die Benachrichtigung des Kanzleiteams verwenden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche E-Mail Vorlage für die Benachrichtigung des Kanzleiteams über den Start eines Digitalen Signatur Prozesses verwendet werden soll. Stelle sicher, dass die verwendete Vorlage einen Button Platzhalter für den Signierlink beinhaltet.',
            },
            notifyUsersByMail: {
              label: 'Soll das Kanzleiteam durch E-Mails benachrichtigt werden?',
              tooltip:
                'Hier kannst du einstellen, ob dein Kanzleiteam und du über einen Digitalen Signatur Prozess per E-Mail benachrichtigt werden, wenn diese Teil davon sind. Benutzer können auch von KanzleiPilot aus auf ihre Signaturvorgänge zugreifen, wenn sie eingeloggt sind. Wenn diese Option aus ist, wird dein Kanzleiteam und du nicht über den Start eines Digitalen Signaturvorgangs per E-Mail benachrichtigt.',
            },
            passwordEnabled: {
              label: 'Soll der Signierprozess durch Passwörter abgesichert werden?',
              tooltip:
                'Hier kannst du einstellen, ob der Signiervorgang für Signierer zusätzlich über ein Passwort abgesichert werden soll. Es ist empfohlen, diese Option zu aktivieren. Das Passwort wird automatisch generiert und per E-Mail an die Signierer versendet.',
            },
            sendPasswordToSigneesTemplate: {
              label: 'Welche E-Mail Vorlage möchtest du für die Versendung von Passwörtern verwenden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welches E-Mail Vorlage für die Versendung des Passwortes für den Signiervorgang eines Signierers verwendet werden soll. Stelle sicher, dass die E-Mail Vorlage einen Platzhalter für das Passwort enthält (Du kannst den Platzhalter in der Benachrichtigungsgruppe finden). Falls die E-Mail Vorlage nicht diesen Platzhalter enthält, bekommt der Signierer sein Passwort nicht per E-Mail. Du kannst das Passwort auch manuell für jeden Signierer in der Projektkartenansicht abrufen und über einen anderen Weg weitergeben.',
            },
          },
          helperWidget: {
            howUseButton: 'Anleitung: Digitale Signatur verwenden',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung für die Einstellungen zur Digitalen Signatur',
              videoCaption: 'Erfahre mehr über die Digitale Signatur',
              videoUrl: 'https://vimeo.com/862392827',
            },
          },
          importDefaultTemplatesButton: 'Importiere Standardvorlagen für Digitale Signatur',
          importDefaultTemplatesModal: {
            cancelButton: 'Abbrechen',
            importAllTemplatesButton: 'Alle Standardvorlagen neu importieren und ersetzen',
            importError: 'Das Importieren der Standardvorlagen ist fehlgeschlagen. Probieren Sie es nochmal.',
            importMissingTemplatesButton: 'Fehlende Standardvorlagen importieren',
            importTemplatesButton: 'Standardvorlagen importieren',
            message: {
              cleanImport:
                'Willst du die Standardvorlagen für die digitale Signatur aus der gemeinsamen Bibliothek importieren?',
              incompleteImport:
                'Willst du die fehlenden Standardvorlagen für die digitale Signatur aus der gemeinsamen Bibliothek importieren oder alle Standardvorlagen importieren und die bestehenden ersetzten?',
              reimportAll:
                'Willst du die bestehenden Standardvorlagen für die digitale Signatur neu importieren? Die bestehenden werden dann durch die neu importierte Version ersetzt. Oder willst du nur die importierten Vorlagen als ausgewählte Einstellung setzen.',
            },
            setSettingsButton: 'Importierte Vorlagen als ausgewählte Vorlagen in den Einstellungen setzen',
            shouldSetSelectionValuesToImportedTemplatesSwitch: {
              label: 'Willst du die importierten Vorlagen als ausgewählte Vorlagen in den Einstellungen setzen?',
            },
            title: 'Standardvorlagen für digitale Signatur importieren',
          },
          noticeOfCancellation: {
            cardLabel: 'Widerrufsbelehrung',
            noticeOfCancellationDocumentTemplate: {
              label: 'Welche Dokumentenvorlage soll für die Widerrufsbelehrung bei Privatpersonen verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche Dokumentenvorlage für die Widerrufsbelehrung für Privatpersonen verwendet werden soll. Diese wird automatisch als Dokument beim Digitalen Signaturprozess hinzugefügt, wenn der Mandant eine Privatperson ist. Die Signierer auf Mandantenseite müssen in diesem Fall zusätzlich bestätigen, dass dieser die Widerrufsbelehrung gelesen hat und damit einverstanden ist.',
            },
          },
          resetButton: 'Digitale Signatureinstellungen zurücksetzen',
          resetConfirm: 'Bist du sicher, dass du die digitalen Signatureinstellungen zurücksetzen möchtest?',
          sepa: {
            cardLabel: 'SEPA',
            documentTemplateSelect: {
              label: 'Welche Dokumentenvorlage soll für das SEPA-Lastschriftmandat verwendet werden?',
              placeholder: 'Keine Vorlage ausgewählt',
              tooltip:
                'Hier kannst du einstellen, welche Vorlage für das SEPA-Lastschriftmandat verwendet werden soll. Dieses wird automatisch zur Projektkarte hinzugefügt, wenn ein SEPA-Lastschriftmandat durch den Mandanten erteilt wird. Du kannst Platzhalter verwenden, um die Bankaccount-Informationen und den Mandatstext in die Vorlage einzubinden.',
            },
            introductionText: {
              label:
                'Welcher einleitende Informationstext soll bei der Abfrage des SEPA-Lastschriftmandates angezeigt werden?',
              placeholder: 'Einleitungstext',
              tooltip:
                'Hier kannst du einstellen, welcher Text als Einleitung für den SEPA-Lastschriftmandat Schritt im Signierprozess angezeigt werden soll.',
            },
            mandateCompany: {
              label: 'Welcher Text soll für das SEPA-Lastschriftmandat für Unternehmen verwendet werden?',
              placeholder: 'Mandatstext für Unternehmen',
              tooltip:
                'Hier kannst du einstellen, welcher Text für das SEPA-Lastschriftmandat für Unternehmen verwendet werden soll. Der Text wird sowohl im Abfrageschritt für das SEPA-Lastschriftmandat verwendet, also auch über einen Platzhalter für das SEPA-Lastschriftmandatsdokument bereitgestellt. Beide Optionen verwenden entweder den Text für Privatpersonen oder für Unternehmen automatisch je nach Mandantentyp.',
            },
            mandatePrivate: {
              label: 'Welcher Text soll für das SEPA-Lastschriftmandat für Privatperson verwendet werden?',
              placeholder: 'Mandatstext für Privatpersonen',
              tooltip:
                'Hier kannst du einstellen, welcher Text für das SEPA-Lastschriftmandat für Privatpersonen verwendet werden soll. Der Text wird sowohl im Abfrageschritt für das SEPA-Lastschriftmandat verwendet, also auch über einen Platzhalter für das SEPA-Lastschriftmandatsdokument bereitgestellt. Beide Optionen verwenden entweder den Text für Privatpersonen oder für Unternehmen automatisch je nach Mandantentyp.',
            },
            sepaEnables: {
              label: 'Willst du ein SEPA-Lastschriftmandat während des Signierprozesses abfragen?',
              tooltip:
                'Hier kannst du einstellen, ob während des Signierprozesses ein SEPA-Lastschriftmandat abgefragt werden soll. Wenn diese Option an ist, wird der erste Signierer des Mandaten nach den Daten für ein SEPA Mandat gefragt und falls der Signierer das SEPA Mandat erteilt, wird ein Dokument für das SEPA Mandat hinzugefügt.',
            },
            sepaMode: {
              label: 'Soll das SEPA-Lastschriftmandat optional oder verpflichtend für den Mandanten sein?',
              mandatory: 'Verpflichtend',
              optional: 'Optional',
              tooltip:
                'Hier kannst du einstellen, ob der Mandant entscheiden kann, ob dieser ein SEPA-Lastschriftmandat erteilt oder nicht. Falls die Option “Verpflichtend” eingestellt ist, muss der Mandant die Informationen für das SEPA Mandat eingeben um den Signierprozess abzuschließen. Falls die Option “Optional” eingestellt ist, kann der Mandant entscheiden, ob dieser das Mandat erteilt und seine Bankverbindung hinterlegt oder nicht.',
            },
          },
          title: 'Digitale Signatur',
          warningCard: {
            description:
              'Deine digitale Signatur befindet sich gerade in der Alpha-Phase und wir testen sie intensiv. Bitte beachte, dass einige Funktionen möglicherweise noch nicht wie erwartet funktionieren und sich im Laufe der Entwicklungsphase noch ändern könnten.',
            message: 'HINWEIS',
          },
        },
        Email: {
          dkimAlert: {
            description:
              'Um E-Mails über deine eigene Domain zu versenden, musst du einen DKIM Eintrag in deinen DNS Einstellungen hinterlegen. Bitte kontaktiere deinen Domain-Administrator, um den Eintrag zu hinterlegen. Du kannst den Eintrag auch selbst hinterlegen, wenn du Zugriff auf deine DNS Einstellungen hast. Wenn du den Eintrag selbst hinterlegen möchtest findest du hier mehr Informationen: ',
            message: 'Hinweis',
          },
          dkimHelperWidget: {
            howUseButton: 'Anleitung: Domain mit DKIM authentifizieren',
            modalInfo: {
              helpText: 'Hier findest du weitere Anleitungen zum Thema DKIM: ',
              imageUrl: '',
              ionosGuide: {
                link: 'https://www.ionos.de/digitalguide/e-mail/e-mail-sicherheit/dkim-domainkeys/',
                title: 'IONOS: DKIM einrichten für eine bessere E-Mail-Zustellbarkeit',
              },
              stratoGuide: {
                link: 'https://www.strato.de/faq/mail/wie-kann-ich-fuer-meine-domain-die-dkim-einstellungen-aendern/',
                title: 'Strato: Wie kann ich für meine Domain die DKIM Einstellungen ändern?',
              },
              title: 'DKIM einrichten',
              videoCaption: 'Erfahre, wie du die DKIM Einträge in deinen DNS Einstellungen hinterlegen kannst',
              videoUrl: 'https://vimeo.com/922503158/cad1f17345',
            },
          },
          error: {
            couldNotGetDomainConfig:
              'Ein Fehler ist aufgetreten bei der Verarbeitung deiner Domainkonfiguration. Bitte versuche es später erneut',
            domainAuthenticationRequestFailed:
              'Ein Fehler ist aufgetreten bei der Verifizierung deiner Domain. Bitte versuche es später erneut.',
            invalidDomainName:
              'Deine Domain scheint ungültig zu sein, bitte überprüfe deine Eingabe und versuche es erneut.',
          },
          inputs: {
            activeUserToBCC: {
              label: 'Sende BCC Kopie an aktuellen Benutzer',
              tooltip:
                'Soll der aktuelle Benutzer beim Senden einer E-Mail eine Kopie an seine E-Mail-Adresse erhalten? Wir empfehlen, die Funktion anzuschalten.',
            },
            attachmentsSizeLimit: {
              label: 'Größenlimit für E-Mail Anhang (MB)',
            },
            bcc: {
              label: 'Kopie aller E-Mails an folgende E-Mail als BCC senden',
            },
            customDomain: {
              introtext:
                'Deine Domain wird automatisch aus der Absender-Adresse ermittelt. Im Moment kann nur die Absender-Adresse hinterlegte Domain authentifiziert werden.',
              label: 'Deine Domain',
              notAuthText:
                'Die Domain ist noch nicht authentifiziert. Bitte nehme die unten aufgezeigte Konfiguration vor und klick dann auf `Konfiguration prüfen`.',
              placeholder: 'Domain',
              subHeading: 'Basis DKIM Konfiguration',
              tooltip: 'Deine Domain wird aus der Absender E-Mail Adresse abgeleitet.',
              useCustomDomain: 'Über eigene Domain senden (DKIM & SPF)',
              validationError: 'Bitte gib eine gültige Domain ein',
            },
            dkimStatusCheck: {
              checkStatus: 'Konfiguration prüfen',
              heading: 'Finale Überprüfung',
              headingAppendix: '(Type: TXT)',
              status: {
                error: 'Status: Fehler beim Prüfen',
                last_checked: ', zuletzt geprüft am {{date}}',
                loading: 'Status: wird geprüft...',
                success_connected: 'Status: verbunden',
                success_notConnected: 'Status: nicht verbunden',
              },
              tooltip: {
                connected: 'Authentifiziert',
                notConnected: 'Nicht authentifiziert',
              },
            },
            dnsSettings: {
              dkimRecord: {
                heading: 'DKIM-Eintrag',
                label: 'DKIM-Eintrag Wert',
                prefixLabel: 'DKIM-Eintrag Hostname',
                type: 'TXT',
                typeLabel: 'DKIM-Eintrag Typ',
              },
              dmarcRecord: {
                description:
                  'Um die Zustellbarkeit deiner E-Mails zu erhöhen, empfehlen wir dir einen zusätzlichen Eintrag für eine DMARC-Richtlinie in deinen DNS-Einträgen zu hinterlegen. Falls du noch keine Richtlinie für DMARC in deiner Domain hinterlegt hast, empfehlen wir folgenden Eintrag (Empfehlung von unseren E-Mail Dienstleisters): ',
                heading: 'Optional: DMARC-Eintrag',
                hostname: '_dmarc',
                hostnameLabel: 'DMARC-Eintrag Hostname',
                label: 'DMARC-Eintrag Wert',
                message: 'Hinweis zur Verbesserung der Zustellbarkeit',
                type: 'TXT',
                typeLabel: 'DMARC-Eintrag Typ',
                value: 'v=DMARC1;p=none;rua=mailto:rua@dmarc.brevo.com',
              },
              downloadButton: 'Konfiguration herunterladen',
              heading: 'DNS-Einträge für Domain-Administrator',
              introAlertText:
                'Hier siehst du die Konfigurationsdetails um deinen Domain für DKIM und SPF zu konfigurieren. Die hier aufgelisteten Einträge müssen im DNS-Server deiner Domain angelegt werden. Wenn du der Verantwortliche der Domain bist oder der Verantwortliche deiner Domain ein Kanzleimitglied ist, können die Namen und Werte der Einträge durch Klick auf des jeweilige Feld hier kopiert werden. Wenn du jemanden Extern für die Verwaltung der Domain beauftragt hast, musst du die Informationen für die Einträge den Beauftragten zu kommen lassen. Ein Eintrag besteht aus jeweils einen Typ, einen Hostname und den Wert des Eintrages.',
              spfRecord: {
                heading: 'SPF-Eintrag',
                hostname: '@',
                label: 'SPF-Eintrag Wert',
                prefixLabel: 'SPF-Eintrag Hostname',
                type: 'TXT',
                typeLabel: 'SPF-Eintrag Typ',
              },
            },
            from: {
              invalidEmailFormat:
                'Bitte gib eine gültige E-Mail Adresse in diesem Format: Max Mustermann <max.m@domain.de> ein',
              label: 'Absender E-Mail Adresse',
              tooltip: 'Die hier eingetragene E-Mail erscheint als Absender E-Mail Adresse.',
            },
            mailServiceProvider: {
              default: 'Über KanzleiPilot Server senden',
              label: 'E-Mail Service-Provider',
              smtp: 'Über eigenen SMTP-Server senden',
            },
            options: {
              label: 'Verbindungs-Optionen',
              requireTLS: {
                tooltip:
                  'Wenn hier ein Haken gesetzt und "TLS aktivieren" deaktiviert ist, versucht der Server STARTTLS zu verwenden.',
              },
              useSSLv3: {
                tooltip:
                  'SSL v3 ist eine Alternative, die wir verwenden, wenn TLS nicht funktioniert (Port 465). For Port 587 oder 25 kannst du es optional deaktivieren.',
              },
              useTLS: {
                tooltip: 'Aktiviere TLS, wenn du Port 465 nutzt. Lasse es Port 587 oder 25 abgewählt',
              },
            },
            requireTLS: 'TLS verbindlich anfordern',
            smtpPassword: 'SMTP Passwort',
            smtpPort: 'SMTP Port',
            smtpServer: 'SMTP Server',
            smtpUserName: 'SMTP Nutzername',
            useSSLv3: 'SSL aktivieren',
            useTLS: 'TLS aktivieren',
          },
          mailServiceIsNotSmtp:
            'Du musst zuerst die Einstellung "über eigenen SMTP-Server senden" auswählen und speichern',
          otherSettingTitle: 'Sonstige Einstellungen',
          saveAndTestEmail: 'Teste den E-Mail-Versand',
          saveAndTestEmailAlert: {
            description:
              'Mit der Option "über KanzleiPilot Server senden" kannst du ganz ohne technische Konfiguration E-Mails über KanzleiPilot versenden. Mit der Option werden die E-Mails über den E-Mail Server von KanzleiPilot gesendet. Als Absender-Adresse erscheint beim Empfänger z.B. "deine.email@kanzlei.de gesendet über kanzleipilot.de". Alternativ kannst du mit der Option "über eigenen SMTP-Server senden" die E-Mails über deinen eigenen Server versenden - "gesendet über kanzleipilot.de" taucht damit dann nirgendwo auf. Bitte kontaktiere deinen ASP Dienstleister für die Zugangsdaten zu deinem SMTP Server.',
            message: 'Tipp',
          },
          saveAndTestEmailModal: {
            cancelText: '$t(common.cancel)',
            errorMessage: 'E-Mail konnte nicht versendet werden',
            okText: '$t(common.ok)',
            successMessage: 'E-Mail erfolgreich versendet',
            title:
              'Wir werden, mit den zuletzt gespeicherten Einstellungen, eine Text-E-Mail an deine Adresse ({{email}}) schicken',
          },
          setBestSMTPSettingsTitle:
            'Du hast gerade die SMPT Einstellungen geändert. Möchtest du, dass wir nun die sicherste Port und Verbindungs-Option für dich finden?',
          settingTitle: 'SMTP Server Einstellungen',
          signatureTitle: 'E-Mail Signatur',
          smtpSetting: {
            button: 'Finde und setze beste SMTP Konfiguration',
            found:
              'Wir haben eine SMTP-Konfiguration gefunden und die Einstellungen aktualisiert. Bitte drücke den "Teste den E-Mail-Versand"-Knopf um eine Test-Email zu versenden.',
            loading:
              'Wir suchen gerade das beste SMTP Setting für den Host. Bitte warte und lass diese Seite geöffnet...',
            notFound:
              'Wir haben leider KEINE passende SMTP Konfiguration gefunden. Etwas stimmt nicht. Bitte überpüfe, ob bei der Eingabe des SMTP Nutzernamens, Passworts oder Servers ein Tippfehler existiert. Du kannst unten auf "Details anzeigen" drücken, um zu sehen, welche Einstellungen wir getestet haben.',
            showDetails: 'Details anzeigen',
            validationError:
              'Bitte fülle zuerst in der Form unten folgende Felder aus: SMTP Nutzername, SMTP Passwort, SMTP Server und From". Drücke dann den Knopf erneut.',
          },
          title: 'E-Mail Einstellungen',
        },
        ImportCompanies: {
          alert: {
            step1: {
              description: 'Excel-Muster herunterladen',
              linkDescription: '/StammdatenImportMusterdatei.xlsx',
              message: 'Lade hier eine CSV-Datei hoch, um Mandantendaten zu importieren',
            },
            step3: {
              description: '',
              message:
                'Wähle, welcher Wert automatisch gesetzt werden soll, sollte für einen Datensatz KEIN Unternehmenstyp vorhanden sein.',
            },
          },
          alertLimitCompany:
            'Deine Lizenz reicht nicht aus, um {{numberCompaniesInsert}} Mandanten zu importieren. Du hast nur noch {{nrOfCompaniesLimit}} freie Mandantenplätze. Bitte kontaktiere das KanzleiPilot-Team, damit wir dir helfen können.',
          completePage: {
            numberInsert: '{{count}} neuen Mandant angelegt',
            numberInsert_plural: '{{count}} neue Mandanten angelegt',
            numberUpdate: '{{count}} Mandant aktualisiert',
            numberUpdate_plural: '{{count}} Mandanten aktualisiert',
            tip: 'Hinweis: Die Änderungen kannst du in der Mandantenansicht nachvollziehen.',
            title: 'Import erfolgreich abgeschlossen.',
          },
          errors: {
            duplicateIdentifier:
              'Diese Zeile enthält eine Mandantennummer, die in mindestens einer weiteren Zeile verwendet wird. Mandantennummern müssen eindeutig sein. Bitte bearbeite deine CSV-Datei, um diese Zeile zu importieren.',
            duplicateName:
              'Diese Zeile enthält einen Mandantennamen, der in mindestens einer weiteren Zeile verwendet wird. Mandantennamen müssen eindeutig sein. Bitte bearbeite deine CSV-Datei, um diese Zeile zu importieren.',
          },
          fields: {
            city: 'Ort',
            cityCode: 'PLZ',
            commercialObject: 'Unternehmensgegenstand',
            country: 'Land',
            defaultCompanyType: 'Standard Unternehmensform',
            houseNumber: 'Straße-Nr',
            identifier: 'Mandanten-Nummer',
            importAcademicTitle: 'Akademischer Grad',
            importCompanyType: 'Unternehmensform',
            importEmail: 'E-Mail',
            importSalutation: 'Anrede',
            name: 'Name',
            street: 'Straße',
          },
          navigationButtons: {
            import: '$t(common.navigationButtons.import)',
            next: '$t(common.navigationButtons.next)',
            previous: '$t(common.navigationButtons.previous)',
          },
          numberImport: '{{numberCompaniesInsert}} Mandanten zum Hinzufügen',
          numberUpdate: '{{numberCompaniesUpdate}} Mandanten zum Aktualisieren',
          status: {
            invalid: 'Ungültig',
            valid: 'Korrekt',
          },
          step1: 'Datensatz hochladen',
          step2: 'Spalten zuweisen',
          step3: 'Standardwerte setzen',
          step4: 'Vorschau',
          table: {
            after: 'After',
            before: 'Before',
            city: '$t(admin.Setting.ImportCompanies.fields.city)',
            cityCode: '$t(admin.Setting.ImportCompanies.fields.cityCode)',
            columnSource: {
              placeholder: 'bitte auswählen',
              title: 'Spalte in CSV-Datei',
            },
            columnTarget: 'Ziel',
            companyIdentifier: '$t(admin.Setting.ImportCompanies.fields.companyIdentifier)',
            country: '$t(admin.Setting.ImportCompanies.fields.сountry)',
            importType: {
              insert: 'neu',
              label: 'Aktion',
              update: 'aktualisieren (existiert bereits)',
            },
            name: '$t(admin.Setting.ImportCompanies.fields.name)',
            status: 'Status',
            street: '$t(admin.Setting.ImportCompanies.fields.street)',
          },
          title: 'Mandanten importieren',
          uploadButton: 'Lade CSV-Datei hoch',
        },
        LicenseOverview: {
          columns: {
            licenseStarted: 'Lizenz gestartet am',
            nrOfCompaniesCreated: 'Anzahl von Mandanten im System',
            nrOfCompaniesLimit: 'Mandanten-Limit',
            nrOfUsersCreated: 'Anzahl von Benutzern im System',
            nrOfUsersLimit: 'Anzahl durch Lizenz erlaubter Benutzer',
          },
          restartOnboarding: 'Einrichtungsassistent neu starten',
          title: 'Lizenz Übersicht',
        },
        MonthlyPaymentPreferences: {
          documentsSettings: {
            cardLabel: 'In den Dokumenten',
            infoAlertText:
              'Bitte beachte, dass du die Texte in den Dokumentenvorlagen im Block „Ihre Investition“ anpassen kannst.',
          },
          generalSettings: {
            cardLabel: 'Allgemeine Einstellungen',
            monthlyPaymentMode: {
              disabledOption: 'Deaktiviert',
              disabledOptionTooltip: 'Die Funktion "Monatliche Zahlung" ist deaktiviert.',
              label: 'Wie möchtest du die monatliche Zahlung nutzen?',
              optionalOption: 'Optional: Der Mandant entscheidet',
              optionalOptionTooltip:
                'Der Mandant kann entscheiden, ob er eine monatliche Zahlung für monatliche und jährliche Leistungen tätigen möchte. Du kannst dies in der interaktiven Projektkarte für den Mandanten auswählen – oder keine Auswahl treffen. In diesem Fall kann der Mandant im Vertrag seinen Wunsch ankreuzen und / oder beim digitalen Signieren eine Auswahl treffen.',
              requiredOption: 'Fest ausgewählt: Für jeden Mandanten aktiviert',
              requiredOptionTooltip:
                'Der Mandant hat keine Wahl: in den Dokumenten wird fest vereinbart, dass eine monatliche Zahlung getätigt wird.',
            },
            monthlyPaymentTitle: {
              label: 'Wie möchtest du die Monatliche Zahlung benennen?',
              tooltip: 'Der Name erscheint in der Projektkarte neben dem kalkulierten Betrag.',
            },
            roundingOptions: {
              label: 'Möchtest du die monatliche Zahlung runden?',
              tooltip:
                'Soll die monatliche Zahlung gerundet werden? Wenn du im Unternehmerhonorar-Modus arbeitest, wird das Unternehmerhonorar gerundet.',
            },
          },
          helperWidget: {
            howUseButton: 'Anleitung: Monatliche Zahlungen verwenden',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung für die Einstellungen zu Monatliche Zahlungen',
              videoCaption: 'Erfahre, mehr über Monatliche Zahlungen',
              videoUrl: 'https://vimeo.com/862502268',
            },
          },
          shoppingCartSettings: {
            cardLabel: 'In der interaktiven Projektkarte',
            defaultOption: {
              label: 'Welche Standardauswahl soll bei neu erstellten Projektkarten verwendet werden?',
              optionDecideLater: 'Später entscheiden',
              optionNo: 'Nein',
              optionYes: 'Ja',
              tooltip:
                'Hier kannst du auswählen, welche Entscheidung vorausgewählt sein sollte, falls die Monatlichen Zahlungen für den Mandanten optional sind. Diese Entscheidung kann beim Erstellen der Projektkarte geändert werden, falls es der Mandant wünscht.',
            },
            helpText: {
              label: 'Erklärung des Regelwerks für die Mandantschaft',
              tooltip:
                'Erscheint in der interaktiven Projektkarte als Info-Text neben dem Namen und dem Betrag der monatlichen Zahlung.',
            },
            question: {
              label: 'Frage-Text, der der Mandantschaft bei optionaler Entscheidung angezeigt wird',
              tooltip:
                'Diese Frage wird der Mandantschaft in der interaktiven Projektkarte und im Signaturprozess angezeigt, wenn die Entscheidung für oder gegen eine monatliche Zahlung optional ist.',
            },
          },
          signatureSettings: {
            cardLabel: 'Im Signatureprozess',
            signaturePageIntroductionText: {
              label: 'Einleitungstext',
              tooltip:
                'Diese Erklärung sieht ein Mandant, während dieser eine Auswahl über Monatliche Zahlungen während des Signaturprozesses tätigt.',
            },
            signaturePageOptionNo: {
              label: 'Option: Nein',
              tooltip: 'Hier kannst du die Beschriftung der Nein-Option für den Signaturprozess vornehmen.',
            },
            signaturePageOptionYes: {
              label: 'Option: Ja',
              tooltip: 'Hier kannst du die Beschriftung der Ja-Option für den Signaturprozess vornehmen.',
            },
            signaturePageTitle: {
              label: 'Überschrift',
              tooltip:
                'Diese Überschrift sieht ein Mandant, wenn dieser Dokumente unterzeichnet und noch keine Entscheidung über die Nutzung von Monatlichen Zahlungen gemacht hat, als Seitenüberschrift für die Auswahl, ob der Mandant Monatliche Zahlungen nutzen will oder nicht.',
            },
          },
          title: 'Monatliche Zahlung',
        },
        ShoppingCartPreferences: {
          HelperWidget: {
            howUseButton: 'Anleitung: Voreinstellungen verwenden',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung für die Voreinstellungen der Projektkarte',
              videoCaption: 'Erfahre, mehr über die Projektkarte Voreinstellungen',
              videoUrl: 'https://vimeo.com/541298518',
            },
          },
          card: {
            customPrice: 'Individuelle Honorare',
            viewOptions: 'Setze deine Standard Ansichtsoptionen',
          },
          fields: {
            allowCustomPrices: {
              label: 'Erlaube Nutzern, individuelle Honorare zu setzen',
              tooltip:
                'Wenn diese Option aktiviert ist, kannst du in der interaktiven Projektkarte Standardhonorare überschreiben und so für einzelne Leistungen individuelle Honorare vereinbaren. Klicke in der interaktiven Projektkarte einfach auf das Honorar einer Leistung, um ein individuelles Honorar zu setzen',
            },
            feeType: {
              tooltip:
                'Kalkulations-Modus in dem KanzleiPilot die Honorare kalkuliert. Im Standard Modus ist alles wie gehabt: einmalige, monatliche und jährliche Leistungen werden transparent dargestellt. Beim Unternehmerhonorar Modus werden monatlich und jährliche Positionen zu einem monatlichen Honorar zusammengefasst und ihre Einzelhonorare werden mit dem Hinweis „Die Leistung ist im Unternehmerhonorar inklusive“ versteckt. Einmalige Leistungen werden auch im Unternehmerhonorar transparent dargestellt.',
            },
            showCalculated: {
              label: 'Zeige Standardhonorare in Dokumenten',
              tooltip:
                'Hier kannst du den Standard für das Anzeigen von Standardhonoraren bei gesetzten individuellen Honorar konfigurieren. Wenn diese Option aktiv ist, werden in Dokumenten auch die Standardhonorare angezeigt, wenn ein individuelles Honorar konfiguriert ist. Dies kann in jeder Projektkarte beim Setzen des individuellen Honorars individuell angepasst werden.',
            },
            showCategoryTotal: {
              label: 'Honorarvorschau für jeden Bereich anzeigen',
              tooltip:
                'In der interaktiven Projektkarte hat jeder Bereich eine Honorarvorschau. Hier kannst du einstellen, ob diese Vorschau im Standard angezeigt oder versteckt sein soll.',
            },
            showDiscount: {
              label: 'Nachlässe anzeigen',
              tooltip:
                'Sollen Nachlässe angezeigt werden? Wir empfehlen dir: Schalte die Anzeige von Nachlässen im Standard AUS und fokussiere dich darauf, dem Mandanten seinen Nutzen zu verdeutlichen (siehe “Einstellungen > Projektkarte > Projektkarte Voreinstellungen”).',
            },
            showPrice: {
              label: 'Honorare anzeigen',
              tooltip:
                'Sollen Honorare beim Öffnen der Projektkarte angezeigt werden? Wir empfehlen dir: Schalte die Honoraranzeige im Standard AUS (siehe “Einstellungen > Projektkarte > Projektkarte Voreinstellungen”).',
            },
          },
          pricingSetting: {
            roundPrice: {
              defaultOption: 'Globale Einstellung anwenden',
              title: 'Runde alle Honorare wie folgt:',
            },
            showDigits: {
              title: 'Zeige Nachkommastellen',
            },
            title: 'Honorare runden',
          },
          reset: 'Voreinstellungen zurücksetzen',
          resetConfirmModalMessage: 'Bist du sicher, dass du die Voreinstellungen zurücksetzen möchtest?',
          selectDefaultEmailTemplate: 'Standard E-Mail Vorlage auswählen',
          title: 'Projektkarte Voreinstellungen',
        },
        TenantCompany: {
          HelperWidget: {
            howUseButton: 'Anleitung: Stammdaten der Kanzlei einstellen',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung: Stammdaten der Kanzlei einstellen',
              videoCaption: 'Erfahre, wie du die Stammdaten deiner Kanzlei einstellen kannst',
              videoUrl: 'https://vimeo.com/541298316',
            },
          },
          inputs: {
            city: {
              title: 'Stadt',
            },
            cityCode: {
              title: 'PLZ',
            },
            country: {
              title: 'Land',
            },
            creditorIdentNr: {
              title: 'Gläubiger-Identifikationsnummer',
            },
            houseNumber: {
              title: 'Hausnummer',
            },
            name: {
              title: 'Name der Kanzlei',
            },
            officeEmail: {
              title: 'E-Mail (Sekretariat)',
            },
            phoneNumber: {
              title: 'Telefon',
            },
            street: {
              title: 'Straße',
            },
            website: {
              title: 'Webseite',
            },
          },
          title: 'Unsere Kanzlei',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Neuen Bereich anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Ein Bereich mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name des Bereichs',
        nameFieldLabel: 'Name',
        ok: '$t(common.ok)',
      },
      addDiscountButton: 'Neuen Nachlass anlegen',
      addDocumentTemplateModal: {
        addModalTitle: 'Neuen Dokumentenvorlage anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Eine Dokumentenvorlage mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name der Dokumentenvorlage',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Neues Eingabefelder anlegen',
      addItemButton: 'Neue Leistung anlegen',
      addStaticItemButton: 'Neue Leistung anlegen',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        guidanceText: {
          label: '$t(common.fields.guidanceText.label)',
        },
        guidanceVideoId: {
          label: '$t(common.fields.guidanceVideoId.label)',
        },
        internalNote: {
          label: 'Interne Notiz für KanzleiPilot-Team',
        },
        introForDocument: {
          label: '$t(common.fields.introForDocument.label)',
          tooltip:
            'Wird im Blocktyp “Details zu den ausgewählten Dienstleistungen” unterhalb des Bereichsnamens angezeigt und erscheint so z.B. im Angebot. Gib hier eine Vogelperspektive über den Bereich, beschreibe den Nutzen für den Mandanten und positioniere dich als Experte.',
        },
        name: {
          label: 'Name',
          tooltip: 'Name des Bereiches. Erscheint so in der interaktiven Projektkarte und in den Dokumenten.',
        },
        subject: {
          tooltip:
            'Kurzbeschreibung, die in der interaktiven Projektkarte unterhalb des Bereichsnamens erscheint. Sinnvoll, um deinen Expertenstatus und den Nutzen für den Mandanten zu betonen.',
        },
      },
      deleteCategory: 'Bereich löschen',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Bereich löschen möchtest?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Nachlass löschen möchtest?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du das Eingabefelder löschen möchtest?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Leistung löschen',
        title: 'Bist du sicher, dass du die Leistung löschen möchtest?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Leistung löschen möchtest?',
      },
      deleteStatusConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title:
          'Bist du sicher, dass du den Status löschen möchtest? Existierende Projektkarten mit diesem Status werden auf den Standard-Status gesetzt',
      },
      deleteUserConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title:
          'Bist du sicher, dass du den Benutzer löschen möchtest? Falls der Benutzer die Einladung schon angenommen hat, wird dieser nur deaktiviert.',
      },
      deleteVariableConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Variable löschen möchtest?',
      },
      discountFieldModal: {
        addModalTitle: 'Neuen Nachlass anlegen',
        duplicatedErrorMessage: 'Ein Nachlass mit diesem Namen existiert bereits!',
        editModalTitle: 'Nachlass bearbeiten',
        nameFieldDescription: 'Name des Nachlasss',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Typ',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Wert des Nachlasss (in %)',
        valueFieldLabel: 'Wert',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Wert',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        titleDelete: 'Nachlass wird in einer bestehenden Projektkarte verwendet. Möchtest du ihn löschen?',
        titleUpdate: 'Der Nachlass wird in einer existierenden Projektkarte verwendet. Möchtest du ihn ändern?',
      },
      discountsTitle: 'Nachlässe',
      duplicateCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Ein Bereich mit diesem Namen existiert bereits. Bitte ändere den Namen',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Bereich duplizieren',
        title: 'Bereich duplizieren',
      },
      duplicateItemConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage:
          'Name des Duplikats von Leistungen sind in KanzleiPilot eindeutig. Bitte trage den Namen des Duplikats ein.',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Leistung duplizieren',
        title: 'Bitte trage den Namen für die neue Leistung ein',
      },
      guidanceAlert: {
        category: 'Tipp vom KanzleiPilot-Team',
        closeForever: {
          buttonTitle: 'Für immer schließen',
          title: 'Do you want to hide forever {{name}} guide?',
        },
        item: 'Tipp vom KanzleiPilot-Team',
      },
      importCategoryPresetsModal: {
        errorMessage:
          'Ein Bereich mit diesem Namen existiert bereits. Bitte nenne den existierende Bereich zunächst um',
        import: 'importieren',
        title: 'Bereich und Leistungen aus Bibliothek importieren',
      },
      inputFieldModal: {
        addModalTitle: 'Neues Eingabefelder anlegen',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'Neue Option anlegen',
          boxLabel: 'Auswahlfeld Optionen',
          modal: {
            addTitle: 'Neue Option anlegen',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Eine Option mit diesem Namen existiert bereits!',
            editTitle: 'Option bearbeiten',
            nameFieldDescription: 'Option Name',
            nameFieldLabel: 'Name',
            ok: '$t(common.ok)',
            valueFieldDescription: 'Option Wert',
            valueFieldLabel: 'Wert',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Option löschen möchtest?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Wert',
          },
        },
        duplicatedErrorMessage: 'Ein Eingabefelder mit diesem Namen existiert bereits!',
        editModalTitle: 'Eingabefelder bearbeiten',
        fields: {
          allowedRange: {
            label: 'Erlaubter Wertebereich',
          },
          allowedRangeMaxValue: {
            label: 'Maximalwert',
            maxIsLessThenDefaultValue: 'Bitte trage einen Maximalwert ein, der größer als der Standardwert ist',
            maxIsLessThenMin: 'Bitte trage einen Maximalwert ein, der größer als der Mindestwert ist',
          },
          allowedRangeMinValue: {
            label: 'Mindestwert',
            minIsMoreThenDefaultValue: 'Bitte trage einen Mindestwert ein, der kleiner als der Standardwert ist',
            minIsMoreThenMax: 'Bitte trage einen Mindestwert ein, der größer als der Maximalwert ist',
          },
          defaultInput: {
            label: '$t(common.fields.defaultValue.label)',
          },
          defaultType: {
            input: 'manuellen Wert eintragen',
            label: 'Wie soll der Standardwert gesetzt werden?',
            variable: 'von Variable übernehmen',
          },
          defaultValue: {
            defaultValueIsOutOfRange: 'Bitte trage einen Standardwert ein, der sich im erlaubten Bereich befindet',
          },
          defaultVariable: {
            label: 'Übernehme Wert von folgender Variable',
          },
          name: {
            description: 'Eingabefelder Name',
            label: '$t(common.fields.name.label)',
          },
          optionPDFNameTitle: {
            label: 'Überschrift für Optionen',
            placeholder: 'Überschrift für Optionen',
          },
          optionPDFValueTitle: {
            label: 'Überschrift für Wert',
            placeholder: 'Überschrift für Wert',
          },
        },
        ok: '$t(common.ok)',
        pdfConfigurationLabel: 'Überschriften für PDF Dateien',
        typeFieldLabel: 'Typ',
        unitNameDescription: 'Einheit',
        unitNameLabel: 'Einheit',
        variableAlert: 'Hinweis: Der Wert der ausgewählten Variabel beträgt {{value}}',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Typ',
      },
      inputsTitle: 'Eingabefelder',
      itemModal: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: 'Anleitung: Sichtbarkeit nach Mandantentyp bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Sichtbarkeit nach Mandantentyp bearbeiten',
            videoCaption: 'Erfahre, wie sich die Sichtbarkeit auswirkt',
            videoUrl: 'https://vimeo.com/542078173',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: 'Anleitung: Eingabefelder verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Eingabefelder in Leistung verwenden',
            videoCaption: 'Erfahre, wie du die Leistungs-Position bearbeiten kannst',
            videoUrl: 'https://vimeo.com/542032063',
          },
        },
        PriceHelperWidget: {
          howUseButton: 'Anleitung: Honorare konfigurieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Honorare konfigurieren (Einführung)',
            videoCaption: 'Erfahre, wie du Honorare in KanzleiPilot konfigurierst',
            videoUrl: 'https://vimeo.com/541286429',
          },
        },
        ScalesHelperWidget: {
          howUseButton: 'Anleitung: Staffelhonorare einstellen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Konfigurieren von Staffelhonoraren',
            videoCaption: 'Erfahre, wie du Staffelhonorare leicht in die Honorarformel einbaust',
            videoUrl: 'https://vimeo.com/541286974',
          },
        },
        TextsHelperWidget: {
          howUseButton: 'Anleitung: Texte bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Texte für Leistung bearbeiten',
            videoCaption: 'Erfahre, wo die Texte erscheinen',
            videoUrl: 'https://vimeo.com/542074372',
          },
        },
        addTitle: 'Neue Leistung anlegen',
        cancel: '$t(common.cancel)',
        copied: 'Link wurde in Zwischenablage kopiert!',
        copyCLLink: 'Kopiere Link zur Leistung in der gemeinsamen Bibliothek in die Zwischenablage',
        duplicatedErrorMessage: 'Leistung mit diesem Namen existiert bereits!',
        editSubTitleForItem: 'Leistung {{itemName}} bearbeiten',
        editTitle: 'Leistung bearbeiten',
        guideAlert: {
          guidanceVideoButton: 'Display guidance video',
          guidanceVideoModalTitle: 'Guidance video',
        },
        inputs: {
          benefits: {
            label: '$t(common.fields.benefits.label) (erscheint im Angebot)',
            tooltip:
              'Nutzen für den Mandanten, der so im Angebot erscheint. Fokussiere dich hier auf den Mehrwert, den der Kunde durch seine Investition in die Leistung hat.',
          },
          calculationMode: {
            label: '$t(common.fields.calculationMode.label)',
            tooltip:
              'Wähle hier, ob du grundsätzlich einen Wert für dein Honorar berechnen möchtest, oder rein "nach Zeitaufwand" abrechnen möchtest. Wir empfehlen dir nach Möglichkeit einen Wert zu berechnen und zu kommunizieren, da dies für deinen Mandanten deutlich transparenter ist.',
          },
          descriptionForContract: {
            label: 'Auftragsumfang (erscheint in Vergütungsvereinbarung und Steuerberatungsvertrag)',
            tooltip:
              'Im Auftragsumfang beschreibst du ganz klar den Umfang deiner Dienstleitung. Grenze hier deine Leistung klar ab. Der Text erscheint im Standard in der Vergütungsvereinbarung und im Steuerberatervertrag.',
          },
          guidanceText: {
            label: '$t(common.fields.guidanceText.label)',
            placeholder: '$t(common.fields.guidanceText.label)',
          },
          guidanceVideoId: {
            label: '$t(common.fields.guidanceVideoId.label)',
            placeholder: '$t(common.fields.guidanceVideoId.label)',
          },
          highlightBindingness: {
            label: '$t(common.fields.highlightBindingness.label)',
            tooltip:
              'Möchtest du die Verbindlichkeit des Honorars besonders hervorheben? Dann wähle z.B. "dynamisch", um klar zu kommunizieren, dass dein Honorar mit veränderten/dynamischen Honorarparametern ein anderes sein wird. Tipp: Nutze das "Bitte beachten"-Textfeld einer Leistung, um die Verbindlichkeit noch weiter zu konkretisieren.',
          },
          infoText: {
            label: 'Info-Button (erscheint in Projektkarte neben der Leistung)',
            tooltip:
              'Dieser Text erscheint in der interaktiven Projektkarte, wenn du auf den Info-Button drückst. Betone den Nutzen für den Mandanten.',
          },
          maxPrice: {
            label: '$t(common.fields.maxPrice.label)',
            tooltip:
              'Höchsthonorar, welches dann gilt, wenn das in der Honorarformel errechnete Honorar höher ausfällt. Lösche den Wert, um KEIN Höchsthonorar zu setzen.',
            tooltipOnActualCost:
              'Das Höchsthonorar wird in Dokumenten bei der Angabe der Kalkulation der Leistung mit angegeben. Das Höchsthonorar hat keinen Einfluss auf das Honorar des ganzen Bereichs oder der Menükarte. Durch löschen des Inhaltes des Feldes wird kein Höchsthonorar für die Leistung angegeben.',
          },
          minPrice: {
            label: '$t(common.fields.minPrice.label)',
            tooltip:
              'Mindesthonorar, welches dann gilt, wenn das in der Honorarformel errechnete Honorar geringer ausfällt. Lösche den Wert, um KEIN Mindesthonorar zu setzen.',
            tooltipOnActualCost:
              'Das Mindesthonorar wird in Dokumenten bei der Angabe der Kalkulation der Leistung mit angegeben. Das Mindesthonorar hat keinen Einfluss auf das Honorar des ganzen Bereichs oder der Projektkarte. Durch löschen des Inhaltes des Feldes wird kein Mindesthonorar für die Leistung angegeben.',
          },
          name: {
            label: 'Name',
            placeholder: '',
            tooltip: 'Name der Leistung. Erscheint so in der interaktiven Projektkarte und in den Dokumenten.',
          },
          notesForFeeAgreement: {
            label: 'Honorarhinweis (erscheint in Vergütungsvereinbarung)',
            tooltip:
              'Der Honorarhinweis-Text erscheint im Standard in der Vergütungsvereinbarung. Hier kannst du z.B. die rechtliche Grundlage vermerken.',
          },
          notesToEmployee: {
            label: '$t(common.fields.notesToEmployee.label)',
            tooltip:
              'Platz für Interne Hinweise für dein Kanzlei-Team. Der Text erscheint in der Lese-Ansicht für eine existierende Projektkarte. Sinnvoll, z.b: für den Hinweis zu verbundenen Auftragsnummern, etc.',
          },
          paymentInterval: {
            label: '$t(common.fields.paymentInterval.label)',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            tooltip:
              'Zahlungsintervall der Leistung. Im Unternehmerhonorar Modus werden in der Projektkarte jährliche und monatliche Leistungen für den Mandanten in einen monatlichen Honorar zusammengefasst.',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: 'Bitte beachten (erscheint in Angebot, Steuerberatungsvertrag und Vergütungsvereinbarung)',
            tooltip:
              'Stelle hier ein Regelwerk auf: Wenn gilt das Honorar, was muss der Mandant liefern? Welche Informationen benötigst du von ihm? Welche Leistung ist nicht beinhaltet? Im Standard erscheint der Texte im Angebot, der Vergütungsvereinbarung und im Steuerberatervertrag.',
          },
          pricingFormula: {
            editConstants: 'Variablen bearbeiten',
            errors: {
              invalid: 'Bitte trage eine gültige {{name}} ein',
            },
            insertVariableMenu: {
              disabledMenuTooltip: {
                categoryMonthlyAdvance:
                  'Kategorie enthält keine aktive Leistung mit dem Zahlungsinterval monatlich oder jährlich.',
                inactiveItem: 'Leistung ist inaktiv.',
                onActualCostItem: 'Platzhalter ist deaktiviert, da die Leistung nach Zeitaufwand abgerechnet wird.',
              },
              subMenu: {
                category: 'Eingabefelder aus Bereich',
                categoryMonthlyAdvance: 'Monatliches Fixhonorar des Bereichs',
                categoryTotal: 'Wert eines Bereichs',
                function: 'Funktionen',
                item: 'Eingabefelder aus Leistung',
                itemTotal: 'Wert einer Leistung',
                scaleFunctionParameters: 'Parameter der Staffelfunktion',
                variable: 'Variablen',
              },
              title: 'Platzhalter einfügen',
            },
            label: '$t(common.fields.pricingFormula.label)',
            tooltip:
              'Die Honorarformel anhand der sich das Honorar berechnet. Tipp: Schaue dir hierzu die Erklär-Videos an, um diese wichtige Funktion genau kennen zu lernen.',
          },
          roundPrice: {
            label: 'Honorar runden',
            tooltip:
              'Wähle aus ob das von der Honorarformel errechnete Honorar gerundet werden soll. Tipp: Unter Einstellungen > Projektkarte > Projektkarte Voreinstellungen“ kannst du eine globale Rundung einstellen, die du hier aber für die individuelle Leistung überschreiben kannst.',
          },
          scales: {
            addModalLabel: 'Neue Staffelzeile anlegen',
            atLeastTwoError: 'Bitte lege mindestens 2 Staffeln an',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Staffel löschen möchtest?',
            },
            duplicatedError: 'Staffeln müssen einzigartig sein',
            duplicatedErrorMessage: 'Staffel mit diesem Namen existiert bereits',
            editModalLabel: 'Staffel bearbeiten',
            emptyScaleList:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            emptyTable:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            inputs: {
              formula: {
                error: 'Honorarformel ist ein Pflichtfeld',
                label: 'Wert (Honorarformel)',
              },
              graduatedScaleMode: {
                graduated: 'Stufen',
                label: 'Modus der Staffel-Funktion',
                standard: 'Standard',
                tooltip:
                  'Im “Standard“-Modus gibt die Staffelfunktion den Wert der jeweiligen Staffel zurück - in der Honorarformlel kannst du damit dann weiter rechnen. 2) Im “Stufen“-Modus summiert die Staffelfunktion stufenweise die Werte der kleineren Staffeln mit auf. Beispiel: Bei einer Konfiguration von (1 - 3 = 2, 4 - 6 = 1,5) wird bei einem Parameter von 4 der Wert 7,50 errechnet. Kalkulation = 3*2 + 1 * 1,5',
              },
              pricingFormulaTitle: {
                label: 'Überschrift für “Wert“-Spalte',
                placeholder: '',
                tooltip:
                  'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
              },
              pricingFormulaUnit: {
                label: 'Einheit für “Wert”-Spalte',
                placeholder: 'Einheit',
                tooltip:
                  'Hier kannst du die Einheit der “Wert”-Spalte für Dokumente konfigurieren. Wenn du das Feld leer lässt, besitzt die Spalte keine Einheit. Wenn du damit Preis ausdrückst empfehlen wir dir hier “€” einzutragen.',
              },
              scaleTitle: {
                label: 'Überschrift für “Staffel“-Spalte',
                placeholder: '',
                tooltip:
                  'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
              },
              scaleUnit: {
                label: 'Einheit für “Staffel”-Spalte',
                placeholder: 'Einheit',
                tooltip:
                  'Hier kannst du die Einheit für die “Staffel”-Spalte für Dokumenten konfigurieren. Wenn du das Feld leer lässt, besitzt die Spalte keine Einheit.',
              },
              unitName: {
                label: 'Einheit',
                placeholder: 'Einheit',
              },
              value: {
                label: 'Staffel',
              },
            },
            label: 'Staffeln konfigurieren?',
            ok: '$t(common.ok)',
            pdfConfigurationLabel: 'Überschriften für Staffel-Auflistung in Dokumenten',
            pdfConfigurationUnitsLabel: 'Einheiten für Staffel-Auflistung in Dokumenten',
            table: {
              columns: {
                pricingFormula: 'Wert (Honorarformel)',
                value: 'Staffel',
              },
            },
            tooltip:
              'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
            wrapperLabel: 'Staffeln konfigurieren',
          },
          scalesList: {
            label: 'Staffel-Liste',
          },
          subTitle: {
            label: 'Kurzbeschreibung',
            placeholder: '',
            tooltip:
              'Kurzbeschreibung der Leistung, die in der interaktiven Projektkarte unterhalb des Bereichsnamens erscheint. Sinnvoll, um den Fokus auf eine bestimmte Leistung zu lenken, den Nutzen für den Mandanten zu betonen und dich als Experte zu positionieren.',
          },
        },
        internalNoteWarning: 'Interne Notiz für KanzleiPilot-Team',
        minGreaterThanMaxWarning: 'Das Mindesthonorar ist größer als das Höchsthonorar. Bitte korrigiere die Werte.',
        ok: '$t(common.yes)',
        pricingWarning:
          'Achtung: du hast in der Honorarformel ein negatives Honorar konfiguriert und gleichzeitig das Mindesthonorar auf {{value}} gesetzt. Das Mindesthonorar wird das kalkulierte Honorar überschreiben. Bitte entferne das Mindesthonorar, wenn das kalkulierte Honorar negativ sein soll.',
        tabs: {
          conditionalVisibility: 'Sichtbarkeit nach Mandantentyp',
          inputs: 'Eingabefelder',
          price: 'Honorar',
          texts: 'Texte',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          active: 'aktiv',
          name: 'Name',
          paymentInterval: 'Zahlungsintervall',
          pricingFormula: 'Honorarformel',
        },
        title: 'Leistungen',
      },
      premiumClAdvertisement:
        'Hinweis: Es sind weitere PREMIUM-Elemente verfügbar, die hier NICHT angezeigt werden. Wir haben viele fertig optimierte Honorar-Strategien in der Schublade liegen (z.B. unsere 🚀 STRATEGIE-BOOSTER Bibliothek mit bewährten Honorar-Strategien und dem Wissen von über 200 Kanzleien). Wenn du daran interessiert bist, dann schreiben Sie uns eine kurze E-Mail an <a>support@kanzleipilot.de<a/>.',
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA() Beispiel: "StBVV_TabelleA(140000)" gibt 1773 zurück',
          StBVV_TabelleB: 'StBVV_TabelleB() Beispiel: "StBVV_TabelleB(5000)" gibt 81 zurück',
          StBVV_TabelleC: 'StBVV_TabelleC() Beispiel: "StBVV_TabelleC(45000)" gibt 122 zurück',
          StBVV_TabelleD_a: 'StBVV_TabelleD_a() Beispiel "StBVV_TabelleD_a(951)" gibt 1738 zurück',
          StBVV_TabelleD_b: 'StBVV_TabelleD_b() Beispiel "StBVV_TabelleD_b(42500)" gibt 380 zurück',
          round: 'round() Beispiel: "round(7.8)" gibt 8 zurück',
          scale: 'scale(Zahl) nutzt die unten definierbare Staffel-Konfiguration',
        },
        functionsListText: 'Folgende Funktionen sind verfügbar:',
        howUseButton: 'Anleitung: Honorarformel verwenden',
        inputsListText: 'Folgende Eingabefelder sind verfügbar:',
        modalInfo: {
          helpText: '',
          title: 'Anleitung: So funktioniert die Honorarformel',
          videoCaption: 'Erfahre, wie die Honorarformel genau funktioniert',
          videoUrl: 'https://vimeo.com/541286672',
        },
        syntaxInstructions:
          'Drücke "Platzhalter einfügen" um einen Platzhalter einzufügen\nDrücke "@" um eine Liste der Eingabefelder anzuzeigen\nDrücke "#" um eine Liste der Funktionen anzuzeigen',
      },
      projectCard: {
        deletion: {
          alert: {
            chooseDesiredAction: 'Wie möchtest du vorgehen?',
            introText: 'Hier kannst du die Projektkarte löschen.',
            notRestorableWarning: 'Bitte beachte, dass gelöschte Daten nicht wiederhergestellt werden können.',
            paragraph:
              'Hintergrund: Eine Projektkarte kann mehrere Revisionen haben. Diese Revisionen entstehen, wenn du die Projektkarte bearbeitet hast, beispielsweise um ein Angebot zu korrigieren. Du hast die Möglichkeit, entweder die aktuelle Revision oder alle verbundenen Revisionen der Projektkarte zu löschen.',
          },
          deleteConfirmationModal: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
            titleDeleteProjectCard: 'Bist du sicher, dass du diese Projektkarte vollständig löschen möchtest?',
            titleDeleteProjectCardWithClient:
              'Bist du sicher, dass du diesen Mandanten und alle verbundenen Projektkarten löschen möchtest?',
            titleDeleteRevision: 'Bist du sicher, dass du diese Revision löschen möchtest?',
          },
          modalOptions: {
            abort: 'Abbrechen',
            deleteCurrentRevision: {
              button: 'Revision löschen',
              description:
                'Diese Aktion löscht nur die aktuelle Revision der Projektkarte. Alle anderen Revisionen bleiben erhalten.',
              title: 'Aktuelle Revision löschen',
            },
            deleteProjectCard: {
              button: 'Alle Revisionen löschen',
              description:
                'Diese Aktion löscht alle Revisionen dieser Projektkarte. Alle Revisionen werden dauerhaft entfernt.',
              title: 'Alle Revisionen löschen',
            },
            deleteProjectCardAndClient: {
              button: 'Mandanten vollständig löschen',
              description:
                'Diese Aktion löscht den Mandanten vollständig und alle verbundenen Projektkarten, sowie deren Revisionen.',
              title: 'Mandanten mit allen Projektkarten löschen',
            },
            title: 'Projektkarte löschen',
          },
        },
      },
      resetTextStylesConfirmation: {
        cancel: '$t(common.cancel)',
        title:
          'Bist du sicher, dass du den Text-Stil entfernen möchtest? Alle Text-Formatierung, wie z.B. Aufzählungen, fette und kursive Formatierungen, werden entfernt. Der Text selber bleibt erhalten. Fortfahren?',
        yes: '$t(common.yes)',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Neue Leistung anlegen',
        alert: {
          description:
            'Bitte trage in dem Wert-Feld Netto-Werte ein und verwende die Text-Funktion, um textbasierte Anmerkungen hinzuzufügen („+ Platzhalter einfügen > Funktionen > Text“). Tipp: In der Textfunktion kannst du auch Nummern verwenden, die dann in der Ausgabe unverändert bleiben. Beispiel: 45 € „pro angefangenen 15 Minuten“.',
          message: 'Preisangabenverordnung',
        },
        duplicatedErrorMessage: 'Leistung mit diesem Namen existiert bereits. Bitte wähle einen anderen Namen',
        editModalTitle: 'Leistung bearbeiten',
        errors: {
          isEmpty: '{{label}} ist ein Pflichtfeld',
          unwrappedText: 'Bitte schreibe Text innerhalb der Textfunktion. Sie auch den Hinweis oben.',
        },
        nameFieldDescription: 'Leistung',
        nameFieldLabel: 'Leistung',
        valueFieldDescription: 'Wert der Leistung (z.B. "90 €")',
        valueFieldLabel: 'Wert',
      },
      staticItemsTableColumns: {
        actions: '',
        name: 'Leistung',
        value: 'Wert',
      },
      staticItemsTitle: 'Statischer Leistungskatalog',
      textsTabSubmitButton: 'Änderungen speichern',
      textsTabTitle: 'Texte',
      textsTitle: 'Texte',
    },
    availableTime: {
      daysLeft_one: '(Noch {{daysLeft}} Tag gültig)',
      daysLeft_other: '(Noch {{daysLeft}} Tage gültig)',
      hoursLeft_one: '(Noch {{hoursLeft}} Stunde gültig)',
      hoursLeft_other: '(Noch {{hoursLeft}} Stunden gültig)',
      minutesLeft_one: '(Noch {{minutesLeft}} Minute gültig)',
      minutesLeft_other: '(Noch {{minutesLeft}} Minuten gültig)',
      noTimeLeft: '(Nicht mehr gültig)',
    },
    common: {
      ConditionalVisibility: {
        ConflictModal: {
          content: {
            callForAction:
              'Die Einstellungen in “Sichtbarkeit nach Mandantentyp” unterscheiden sich in den diesen Leistungen. Bitte sichte nach dem Speichern unbedingt die Einstellungen in den o.g. verbundenen Leistungen und stelle sicher, dass sie übereinstimmen. So stellst du sicher, dass die Leistungen in der Projektkarte bei gleichen Mandantentypen eingeblendet werden und die Honorar kalkulierbar sind. In folgendem Video erklären wir dir das Vorgehen kurz:',
            intro: 'Hinweis: Diese Leistung ist in den Honorarformeln mit folgenden anderen Leistungen verbunden:',
          },
          okText: 'Speichern und fortfahren',
          title: 'Konflikt mit Sichtbarkeit von verbundenen Leistungen',
          videoUrl: 'https://vimeo.com/955750713/be9a63d577',
        },
        balanceOfAccountsGmbH: 'Bilanz - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Bilanz - GmbH & Co. KG',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Bilanz - GmbH & Co. KG (Selbstbucher)',
        balanceOfAccountsGmbHSelfBooker: 'Bilanz - GmbH (Selbstbucher)',
        balanceOfAccountsOther: 'Bilanz - Personengesellschaft',
        balanceOfAccountsOtherSelfBooker: 'Bilanz - Personengesellschaft (Selbstbucher)',
        balanceOfAccountsSingle: 'Bilanz - Einzelunternehmer',
        balanceOfAccountsSingleSelfBooker: 'Bilanz - Einzelunternehmer (Selbstbucher)',
        balanceOfAccountsUG: 'Bilanz - UG (haftungsbeschränkt)',
        balanceOfAccountsUGSelfBooker: 'Bilanz - UG (haftungsbeschränkt) (Selbstbucher)',
        balanceOfUnion: 'Bilanz - Verein',
        balanceOfUnionSelfBooker: 'Bilanz - Verein (Selbstbucher)',
        cashBasisAccountingCapital: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft',
        cashBasisAccountingCapitalSelfBooker: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft (Selbstbucher)',
        cashBasisAccountingSingle: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer',
        cashBasisAccountingSingleSelfBooker: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer (Selbstbucher)',
        cashBasisAccountingUnion: 'Einnahmen-Überschuss-Rechnung - Verein',
        cashBasisAccountingUnionSelfBooker: 'Einnahmen-Überschuss-Rechnung - Verein (Selbstbucher)',
        checkAll: 'Alle auswählen',
        other: 'Sonstige Rechtsform',
        otherSelfBooker: 'Sonstige Rechtsform (Selbstbucher)',
        privatePerson: 'Privatperson',
        uncheckAll: 'Keine auswählen',
      },
      Discount: {
        absolute: 'absolut',
        percent: 'prozentual',
      },
      Error: {
        required: '{{field}} ist ein Pflichtfeld',
      },
      InputField: {
        combo: 'Auswahlfeld',
        copiedToClipboard: 'in die Zwischenablage kopiert',
        input: 'Eingabefeld',
        internal: 'Internes Eingabefeld (mit Slider)',
        name: 'Eingabefeld',
      },
      Item: {
        calculationMode: {
          onActualCost: 'nach Zeitaufwand',
          value: 'Honorarformel',
        },
        highlightBindingness: {
          dynamic: 'dynamisch',
          dynamicDativ: 'dynamischem',
          dynamicDativ_plural: 'dynamischen',
          estimated: 'geschätzt',
          estimatedDativ: 'geschätztem',
          estimatedDativ_plural: 'geschätzten',
          fixPriced: 'pauschal',
          fixPricedDativ: 'pauschalem',
          fixPricedDativ_plural: 'pauschalem',
          noHighlight: 'nicht hervorheben',
        },
        paymentIntervalValue: {
          fixedMonthly: 'Monatliches Unternehmerhonorar',
          monthly: 'monatlich',
          oneOff: 'einmalig',
          yearly: 'jährlich',
        },
        recursive:
          'Du kannst dich NICHT auf diese Leistung beziehen, da sie sich selber in ihrer Honorarformel auf diese Leistung bezieht (zirkuläre Abhängigkeit)',
        scaleError:
          'Bitte konfiguriere zunächst Staffeln, um die “scale”-Funktion in der Honorarformel verwenden zu können',
        switchToOnActualCost:
          'Honorar-Modus kann nicht "nach Zeitaufwand" umgestellt werden, da sie in Leistungen {{items}} verwendet wird.',
      },
      MoreInfoWidget: {
        book: {
          calendly: '15 Minuten Zoom-Gespräch verabreden',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          title: 'Hast du noch Fragen? Dann buche gerne ein Zoom-Gespräch',
        },
      },
      RoundPrices: {
        noRound: 'NICHT runden',
        roundUpTo1: 'Aufrunden auf 1 €',
        roundUpTo10: 'Aufrunden auf 10 €',
        roundUpTo25: 'Aufrunden auf 25 € (0, 25, 50 oder 75)',
      },
      Variables: {
        types: {
          float: 'Zahl',
          percent: 'Prozent',
          pricePerHour: 'Stundensatz (€)',
          text: 'Text',
        },
      },
      activateYourAccount: 'Jetzt Zugang aktivieren',
      alertInAcceptInvitationForm: 'Bitte setze jetzt dein Passwort, um deinen KanzleiPilot-Zugang zu aktivieren',
      and: 'und',
      buttons: {
        resendInvitation: {
          tooltip:
            'Einladung via E-Mail senden. Tipp: Falls der Nutzer sein Passwort vergessen hat, kann er einfach im Login-Bildschirm die Funktion "Passwort vergessen" nutzen',
        },
        resendVerificationEmail: 'Bestätigungsmail erneut senden',
      },
      cancel: 'Abbrechen',
      closeToastText: 'Ok, verstanden',
      columns: {
        actions: 'Aktionen',
        body: 'Inhalt',
        category: 'Bereich',
        createdAt: 'Erstellt am',
        createdBy: 'Erstellt von',
        data: 'Daten',
        document: 'Dokument',
        field: 'Field',
        internalNote: '$t(common.fields.notesToEmployee.label)',
        interval: 'Intervall',
        item: 'Leistung',
        name: 'Name',
        nrOfAllowedCompaniesByPricingPlan: 'Anzahl der erlaubten Mandanten nach Lizenz',
        nrOfAllowedUsersByPricingPlan: 'Anzahl der erlaubten Nutzer nach Lizenz',
        price: 'Honorar',
        rootAdminEmail: 'Root-Admin Email',
        rootAdminName: 'Root-Admin Name',
        status: 'Status',
        subject: 'Betreff',
        tenantName: 'Mandant',
        type: 'Type',
        value: 'Wert',
        valueMonthly: 'Wert monatlich',
        valueOneOff: 'Wert einmalig',
        valueYearly: 'Wert jährlich',
      },
      company: {
        addCompany: 'Mandant hinzufügen',
        changeCompany: 'Mandant ändern',
        editCompany: 'Mandant bearbeiten',
      },
      contact: {
        addContact: 'Kontakt hinzufügen',
        deleteContact: 'Kontakt löschen',
        editContact: 'Kontakt bearbeiten',
      },
      dataPicker: {
        thisYear: 'This year',
      },
      duplicatedErrorMessage: '{{name}} with the same name already exists',
      error: 'Fehler',
      fields: {
        actions: {
          label: 'Aktionen',
        },
        benefits: {
          label: 'Nutzen',
        },
        body: {
          label: 'Inhalt',
          placeholder: 'Inhalt',
        },
        calculationMode: {
          label: 'Kalkulation',
        },
        defaultValue: {
          label: 'Standardwert',
        },
        descriptionForContract: {
          label: 'Auftragsumfang',
        },
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        guidanceText: {
          label: 'Coaching-Text',
          placeholder: 'Coaching-Text',
        },
        guidanceVideoId: {
          label: 'Coaching-Video',
          placeholder: 'Coaching-Video',
        },
        highlightBindingness: {
          label: 'Verbindlichkeit hervorheben',
        },
        infoText: {
          label: 'Info-Button',
        },
        introForDocument: {
          label: 'Einleitung für Dokumente',
        },
        isDefault: {
          label: 'Bei Neuanlage importieren?',
        },
        maxPrice: {
          label: 'Höchsthonorar',
        },
        minPrice: {
          label: 'Mindesthonorar',
        },
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        nameRaw: {
          label: '$t(common.fields.name.label)',
        },
        notesForFeeAgreement: {
          label: 'Honorarhinweis',
        },
        notesToEmployee: {
          label: 'Interner Hinweis (für Kanzlei-Team)',
        },
        nrOfAllowedCompaniesByPricingPlan: {
          label: 'Erlaubte Anzahl Mandanten (Lizenzlimit)',
          placeholder: 'Erlaubte Anzahl Mandanten (Lizenzlimit)',
        },
        nrOfAllowedUsersByPricingPlan: {
          label: 'Erlaubte Anzahl von Nutzern (Lizenzlimit)',
          placeholder: 'Erlaubte Anzahl von Nutzern (Lizenzlimit)',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        passwordConfirmation: {
          label: 'Passwort bestätigen',
          placeholder: 'Passwort bestätigen',
        },
        paymentInterval: {
          label: 'Zahlungsintervall',
        },
        pleaseNote: {
          label: 'Bitte beachten',
        },
        position: {
          label: 'Position',
          placeholder: 'Position',
        },
        pricingFormula: {
          label: 'Honorarformel',
        },
        projectName: {
          label: 'Projektname',
        },
        role: {
          label: 'Berechtigung',
          placeholder: 'Berechtigung',
        },
        status: {
          label: 'Status',
        },
        subject: {
          label: 'Betreff',
          placeholder: 'Betreff',
        },
        type: {
          label: 'Typ',
        },
        value: {
          label: 'Wert',
        },
        valueRaw: {
          label: '$t(common.fields.value.label)',
        },
      },
      howUseButton: 'Anleitung ansehen',
      htmlModalFooterInfoWidget: {
        infoButtonText: 'Anleitung: Seitenumbrüche in der Vorschau',
        modalInfo: {
          helpText:
            '<bold>Wichtiger Hinweis:</bold> Diese Vorschau dient nur zur inhaltlichen Darstellung der Elemente, die später in der finalen PDF-Datei angezeigt werden. Es kann vorkommen, dass hier Zeilenumbrüche anders erscheinen oder fehlen, da diese in der PDF-Datei optimiert und entsprechend der Konfiguration in den Blöcken angepasst werden. Bitte beachte, dass dies nur eine Vorschau ist und die PDF-Datei später korrekt dargestellt wird. <br /><bold>Tipp:</bold> Während der Konfigurationsphase empfiehlt es sich, die PDF-Datei regelmäßig zu überprüfen, um ein Gefühl für die dynamische Darstellung zu bekommen.',
          imageUrl: '',
          title: '$t(common.htmlModalFooterInfoWidget.infoButtonText)',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/1010563214',
        },
      },
      inputs: {
        otp: {
          title: 'Einmaliges Passwort',
        },
      },
      itemCatalogueConfigurationModal: {
        navigateNextTooltip: 'Navigiere zur nächsten Leistung',
        navigatePreviousTooltip: 'Navigiere zur vorherigen Leistung',
      },
      navigateOnUnsavedChangesModal: {
        cancel: 'Abbrechen',
        content: 'Achtung: Deine Änderungen sind noch NICHT gespeichert',
        contentWithFormErrors:
          'Achtung: Du hast Fehler in deinen Eingaben. Bitte überprüfe alle Felder auf ihre Richtigkeit',
        proceed: 'Speichern und navigieren',
        title: 'Ungespeicherte Änderungen',
      },
      navigationButtons: {
        done: 'Fertig',
        import: 'Import',
        next: 'Weiter',
        previous: 'Zurück',
      },
      next: 'Weiter',
      no: 'Nein',
      off: 'Aus',
      ok: 'Ok',
      on: 'An',
      quotes: [
        {
          quote: 'Gut zuhören können ist der halbe Erfolg',
        },
        {
          quote: 'Zeige deinen Mandanten, wie sehr du dich um sie kümmerst',
        },
        {
          quote: 'Persönlicher Service gewinnt das Spiel',
        },
        {
          author: 'Angelehnt an Mahatma Gandhi',
          quote: 'Ein Mandant ist der wichtigste Besucher in unserem Hause',
        },
        {
          author: 'Chip Bell',
          quote: 'Loyale Mandanten bestehen darauf, dass auch ihre Freunde mit dir Geschäfte machen',
        },
        {
          quote: 'Wir hören gut zu und liefern echten Mehrwert',
        },
        {
          quote: 'Tue Gutes und rede darüber',
        },
      ],
      roles: {
        ADMIN: 'Administrator',
        ROOT_ADMIN: 'Inhaber',
        SUPER_ADMIN: 'Super Administrator',
        USER: 'Nutzer (darf eigene Projektkarten anlegen)',
        VIEWER: 'Nutzer (nur Lesezugriff)',
      },
      saved: 'gespeichert',
      saving: 'Änderung wird gespeichert....',
      showDigits: {
        always: 'immer anzeigen (z.B. 10,00 €)',
        avoid: 'nur wenn notwendig (z.B. 10 € or 8,99 €)',
      },
      stateInvitation: {
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv (hat Kanzlei verlassen)',
        INVITATION_SEND: 'Einladung gesendet',
      },
      statusTypes: {
        shoppingCart: 'Projektkarte',
      },
      success: 'Aktualisierung erfolgreich durchgeführt!',
      successToastText: '$t(common.success)',
      tenant: {
        noAllowedToCreateCompany: 'Du kannst keine weiteren Mandanten anlegen',
      },
      toast: {
        errors: {
          activateInvitation: 'Aktivierung fehlgeschlagen',
          passwordReset: 'Failed to send password reset e-Mail',
          resendInvitation: 'Senden der Einladung fehlgeschlagen',
          sendInvitation: 'Senden der Einladung fehlgeschlagen',
          user: {
            delete: 'Löschen des Nutzers fehlgeschlagen',
          },
        },
        info: {
          updateIntroductionBlock:
            'Einige Personen wurden gelöscht, deshalb werden die Begrüßungsblock Daten automatisch angepasst',
        },
        success: {
          acceptInvitation: 'Einladung wurde erfolgreich akzeptiert',
          activateInvitation: '{{name}} wurde aktiviert',
          changePassword: 'Passwort wurde erfolgreich geändert',
          clipboard: 'Farbcode wurde in die Zwischenablage kopiert',
          passwordReset: 'E-Mail wurde gesendet',
          resendInvitation: 'Einladung wurde gesendet',
          sendChangeEmailVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
          sendChangePasswordVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
          sendInvitation: 'Einladung wurde gesendet',
        },
      },
      unsavedChangesModal: {
        cancel: 'Weiter editieren',
        proceed: 'Ohne Speichern schließen',
        title:
          'Achtung: Es existieren ungespeicherte Änderung. Falls du die Änderungen behalten möchtest, drücke bitte den Button “Weiter editieren”.',
      },
      urls: {
        calendlyUrl:
          'https://calendly.com/kanzleipilot-erfolgsteam/kanzleipilot-fragen-und-antworten-session-15-minuten',
        sendEmailUrl: 'mailto:support@kanzleipilot.de',
      },
      vatType: {
        company: 'Umsatzsteuer',
        personal: 'Mehrwertsteuer',
      },
      yes: 'Ja',
    },
    dateFormats: {
      date: 'dd.MM.yyyy',
      dateMoment: 'DD.MM.YYYY',
      dateTime: "dd.MM.yyyy HH:mm 'Uhr'",
      month: 'MM.yyyy',
    },
    decimalSeparator: ',',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Es existieren Projektkarten oder Einstellungen, die diese Dokumentenvorlage verwenden. Möchtest du sie wirklich löschen? (Wichtig: Bereits generierte PDF-Dateien bleiben natürlich erhalten)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Dokumentenvorlage löschen möchtest?',
    },
    deleteEmailTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Es existieren Einstellungen, die diese E-Mail Vorlage verwenden. Möchtest du sie wirklich löschen?',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die E-Mail Vorlage löschen möchtest?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Projektkarte löschen möchtest?',
    },
    duplicateShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Möchtest du die Projektkarte duplizieren? Der Bezug der aktuellen Projektkarte geht dann verloren?',
    },
    editShoppingCartModal: {
      HelperWidget: {
        howUseButton: 'Anleitung: So nutzt du die historische und aktuelle Konfiguration',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung: So nutzt du die historische und aktuelle Konfiguration',
          videoCaption: 'Erfahre, wie du die historischen und aktuelle Konfiguration nutzt',
          videoUrl: 'https://vimeo.com/715925363',
        },
      },
      explanationGlobal:
        'Du verwendest die aktuelle Konfiguration, die deine Kanzlei-Administratoren unter "Einstellungen > Leistungskatalog konfigurieren" bearbeiten können',
      explanationIndependent:
        'Du verwendest die Konfiguration, die zum Zeitpunkt der Erstellung der Projektkarte aktiv war',
      globalOption: 'Bearbeitung mit aktueller Konfiguration',
      independentOption: 'Bearbeitung mit historischer Konfiguration',
      labelGlobal: 'Aktuelle Konfiguration',
      labelIndependent: 'Historische Konfiguration',
      migrationInfoText:
        'Diese Projektkarte wurde vor der Speicherung von "historischen Konfigurationen" migriert und kann deshalb nicht mit der "historischen Konfiguration" bearbeitet werden. Du musst diese Projektkarte einmal mit der "aktuellen Konfiguration" bearbeitet haben, um die "historische Konfiguration" nutzen zu können. Bei neuen Projektkarten passiert das automatisch.',
      title: 'Welche Konfiguration willst du für die Bearbeitung der Projektkarte verwenden?',
    },
    email: {
      transactionalBadRequest: 'E-Mail konnte nicht gesendet werden, bitte überprüfe deine Eingabe.',
      transactionalUnkownError: 'E-Mail konnte nicht gesendet werden, bitte versuche es später erneut.',
    },
    global: {
      outOfService: {
        checkSpamWarning: 'Bitte warten Sie einen kurzen Moment zwischen den Versuchen.',
        infoText:
          'Es wird gleich versucht, die Verbindung wiederherzustellen. Bitte warte oder <0>klicke hier</0>, um die Verbindung manuell zu starten.',
        message: 'Es wird gerade eine außerplanmäßige Wartung durchgeführt.',
        nextTry: 'Nächster Versuch in {{seconds}} Sekunden',
        title: 'Wartungsmodus',
      },
    },
    locale: 'Deutsch',
    localeCode: 'de',
    onboarding: {
      companyInfo: {
        stepTitle: 'Kanzleidaten',
        textAboveVideo: '',
        title: 'Kanzleidaten',
      },
      corporateIdentity: {
        chooseFontsTitle: 'Wähle die passende Schriftart für Überschriften',
        colorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color)',
        colorPreview: 'Vorschau eines Buttons in Ihrer Farbgebung',
        fontFamilyInputLabel: 'Wähle die passende Schriftart für Textblöcke',
        pickCompanyColors: 'Wähle die Farben',
        preview: 'Vorschau',
        primaryColorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor)',
        stepTitle: 'Corporate Identity',
        textAboveVideo: '',
        textFontPreview:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
        title: 'Wie sieht die Kanzlei aus?',
        titleFontFamilyInputLabel: 'Wähle die passende Schriftart',
        titleFontPreview: 'Dies ist eine Überschrift',
        uploadLogoAlert:
          'Wir empfehlen dir, dein Logo im PNG-Format hochzuladen. PNG unterstützt auch transparente Hintergründe und so kann sich dein Logo bestens in die interaktive Projektkarte integrieren. Alternativ kannst du auch das JPG Format verwenden.',
        uploadLogoTitle: 'Corporate Identity: Logo und Schriftart',
        videoCaption: 'Erfahre, wie du deine Corporate Identity einstellst',
        videoUrl: 'https://vimeo.com/541265574',
      },
      finish: {
        buttonsTitle: 'Tipp: Schaue dir das Video an und erfahre, welche nächsten Schritte wir die vorschlagen.',
        calendly: 'Onboarding-Termin buchen (Calendly)',
        calendlyUrl: '$t(common.urls.calendlyUrl)',
        catalogueConfigurationButton: 'Leistungskatalog anpassen',
        manageUsersButton: 'Team-Mitglieder einladen',
        shoppingCartButton: 'Neue Projektkarte anlegen',
        stepTitle: 'Einrichtung beenden',
        successfulMessage: 'Herzlichen Glückwunsch! Du hast die Einrichtung abgeschlossen',
        textAboveVideo: '',
        title: 'Einrichtung abgeschlossen',
        videoCaption: 'Henning Zacher empfiehlt dir die nächsten Schritte',
        videoUrl: 'https://vimeo.com/541268939',
      },
      navigationButtons: {
        done: '$t(common.navigationButtons.done)',
        next: '$t(common.navigationButtons.next)',
        previous: '$t(common.navigationButtons.previous)',
      },
      pickCategories: {
        stepTitle: 'Leistungskatalog',
        textAboveVideo: '',
        title: 'Auswahl der Bereiche für den Leistungskatalog',
        videoCaption: 'Erfahre, wie du den vorgefertigten Leistungskatalog importierst',
        videoUrl: 'https://vimeo.com/541268539',
      },
      pickDocuments: {
        infoConfirmationMessage:
          'Diese Dokumente wurde von Rechtsanwälten geprüft. Dennoch übernehmen wir für die Dokumente KEINE rechtliche Gewähr und machen KEINE Rechtsberatung. Die Dokumente sind nur als Vorlage gedacht. Bitte bestätige dies kurz.',
        infoMessage:
          'Wichtig: Diese Dokumente wurde von Rechtsanwälten geprüft. Dennoch übernehmen wir für die Dokumente KEINE rechtliche Gewähr und machen KEINE Rechtsberatung. Die Dokumente sind als Vorlage gedacht.',
        stepTitle: 'Dokumentenvorlagen',
        textAboveVideo: '',
        title: 'Auswahl der Dokumentenvorlagen',
        videoCaption: 'Erhalte einen Überblick über die Dokumentenvorlagen',
        videoUrl: 'https://vimeo.com/541268685',
      },
      pricingLevel: {
        hourlyRatesTable: {
          accountant: 'Steuerberater',
          employee: 'Mitarbeiter',
          jobType: 'Position',
          pricingFactor: 'Stundensatz',
          technicalEmployee: 'Mitarbeiter IT-Support',
          title: 'Wie sind die Stundensätze?',
        },
        otherSettingsTable: {
          tenantSoftware: 'Mandantenportal Name',
          title: 'Sonstiges',
          value: 'Wert',
          variable: 'Variable',
        },
        pricingTable: {
          category: 'Bereich',
          loans: 'Lohn',
          monthlyBookkeeping: 'Finanzbuchhaltung',
          pricingFactor: 'Zehntelsätze',
          title: '',
          yearlyBusinessAccountsBalanceSheetBEU: 'Jahresabschluss (Bilanz BEU)',
          yearlyBusinessAccountsBalanceSheetRest: 'Jahresabschluss (Bilanz BGMBH, BGMCOKG, BPG)',
          yearlyBusinessAccountsCompanies: 'Jahresabschluss (EÜR - Personengesellschaft)',
          yearlyBusinessAccountsSingle: 'Jahresabschluss (EÜR - Einzelunternehmer)',
          yearlyPersonalAccounts: 'Einkommensteuer',
        },
        stepTitle: 'Honorarlevel',
        textAboveVideo: '',
        title: 'Wie ist dein Honorarlevel?',
        videoCaption: 'Erfahre, wie du das Honorarlevel setzt',
        videoUrl: 'https://vimeo.com/541268288',
      },
      pricingMode: {
        fixedMonthlyModeDescription:
          'Einmalige Leistungen werden transparent bepreist. Monatliche und jährliche Leistungen werden in einer monatlichen Gebühr zusammengefasst. Schafft Planungssicherheit für Ihren Mandanten.',
        fixedMonthlyModeTitle: 'Unternehmer­honorar-Modus',
        standardModeDescription:
          'Alle Leistungen werden transparent bepreist. 80 % der Steuerberater arbeiten im Standard Modus.',
        standardModeTitle: 'Standard-Modus',
        stepTitle: 'Honorarmodus',
        textAboveVideo: '',
        title: 'In welchem Honorarmodus möchtest du arbeiten?',
        videoCaption: 'Erfahre, wie sich die Honorarmodi unterscheiden',
        videoUrl: 'https://vimeo.com/541268439',
      },
      signatures: {
        addButton: 'neue unterschriftsberechtigte Person hinzufügen',
        per: ' muss mindestens eine Person sein',
        stepTitle: 'Unterschriften',
        submitError: 'Bitte füge mindestens eine unterschriftsberechtigte Person hinzu',
        textAboveVideo:
          'In einem typischen Angebot, welches du mit KanzleiPilot generierst, gibt es auch einen Begrüßungsblock. Hier erscheint unten der Name des Kanzleiinhaber / Partner. Lege hier bitte mindestens einen Steuerberater an und füge optional seine Unterschrift (als PNG- / JPEG-Datei) hinzu.',
        title: 'Unterschriften für Begrüßungsblatt',
        videoCaption: 'Erfahre, wo die Unterschriftsbilder erscheinen',
        videoUrl: 'https://vimeo.com/541268813',
      },
      welcome: {
        stepTitle: 'Willkommen',
        textAboveVideo:
          'Hallo {{userFirstName}}, lass uns nun KanzleiPilot einrichten! Es geht ganz schnell in nur 5 Minuten.',
        title: 'Willkommen bei KanzleiPilot',
        videoCaption: 'Henning Zacher heißt dich bei KanzleiPilot willkommen',
        videoUrl: 'https://vimeo.com/541261218',
      },
    },
    sharedPackage: {
      actualCostCalculation: {
        include: 'Beinhaltet',
        plus: 'zzgl.',
      },
      code: '{{paymentInterval}} {{discountedValue}}$t(sharedPackage.minMaxPrice.drawer)$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice:
        '{{paymentInterval}} {{customPrice}}$t(sharedPackage.minMaxPrice.drawer)$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_discount:
        '{{paymentInterval}} {{discountedCustomPrice}}{{newlineOrWhitespace}}<i>(reduziert um {{discount}}, anstelle von <del>{{customPrice}}</del>)</i>$t(sharedPackage.minMaxPrice.drawer)$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice:
        '{{paymentInterval}} {{customPrice}} <i>(individuelles Honorar, anstelle von Standardhonorar <del>{{discountedValue}}</del>)<i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice_discount:
        '{{paymentInterval}} {{discountedCustomPrice}} (reduziert um {{discount}}. <i> Individuelles Honorar vor Nachlass <del>{{customPrice}}</del>, anstelle vom Standardhonorar <del>{{priceBeforeDiscount}}</del>)<i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount:
        '{{paymentInterval}} {{discountedValue}}{{newlineOrWhitespace}}<i>(reduziert um {{discount}}, anstelle von <del>{{priceBeforeDiscount}}</del></i>)$t(sharedPackage.minMaxPrice.drawer)$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount_noDiscountValue:
        '$t(sharedPackage.code)<i>(reduziert, anstelle von <s>{{priceBeforeDiscount}}</s>)<i>',
      code_on_actual_cost: '{{paymentInterval}} nach Zeitaufwand$t(sharedPackage.minMaxPrice.drawer)',
      empty_pricing_formula: 'Bitte fülle die Honorarformel aus',
      fixedMonthlyFeeHint:
        '{{paymentInterval}}, Leistung ist im Unternehmerhonorar beinhaltet$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      fixedMonthlyFeeHint_discount:
        '{{paymentInterval}}, Leistung ist im Unternehmerhonorar beinhaltet$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      free_service:
        'Gratis Leistung{{separator}} {{paymentInterval}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      highlightBindingness: {
        dynamic: '<br/><i>$t(common.Item.highlightBindingness.{{highlightBindingness}})</i>',
        estimated: '$t(sharedPackage.highlightBindingness.dynamic)',
        fixPriced: '$t(sharedPackage.highlightBindingness.dynamic)',
        noHighlight: '',
      },
      minMaxPrice: {
        drawer: '$t(sharedPackage.minMaxPrice.min.{{showMin}})$t(sharedPackage.minMaxPrice.max.{{showMax}})',
        max: {
          hide: '',
          show: '<br/><i>Höchsthonorar: {{maxPrice}}</i>',
        },
        min: {
          hide: '',
          show: '<br/><i>Mindesthonorar: {{minPrice}}</i>',
        },
      },
      minPriceOnActualCost: '<i>mindestens {{minPrice}}</i>',
      minPriceOnActualCostAdvanced: '<i>Mindesthonorar: {{minPrice}}</i>',
      numberOfActualCost_one:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} Leistung nach Zeitaufwand',
      numberOfActualCost_other:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} Leistungen nach Zeitaufwand',
      numberOfHighlightBindingness_one:
        '{{count}} Leistung mit $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) Wert {{value}}',
      numberOfHighlightBindingness_other:
        '{{count}} Leistungen mit $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) Werten {{value}}',
      variable_is_empty: 'Bitte trage einen Wert für {{variable}} ein',
    },
    signing: {
      common: {
        backButton: 'Zurück',
        continueButton: 'Weiter',
        headings: {
          BOOKMARKED_ITEMS: 'Strategische Empfehlungen auswählen',
          IDENTITY: 'Identität bestätigen',
          MONTHLY_PAYMENT: '',
          SEPA: 'SEPA-Mandat erteilen',
          SIGNING: 'Sichten und Signieren Sie Ihre Dokumente',
          VIEW_SHOPPING_CART: 'Leistungen überprüfen',
          ready: 'Fertig',
        },
        steps: {
          BOOKMARKED_ITEMS: 'Empfehlungen',
          IDENTITY: 'Identität bestätigen',
          MONTHLY_PAYMENT: 'Monatliche Zahlung',
          SEPA: 'SEPA-Mandat erteilen',
          SIGNING: 'Signieren',
          VIEW_SHOPPING_CART: 'Leistungen überprüfen',
          ready: 'Fertig',
        },
      },
      identityStep: {
        errors: {
          noOptionSelected: 'Sie müssen bestätigen, dass Sie die genannte Person sind oder Nein wählen.',
        },
        heading: 'Identität bestätigen',
        lockModal: {
          button: 'Neu laden',
          message:
            'Ein anderer Unterzeichner ({{lockedBy}}) hat den Signaturvorgang bereits gestartet. Da es strategische Empfehlungen gibt, muss dieser Unterzeichner den Vorgang zunächst abschließen. Nach Abschluss durch den ersten Unterzeichner wird der Signaturvorgang wieder freigegeben.',
          title: 'Der Signaturvorgang ist zur Zeit blockiert',
        },
        options: {
          company: {
            no: '<b>Nein</b>, ich bin nicht {{name}} oder repräsentiere nicht {{company}}.',
            yes: '<b>Ja</b>, ich bin {{name}} und repräsentiere {{company}}.',
          },
          private: {
            no: '<b>Nein</b>, ich bin nicht {{name}}.',
            yes: '<b>Ja</b>, ich bin {{name}}.',
          },
        },
        question: {
          company: 'Sind Sie {{name}} und repräsentieren Sie {{company}}?',
          private: 'Sind Sie {{name}}?',
        },
        warning: {
          description:
            'Diese und folgende Seiten sind nur für eine bestimmte Person gedacht. Bitte bestätigen Sie, dass Sie {{name}} sind und {{company}} repräsentieren. Sollten Sie nicht diese Person sein, so wählen Sie die Option “Nein” aus.',
          message: 'Wichtiger Hinweis:',
        },
      },
      infoPages: {
        alreadySigned: {
          heading: 'Sie haben die Dokumente schon signiert',
        },
        signingNotValid: {
          heading: 'Dieser Link ist nicht gültig',
        },
        startPage: {
          errors: {
            passwordWrong: 'Das eingegebene Passwort ist falsch!',
          },
          heading: 'Sichten und Signieren Sie Ihre Dokumente',
          password: {
            label: 'Passwort',
            placeholderText: 'Passwort',
          },
          passwordAlert: {
            description:
              'Die folgenden Seiten sind nur durch die Eingabe eines Passwortes erreichbar. Das Passwort bekommen Sie über E-Mail oder über einen anderen Weg von Ihrer Kanzlei. Falls Sie noch kein Passwort erhalten haben, kontaktieren Sie Ihrere Kanzlei.',
            message: 'Wichtiger Hinweis',
          },
          startButton: 'Jetzt starten',
        },
        successfullySigned: {
          heading: {
            client:
              'Vielen Dank. Sie haben den Signiervorgang für Sie abgeschlossen. Sobald alle Signierer signiert haben, bekommen Sie eine E-Mail mit allen signierten Dokumenten. Sie können nun den Tab schließen.',
            tenant:
              'Vielen Dank. Du hast deinen Signiervorgang abgeschlossen. Sobald alle Signierer signiert haben, bekommst du eine E-Mail mit allen signierten Dokumenten. Du kannst nun den Tab schließen.',
          },
        },
        waitForDocuments: {
          heading: 'Dokumente werden vorbereitet<br/>Bitte warten',
        },
        waitForDocumentsFailed: {
          heading:
            'Bei der Vorbereitung Ihrer Dokumente ist etwas schief gelaufen. Bitte melden Sie sich bei Ihrer Kanzlei: {{officeEmail}}.',
        },
        waitForDocumentsTimeout: {
          heading:
            'Die Vorbereitung der Dokumente dauert noch etwas. Versuchen Sie es in einer Stunde nochmal über den Link: <a>{{url}}</a>. Falls die Dokumente immmernoch nicht vorhanden sind, melden Sie sich bei Ihrer Kanzlei: {{officeEmail}}',
        },
        wrongIdentityForSigning: {
          heading:
            'Vielen Dank für Ihr Feedback<br/>Falls Sie diesen Link per E-Mail erhalten haben, löschen Sie bitte diese Nachricht. Sie ist nicht für Sie bestimmt.',
        },
      },
      monthlyPaymentDecisionStep: {
        defaultHeadline: 'Konfiguriere Monatliche Zahlung',
        errors: {
          noOptionSelected: 'Sie müssen auswählen, ob Sie jährliche Leistungen monatlich zahlen möchten oder nicht.',
        },
        options: {
          no: 'Nein, ich möchte monatliche und jährliche Leistungen separat zahlen.',
          yes: 'Ja, ich möchte jährliche Leistungen monatlich in kleineren Beträgen zahlen.',
        },
        warning: {
          description:
            'Bitte wählen Sie hier, ob Sie das Honorar für jährliche Leistungen auf die Monate verteilen möchten.',
          monthlyPaymentStepDone:
            'Ein vorheriger Signierer hat bereits eine Auswahl getroffen. Bitte überprüfen Sie die getroffene Auswahl. Wenn Sie Anpassungen oder Änderungen vornehmen möchten, stimmen Sie diese gerne mit uns ab. Klicken Sie anschließend auf "Weiter".',
        },
      },
      orderBookmarkedItemsStep: {
        benefitsHeadline: 'Ihr Nutzen',
        options: {
          yes: '<0>Ja</0>, ich möchte {{serviceName}} dazu buchen.',
        },
        price: 'Ihre Investition',
        warning: {
          description:
            'Diese optionalen Leistungen bieten Ihnen aus strategischer Sicht zusätzlichen Mehrwert. Schauen Sie sie sich kurz an und wählen Sie die gewünschten Leistungen aus. Drücken Sie anschließend auf „Weiter“.',
          orderBookmarkedItemsStepDone:
            'Ein vorheriger Signierer hat bereits eine Auswahl getroffen. Bitte überprüfen Sie die getroffene Auswahl. Wenn Sie Anpassungen oder Änderungen vornehmen möchten, stimmen Sie diese gerne mit uns ab. Klicken Sie anschließend auf "Weiter".',
          tenantAlert:
            'Als Signierer der Kanzlei siehst du diesen Schritt, kannst jedoch <strong>keine</strong> strategischen Empfehlungen auswählen. Die Signierer der Mandanten haben hier die Möglichkeit zur Auswahl. Bitte klicke unten auf „Weiter“.',
          tenantPopover:
            'Du kannst diese Leistung nicht auswählen, da du Signierer der Kanzlei bist. Die Signierer des Mandanten können hier zusätzliche strategische Empfehlungen beauftragen.',
        },
      },
      sepaMandateStep: {
        accountOwnerName: {
          errors: {
            emptyInput: 'Bitte geben Sie den Namen des Kontoinhabers an',
            wrongFormat: 'Bitte geben Sie Vor- und Nachname des Kontoinhabers an. (Bsp.: Max Mustermann)',
          },
          label: 'Bitte geben Sie den Namen des Kontoinhabers des verwendeten Bankkontos an:',
          placeholder: 'Kontoinhaber',
        },
        bankData: {
          label: 'Bank',
        },
        bic: {
          errors: {
            emptyInput:
              'Bitte geben Sie die BIC an. Falls Sie nur eine Bankleitzahl (BLZ) haben, können Sie diese in eine BIC umwandeln lassen.',
            wrongFormat:
              'Die eingegebene BIC hat nicht das richtige Format. Die BIC besteht entweder aus 8 oder 11 Zeichen.',
          },
          label: 'Bitte geben Sie die BIC des verwendeten Bankkontos an:',
          placeholder: 'BIC',
        },
        grantSepa: {
          errors: {
            mandatory: 'Ein SEPA-Mandate ist verpflichtend.',
          },
          no: 'Nein, ich möchte der Kanzlei {{tenantName}} kein SEPA-Mandat für diesen Vertrag erteilen.',
          yes: 'Ja, ich möchte der Kanzlei {{tenantName}} ein SEPA-Mandat für diesen Vertrag erteilen.',
        },
        iban: {
          errors: {
            emptyInput: 'Bitte geben Sie die IBAN an',
            wrongFormat:
              'Die eingegebene IBAN hat nicht das richtige Format. Die IBAN muss mit einen zweitstelligen Ländercode (z.B.: ‘DE’) starten. Die IBAN muss mindestens 13 und darf maximal 34 Zeichen haben.',
          },
          label: 'Bitte geben Sie die IBAN des verwendeten Bankkontos an:',
          placeholder: 'IBAN',
        },
        stepAlreadyDone:
          'Ein vorheriger Signierer hat bereits eine Auswahl getroffen. Bitte überprüfen Sie die getroffene Auswahl. Wenn Sie Anpassungen oder Änderungen vornehmen möchten, stimmen Sie diese gerne mit uns ab. Klicken Sie anschließend auf "Weiter".',
        stepNotDoneYet:
          'Als Signierer der Kanzlei kannst du diesen Schritt einsehen, jedoch keine Daten eintragen. Der Signierer des Mandanten trägt hier die SEPA-Daten ein. Der Mandant hat bisher noch keine Auswahl getroffen.',
      },
      serviceOverviewStep: {
        fixedFeeInfo: 'Leistung ist im Unternehmerhonorar beinhaltet, {{paymentIntervalText}}',
        fixedMonthly: 'Monatliches Unternehmer<wbr/>honorar',
        introtext: {
          text: {
            client: 'Bitte sichten Sie nun die Leistungen und klicken unten auf "Weiter".',
          },
        },
        modal: {
          fixedFeeInfo: 'Leistung ist im Unternehmerhonorar beinhaltet',
          priceHeading: 'Preis:',
        },
        onActualCostsBadge: 'nach Zeitaufwand',
        onActualCostsTooltip: 'Diese Leistung wird nach Zeitaufwand abgerechnet. Das Honorar ist also variabel.',
        priceCard: {
          discounts: {
            discountItem: '{{discount}} im Bereich {{name}}',
            message: 'Aktive Nachlässe',
          },
          heading: 'Investition',
          pricingTable: {
            discountedInformation: ' <i>(reduziert, anstelle von <s>{{priceWithoutDiscount}}</s>)</i>',
            interval: 'Intervall',
            price: 'Honorar',
            vatInformation: 'enthält {{vatAbsoluteValue}} $t(common.vatType.{{vatType}}) ({{vatValue}}%)',
          },
          vatInfo: {
            noVat: 'Honorare sind netto zzgl. {{vatValue}}% $t(common.vatType.{{vatType}}).',
            vatIncluded: 'Honorare sind brutto, enthalten also {{vatValue}}% $t(common.vatType.{{vatType}}).',
          },
        },
        service: {
          customPriceInformation:
            '(individuelles Honorar, anstelle von Standardhonorar <s>{{valueWithoutDiscount}}</s>)',
          discountInformation: '(reduziert um {{discountValueWithUnit}}, anstelle von <s>{{valueWithoutDiscount}}</s>)',
          discountedCustomPriceInformation:
            '(reduziert um {{discountValueWithUnit}}; individuelles Honorar vor Nachlass <s>{{customPriceWithoutDiscount}}</s>, anstelle vom Standardhonorar <s>{{calculatedPrice}}</s>)',
          noteForClient: 'Hinweis: ',
        },
      },
      viewDocumentsAndSignStep: {
        agreementsForm: {
          checkDocumentsSeen: 'Ja, ich habe alle Dokumente gelesen und verstanden',
          checkNoticeOfCancellation:
            'Ja, ich bestätige, dass ich die Widerrufsbelehrung gelesen habe und sie akzeptiere.',
          errorNotSelectedYet:
            'Sie haben noch nicht allen Bedingungen zugestimmt. Sie müssen erst den Bedingungen zustimmen, bevor Sie die Dokumente signieren können.',
          pleaseAgree: {
            client: 'Bitte bestätigen Sie, dass Sie die Dokumente gelesen haben und damit einverstanden sind.',
            tenant: 'Bitte bestätige, dass du die Dokumente gelesen hast und damit einverstanden bist.',
          },
          stepHeadline: '2: Bestätigen Sie, dass Sie die Dokumente gelesen haben',
        },
        documentsCard: {
          downloadButton: 'Herunterladen',
          showButton: 'Anzeigen',
          stepHeadline: '1: Sichten Sie die Dokumente',
          title: 'Dokumente',
        },
        introtext: {
          headline: 'Bitte machen Sie nun Folgendes:',
          text: '1. Sichten Sie alle Dokumente (indem Sie auf "Anzeigen" klicken)<br/>2. Bestätigen Sie, dass Sie alle Dokumente gelesen haben<br/>3. Bitte signieren Sie die Dokumente, indem Sie unten auf das Signaturfeld klicken',
        },
        signeesCard: {
          notSignedYet: 'noch nicht signiert',
          signed: 'signiert am {{date}}',
          title: 'Signatur Status',
        },
        signingButton: {
          label: 'Klicke hier, um alle Dokumente zu signieren',
          notSignedYet:
            'Sie haben die Dokumente noch nicht signiert. Sie müssen zunächst die Dokumente signieren, bevor Sie fortfahren können.',
          signing: 'Signiere die Dokumente...',
          stepHeadline: '3: Signieren Sie alle Dokumente mit einem Klick',
        },
      },
    },
    superAdmin: {
      CommonLibraryAccessGroups: {
        all: 'ALLE',
      },
      ManageAllUsers: {
        title: 'Alle Nutzer verwalten',
      },
      ManageDigitalSignature: {
        defaultTemplatesCard: {
          infoAlert: {
            description:
              'Hier können Standardvorlagen für die Vorlageneinstellungen der digitalen Signatur gesetzt werden. Diese ausgewählten Vorlagen können dann durch die Kanzleien durch Knopfdruck importiert und als Auswahl für deren digitalen Signatur Einstellungen gesetzt werden. Falls eine Auswahl hier leer bleibt, wird für diese Einstellung keine Vorlage importiert und beim Zurücksetzen der Einstellungen durch die Kanzlei wird in der Kanzleiinstanz diese Einstellung auch als nicht ausgewählt gesetzt.',
            title: 'Hinweis:',
          },
          noticeOfCancellationTemplate: {
            label: 'Standard Dokumentenvorlage für die Widerrufserklärung',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'Dokumentenvorlage aus der Commonlibrary, welche für die Widerrufserklärung importiert werden soll',
          },
          openedBySigneeNotificationTemplate: {
            label:
              'Standard E-Mail Vorlage für die Benachrichtigung der Kanzleimitglieder über das Öffnen eines Signiervorgangs durch einen Signierer',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Benachrichtigungsemail an die Kanzleimitglieder über das Öffnen eines Signiervorgangs durch einen Signierer im selben digitalen Signatur importiert werden soll',
          },
          passwordEmailTemplate: {
            label: 'Standard E-Mail Vorlage für die digitale Signatur Passwortemail',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Passwortemail für die digitale Signatur importiert werden soll',
          },
          remindEmailTemplate: {
            label: 'Standard E-Mail Vorlage für die digitale Signatur Erinnerungsemail',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Erinnerungsemail für die digitale Signatur importiert werden soll',
          },
          revokeEmailTemplate: {
            label: 'Standard E-Mail Vorlage für die digitale Signatur Abbruch Benachrichtigung',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Abbruch Benachrichtigungsemail für die digitale Signatur importiert werden soll',
          },
          sepaMandateTemplate: {
            label: 'Standard Dokumentenvorlage für das SEPA-Lastschriftmandat',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'Dokumentenvorlage aus der gemeinsamen Bibliothek, welche für das SEPA-Lastschriftmandat importiert werden soll',
          },
          signedBySigneeNotificationTemplate: {
            label: 'Standard E-Mail Vorlage für die Benachrichtigung der Signierer über das Signieren eines Signierers',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Benachrichtigungsemail an die Signierer über das Signieren eines Signierers im selben digitalen Signaturprozess importiert werden soll',
          },
          startClientNotificationTemplate: {
            label: 'Standard E-Mail Vorlage für die digitale Signatur Startemail für Mandanten',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Startemail für die digitale Signatur an die Mandanten importiert werden soll',
          },
          startTenantNotificationTemplate: {
            label: 'Standard E-Mail Vorlage für die digitale Signatur Startemail für Kanzleimitglieder',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Startemail für die digitale Signatur an die Kanzleimitglieder importiert werden soll',
          },
          successfulFinishedNotificationTemplate: {
            label: 'Standard E-Mail Vorlage für den erfolgreichen Abschluss für die digitale Signatur',
            placeholder: 'Keine Vorlage ausgewählt',
            tooltip:
              'E-Mail Vorlage aus der Commonlibrary, welche für die Abschlussemail für einen erfolgreichen Abschluss eines digitalen Signaturvorgangs importiert werden soll',
          },
          title: 'Standardvorlagen für digitale Signatur',
        },
        digitalSignatureKillSwitch: {
          switchLabel: 'Aktiviere das Digitale Signatur Feature global',
          title: 'Status der Digitalen Signatur global',
          warningSwitchOff:
            'Wenn du die Digitale Signatur über diesen Schalter ausschaltest, kann keine Kanzlei das Feature verwenden. Alle aktiven Digitalen Signaturvorgänge werden widerrufen. Kein Signier kann seinen Signiervorgang verwenden. Nach den wieder einschalten muss die Funktion bei jeder Kanzlei wieder erneut aktiviert werden. Verwende den Schalter mit Vorsicht.',
          warningSwitchOn:
            'Wenn du die Digitale Signatur über diesen Schalter anschaltest, kann jede Kanzlei diese Funktion bei sich aktivieren. Verwende den Schalter mit Vorsicht.',
        },
        statistics: {
          currentlyOpenDigitalSignatureProcesses: 'Aktuell offene Digitalen Signaturvorgänge',
          currentlyOpenSigningProcesses: 'Aktuell offene Signiervorgänge',
          finishedDigitalSignatureProcesses: 'Anzahl abgeschlossener Digitalen Signaturvorgänge',
          finishedDigitalSignatureProcessesThisMonth:
            'Anzahl abgeschlossener Digitalen Signaturvorgänge in diesem Monat',
          finishedSigningProcesses: 'Anzahl abgeschlossener Signiervorgänge',
          finishedSigningProcessesThisMonth: 'Anzahl abgeschlossener Signiervorgänge in diesem Monat',
          numberOfTenantWithEnabledDigitalSignature: 'Anzahl von Kanzleien mit aktivierter Digitaler Signatur',
          numberOfTotalTenants: 'Gesamtanzahl von Kanzleien',
          openedDigitalSignatureProcesses: 'Anzahl gestarteter Digitaler Signaturvorgänge',
          openedDigitalSignatureProcessesThisMonth: 'Anzahl gestarteter Digitaler Signaturvorgänge in diesem Monat',
          openedSigningProcesses: 'Anzahl gestarteter Signiervorgänge',
          openedSigningProcessesThisMonth: 'Anzahl gestarteter Signiervorgänge in diesem Monat',
          title: 'Statistiken',
        },
        title: 'Verwalte Digitale Signatur global',
      },
      ManageTenants: {
        addTenant: 'Neue Kanzlei hinzufügen',
        addTenantModalTitle: 'Neue Kanzlei hinzufügen',
        columns: {
          licenseStarted: 'License Started',
          locale: 'Locale',
          nrOfAllowedCompaniesByPricingPlan: '$t(common.columns.nrOfAllowedCompaniesByPricingPlan)',
          nrOfAllowedUsersByPricingPlan: '$t(common.columns.nrOfAllowedUsersByPricingPlan)',
          nrOfCatalogueConfigurationEdits: 'Number Of  "Edits" In Catalogue-Configuration',
          nrOfCompanies: 'Number Of Companies',
          nrOfCompanyTypeEdits: 'Number Of  "Edits" In DocumentType-Configuration',
          nrOfCreatedShoppingCarts: 'Number Of Shopping-Carts Created',
          nrOfLogins: 'Number Of Logins',
          nrOfTotalActions: 'Number Of Total Actions',
          nrOfUsers: 'Number Of Users',
          rootAdminEmail: '$t(common.columns.rootAdminEmail)',
          rootAdminName: '$t(common.columns.rootAdminName)',
          status: '$t(common.columns.status)',
          tenantName: '$t(common.columns.tenantName)',
          tenantStatus: 'Tenant Status',
          timeStampOfLastAction: 'Timestamp Of Last Action',
          viewLogbook: 'View Logbook',
        },
        editTenantModalTitle: 'Kanzlei bearbeiten',
        emptyListMessage: 'Bis jetzt keinen Kanzleien-Aktionen vorhanden',
        fields: {
          accessGroups: {
            label: 'Gemeinsame Bibliothek Zugriffsgruppen',
            placeholder: 'Zugriffsgruppen',
            tooltip:
              'Hier kannst du auswählen, auf welche Zugriffsgruppen die Kanzlei Zugriff darauf hat. Wenn du die Gruppe `ALLE` zuweist, hat die Kanzlei auf alle Inhalte der Gemeinsamen Bibliothek Zugriff. Die Kanzlei hat nur Zugriff auf Bereiche und Dokumentenvorlagen, die eine Gruppe enthalten, auf die die Kanzlei Zugriff hat und auf Bereiche und Dokumentenvorlagen, die die Gruppe `ALLE` enthalten.',
          },
          disabled: {
            label: 'Aktiv',
            values: {
              disabled: 'Pausiert',
              enabled: 'Aktiv',
            },
          },
          licenseStarted: {
            label: 'Start der Lizenz',
          },
          locale: {
            label: 'Sprache',
            placeholder: 'Sprache',
            values: {
              de: 'Deutsch',
              en: 'Englisch',
            },
          },
          nrOfAllowedCompaniesByPricingPlan: {
            errorMessage: 'Erlaubte Anzahl von Mandanten ist ein Pflichtfeld',
            label: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.placeholder)',
          },
          nrOfAllowedUsersByPricingPlan: {
            errorMessage: 'Erlaubte Anzahl von Nutzern ist ein Pflichtfeld',
            label: '$t(common.fields.nrOfAllowedUsersByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedUsersByPricingPlan.placeholder)',
          },
          rootAdminEmail: {
            label: 'E-Mail des Inhabers',
            placeholder: 'E-Mail des Inhabers',
          },
          rootAdminFirstName: {
            label: 'Vorname (Inhaber)',
            placeholder: 'Vorname (Inhaber)',
          },
          rootAdminLastName: {
            label: 'Nachname (Inhaber)',
            placeholder: 'Nachname (Inhaber)',
          },
          tenantName: {
            label: 'Kanzleiname',
            placeholder: 'Kanzleiname',
          },
        },
        loadingTenantsActions: "Loading tenant's actions",
        logBookModalTitle: 'Tenant\'s actions of the "{{tenantName}}"',
        stateInvitation: {
          ACTIVE: 'Aktiv',
          INACTIVE: 'Inaktiv',
          INVITATION_SEND: 'Einladung gesendet',
        },
        title: 'Kanzleien verwalten',
      },
    },
    user: {
      CompareToGlobal: {
        changed: {
          drawer: {
            global: 'Global',
            independent: 'Projektkarte',
            label: 'Die festgeschriebene Honorarkonfiguration unterscheidet sich von der globalen',
            notSet: '(nicht gesetzt)',
          },
          label:
            'Die festgeschriebene Honorarkonfiguration unterscheidet sich von der globalen. Klicke auf das Icon, um die Details zu vergleichen',
        },
        deleted: {
          label:
            'Diese Element wurde in der globalen Konfiguration entfernt, ist hier in der festgeschriebenen Konfiguration noch vorhanden',
        },
        new: {
          label: 'Dieses Element wurde zur globalen Konfiguration hinzugefügt',
          staticItems: 'Statische Leistungen, die nur in globaler Konfiguration vorhanden sind',
        },
      },
      MigratedHelperWidget: {
        howUseButton: 'Erfahre, wie du mit migrierten Projektkarten am Besten umgehst',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Erfahre, wie du mit migrierten Projektkarten am Besten umgehst',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/715753396',
        },
      },
      PrintShoppingCartPage: {
        compareTitle:
          'Die globale Dokumentenvorlage wurde in der Zwischenzeit aktualisiert (nach dem {{createdAt}}). Bitte beachte, dass sich die Elemente unterscheiden können.',
        download: 'Herunterladen',
        downloadAll: 'Alle Dokumente herunterladen',
        downloadDocuments: 'Dokumente',
        fields: {
          actions: '$t(common.fields.actions.label)',
          name: '$t(common.fields.name.label)',
        },
        print: 'Drucken',
        signed: 'Signiert',
        stateLoading: {
          download: 'Lade PDF herunter',
          generate: 'Generiere PDF',
          save: 'Speichere PDF',
        },
        successfulMessage: 'Projektkarte wurde gespeichert',
      },
      SendShoppingCartPage: {
        loadingMessage: 'Dokumente werden generiert und E-Mail wird versendet...',
        successfulMessage: 'E-Mail wurde erfolgreich versendet',
      },
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              cancelChangeEmail: '$t(common.cancel)',
              changeEmail: 'Ändere deine E-Mail',
              fields: {
                email: 'E-Mail',
                firstName: 'Vorname',
                lastName: 'Nachname',
                newEmail: 'Neue E-Mail',
                otp: 'Einmaliges Passwort',
                phone: 'Telefon',
                position: 'Position',
              },
              saveEmail: 'E-Mail speichern',
              title: 'Dein Account',
            },
            password: {
              errors: {
                currentPassworWrong: 'Passwort ist falsch',
                noMatch: 'Passwörter müssen übereinstimmmen',
              },
              fields: {
                currentPassword: 'Aktuelles Passwort',
                newPassword: 'Neues Passwort',
                otp: 'Einmaliges Passwort',
                passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
              },
              savePassword: 'Passwort speichern',
              title: 'Passwort',
            },
            variables: {
              title: 'Variablen',
            },
          },
          title: 'Dein Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'Speichern und E-Mail Editor öffnen',
          errors: {
            formHasErrors: 'Es existieren Eingabefehler in dem Formular. Bitte behebe sie',
            noCompanyError: 'Bitte wähle einen Mandanten aus, um diesen Button zu aktivieren',
            noContactsError: 'Bitte wähle einen Kontakt aus, um diesen Button zu aktivieren',
            noItemsSelectedError: 'Bitte wähle mindestens eine Leistung aus, um diese Aktionen zu aktivieren',
            noRecipientsWithEmail:
              'Bitte pflege zunächst für mindestens einen Kontakt des Mandanten eine E-Mail Adresse ein',
          },
          html: 'Vorschau öffnen',
          pdf: 'PDF Vorschau',
          pdfGenerating: 'PDF wird generiert. Bitte warte einen Moment und versuche es nochmal.',
          pdfNotFound:
            'PDF wurde nicht gefunden. Bitte kontaktiere unsere Support, damit wir das Problem beheben können.',
          print: 'Speichern und Dokumente anzeigen',
          printModal: {
            title: 'Die Projektkarte wird jetzt für den Druck gespeichert. Ok?',
          },
          saveAndStartDigitalSignature: 'Speichern und Digitale Signatur starten',
          saveAsDraft: 'Als Entwurf speichern',
          store: 'Speichern und Projektkarte anzeigen',
          submitRevisionModal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            printTitle: 'Möchtest du die Projektkarte speichern und die Dokumente generieren?',
            saveAndStartDigitalSignatureTitle:
              'Möchtest du die Projektkarte speichern und anschließend den digitalen Signaturprozess starten?',
            saveTitle: 'Möchtest du die Projektkarte speichern?',
            sendTitle: 'Möchtest du die Projektkarte speichern und anschließend eine E-Mail versenden?',
          },
        },
        Category: {
          Discount: {
            label: 'Wähle einen Nachlass',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} muss eine Zahl sein',
            requiredMessage: 'Bitte trage eine Werte für {{name}} ein',
          },
          Item: {
            bookmarkTooltip: 'Als Strategische Empfehlung markieren (erscheint als optionale Leistung)',
            customPriceDrawer: {
              CustomPriceHelperWidget: {
                howUseButton: 'Preise anpassen',
                modalInfo: {
                  helpText: '',
                  imageUrl: '',
                  title: 'Preise anpassen',
                  videoCaption: 'Erfahre, wie du die Preise anpasst',
                  videoUrl: 'https://vimeo.com/693703597',
                },
              },
              cancelConfirmation: 'Deine Änderungen gehen verloren. Fortfahren?',
              deleteCustomPriceConfirmation:
                'Möchtest du das individuelle Honorar entfernen und das Standardhonorar verwenden?',
              fields: {
                customPrice: {
                  label: 'Finales individuelles Honorar',
                  tooltip:
                    'Hier kannst du ein individuelles Honorar setzen, welches das Standardhonorar überschreibt. Bitte beachte, dass aktive Nachlässe auch auf das individuelle Honorar angewendet werden.',
                },
                defaultValue: {
                  label: '$t(common.fields.defaultValue.label)',
                },
                internalInputField: {
                  errors: {
                    infinity: 'Division durch 0 durch Werte der internen Eingabefelder. Bitte ändere die Werte',
                    max: '{{name}} muss kleiner oder gleich {{value}} sein',
                    min: '{{name}} muss größer oder gleich {{value}} sein',
                  },
                  label: 'Werte für interne Eingabefelder anpassen',
                  tooltip:
                    'Hier kannst du die Werte der internen Eingabefelder für die individuelle Kalkulation des Mandanten anpassen. Tipp: Nutze auch das Textfeld "Interne Notiz für das Kanzleiteam" um Details festzuhalten.',
                },
                internalNoteToTeam: {
                  label: 'Interne Notiz für das Kanzleiteam',
                  tooltip:
                    'Nutze dieses Textfeld, um deinem Team eine Notiz zu hinterlassen. Was ist bezüglich dieser Leistung besonders bei dem individuellen Mandanten? Der Mandant sieht diese Nachricht NICHT - sie erscheint lediglich in der Ansicht auf die Projektkarte für dein Team.',
                },
                newPrice: {
                  label: 'Individuelles Honorar',
                  tooltip:
                    'Diese Honorar wird anhand der Preisformel und den angegebenen Honorarparametern (inkl. den oben gesetzten Werten für die internen Eingabefelder) ermittelt. Sollte für diese Leistung eine Rundungspräferenz eingestellt sein (global oder auf Leistungsebene), so ist Ergebnis aus der Honorarformular gerundet.',
                },
                officialReasonText: {
                  label: 'Honorarhinweis für Mandant',
                },
                originalPrice: {
                  label: 'Standardhonorar',
                  tooltip:
                    'Das Standardhonorar dieser Leistung wird anhand der Preisformel und den angegebenen Honorarparametern ermittelt. Sollte die Leistung KEIN internes Eingabefeld besitzen und für diese Leistung eine Rundungspräferenz eingestellt sein (global oder auf Leistungsebene), so wird das Ergebnis der Honorarformular gerundet.',
                },
                showCalculatedPrice: {
                  disabledTooltip:
                    'Im Unternehmerhonorar-Modus sind monatlich und jährliche Preise von individuellen Preise nicht sichtbar.',
                  label: 'zeige Standardhonorar in Dokumenten?',
                  tooltip:
                    'Zeige das Standardhonorar neben dem individuellen Honorar in den Dokumenten. Wenn du diese Option deaktivierst, wird das Standardhonorar NICHT angezeigt. Im transparenten Modus werden dann zudem die Kalkulations-Details im Block "Details zu den ausgewählten Dienstleistungen" und "Strategische Empfehlungen" versteckt.',
                },
              },
              removeCustomPrice: 'entfernen',
              title: 'Bearbeite Honorar und Notizen',
            },
            displayInternalNoteToTeam: 'Individuell:',
            displayNotesToEmployee: 'Allgemein:',
            displayOfficialReasonText: 'Hinweis:',
            errors: {
              infinityPrice:
                'In der Honorarformel wurde eine Division durch 0 festgestellt. Bitte überprüfe die Eingabefelder',
            },
            graduatedScaleMode: 'Bitte beachten Sie, dass die Staffeln stufenweise berechnet werden',
            infoTextModal: {
              benefits: '$t(common.fields.benefits.label)',
              collapsePanelTitle: 'Details anzeigen',
              descriptionForContract: '$t(common.fields.descriptionForContract.label)',
              notesForFeeAgreement: '$t(common.fields.notesForFeeAgreement.label)',
              notesToEmployee: '$t(common.fields.notesToEmployee.label)',
              pleaseNote: '$t(common.fields.pleaseNote.label)',
            },
            maxPrice: 'Höchsthonorar: {{maxPrice}}',
            minPrice: 'Mindesthonorar: {{minPrice}}',
            paymentIntervalAndHighlightBindingness: 'Zahlungsintervall: {{paymentIntervalAndHighlightBindingness}}',
            pricingFormula: 'Honorarformel: ',
            referenceError: {
              needsToBeSelected:
                'Diese Leistung muss ausgewählt sein, da andere ausgewählte Leistungen hierauf beruhen.',
              referenceIsSelectedButInvalid:
                'Bitte wähle die Leistung “{{itemName}}“ aus und trage dort korrekte Werte ein. Diese Leistung beruht darauf.',
              referenceNotSelectedAndInvalid:
                'Bitte wähle die Leistung “{{itemName}}“ aus und trage dort die Werte ein. Diese Leistung beruht darauf.',
              referenceNotSelectedButValid:
                'Diese Leistung beruht auf einer weiteren strategische Empfehlung . Wähle die Empfehlung {{itemName}} als ausgewählte Leistung aus oder entferne diese Leistung als strategische Empfehlung.',
            },
            requiredFieldsAlertText: 'Diese Eingabefelder sind Pflichtfelder',
            roundPrice: 'Der in der Honorarformel ermittelte Wert wird in {{step}}er Schritten aufgerundet.',
            selectTooltip: 'Anbieten und in Auftragsumfang aufnehmen',
          },
          StaticItems: 'Show static items',
          Total: {
            label: 'Zeige Honorar des Bereichs',
            vatTypeLabel: 'Weise $t(common.vatType.{{vatType}}) aus',
          },
          ValidationLabels: {
            discount: 'Nachlass',
            startOfService: 'Leistungsbeginn',
          },
          showStaticItems: 'Zeige statischen Leistungskatalog',
          startOfService: 'Leistungsbeginn (ab Monat / Jahr)',
          startOfServiceRequired: 'Leistungsbeginn erforderlich',
        },
        CompanyType: {
          confirmChange:
            'Bist du sicher, dass du den Mandantentyp ändern möchtest? Die bestehende Leistungsauswahl geht verloren. Möchtest du den Mandantentyp wechseln?',
          inputs: {
            companyType: {
              label: 'Welche Rechtsform haben Sie?',
              placeholder: 'Mandantentyp auswählen',
            },
            selfBooker: 'Erstellen Sie Ihre Buchhaltung selber?',
          },
          noTypes: 'Keine Mandantentyp',
          title: 'Mandantentyp auswählen',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Existierenden Mandanten hinzufügen',
            createNewCompanyTab: 'Neuen Mandanten anlegen',
            duplicatedErrorMessage: {
              name: 'Mandant mit diesem Namen existiert bereits',
            },
            empty: 'Mandant hinzufügen',
            inputs: {
              city: {
                label: 'Stadt',
                placeholder: 'Stadt',
              },
              cityCode: {
                label: 'PLZ',
                placeholder: 'PLZ',
              },
              commercialObject: {
                label: 'Unternehmensgegenstand',
                placeholder: 'Unternehmensgegenstand',
              },
              company: {
                label: 'Mandant',
                placeholder: 'Suche nach Mandant (z.B. Unternehmensname)',
              },
              country: {
                label: 'Land',
                placeholder: 'Land',
              },
              houseNumber: {
                label: 'Nr.',
                placeholder: 'Nr.',
              },
              identifier: {
                label: 'Mandatsnummer',
                placeholder: 'Mandatsnummer',
              },
              name: {
                company: {
                  label: 'Firmierung',
                  placeholder: 'Firmierung',
                },
                individual: {
                  label: 'Mandatsname',
                  placeholder: 'Mandatsname',
                },
              },
              street: {
                label: 'Straße',
                placeholder: 'Straße',
              },
              type: {
                company: 'Unternehmen',
                individual: 'Einzelperson',
              },
            },
            noCountries: 'No countries',
            notFoundContent: {
              create: 'Neuen Mandanten anlegen',
              noFound: 'Es existiert noch kein Mandant',
            },
          },
          contact: {
            addExistingContactTab: 'Existierenden Ansprechpartner hinzufügen',
            createNewContactTab: 'Neuen Ansprechpartner anlegen',
            duplicatedContactAlert: {
              editMessage:
                'Hinweis: Es existiert bereits ein Ansprechpartner beim Mandanten mit dieser E-Mail. Du kannst fortfahren, wenn du für den Kontakt andere Daten pflegen möchtest',
              message:
                'Hinweis: Es existiert bereits ein Ansprechpartner beim Mandanten mit dieser E-Mail. Du kannst fortfahren, wenn du für den Kontakt andere Daten pflegen möchtest',
              useExistingContactButton: 'existierenden Anprechpartner verwenden',
            },
            duplicatedErrorMessage: {
              contact: 'Ansprechpartner existiert bereits',
              email: 'Ansprechpartner mit dieser E-Mail existiert bereits',
            },
            empty: 'Ansprechpartner hinzufügen',
            inputs: {
              contact: {
                label: 'Ansprechpartner',
                placeholder: 'Suche Ansprechpartner',
              },
              email: {
                label: 'E-Mail Addresse',
                placeholder: 'E-Mail Addresse',
              },
              firstName: {
                label: 'Vorname',
                placeholder: 'Vorname',
              },
              fullSalutation: {
                label: 'Briefanrede',
                placeholder: 'Briefanrede',
              },
              lastName: {
                label: 'Nachname',
                placeholder: 'Nachname',
              },
              phoneNumber: {
                label: 'Telefon (geschäftlich)',
                placeholder: 'Telefon (geschäftlich)',
              },
              phoneNumberAlternative: {
                label: 'Telefon (alternativ)',
                placeholder: 'Telefon (alternativ)',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Anrede',
                options: {
                  Mr: 'Herr',
                  Mrs: 'Frau',
                  Ms: 'Frau',
                },
                placeholder: 'Herr',
              },
            },
            notFoundContent: {
              create: 'Neuen Ansprechpartner anlegen',
              noFound: 'Es existieren noch keine Ansprechpartner',
            },
            title: 'Ansprechpartner beim Mandanten',
          },
          fetchFromGlobal: 'Daten aktualisieren',
          label: 'Mandant auswählen',
          modal: {
            cancel: '$t(common.cancel)',
            doNotReplace: '$t(common.no), nicht ersetzen',
            ok: '$t(common.ok)',
            replace: '$t(common.yes), Standard Ansprechpartner laden',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              changeCompanyContacts:
                'Möchtest du die Ansprechpartner, die sich aktuell in der Projektkarte befinden, durch die Standard-Ansprechpartner des Mandanten ersetzten?',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
          oldData:
            'Die Daten sind nicht aktuell. Um die Mandantendaten oder die Daten von Ansprechpartnern zu bearbeiten, rufe die aktualisierten Daten mit einen Klick auf "Daten aktualisieren" ab',
          prefillContact: {
            button: 'Fülle Kontaktdaten vor',
            columns: {
              fieldTitle: '$t(common.columns.field)',
              fields: {
                commercialObject: 'Unternehmensgegenstand',
                importAcademicTitle: 'Akademischer Grad',
                importCompanyType: 'Unternehmensform (Import)',
                importEmail: 'E-Mail (Import)',
                importSalutation: 'Anrede',
              },
              valueTitle: '$t(common.columns.value)',
            },
            contactCollapse: 'Zeige Daten vom Stammdaten-Import an',
          },
        },
        DigitalSignature: {
          infoAlert:
            'Hinweis: Die Signierer des Kanzleiteams werden mit der Benachrichtigungsemail für Kanzleimitglieder informiert. Du bearbeitest hier nur die E-Mail an die Signierer des Mandanten.',
          noLinkButtonPresent:
            'Es ist kein Button zum Starten des Signierprozesses präsent. Benutze den Button aus “Platzhalter einfügen“ → “Digitale Signatur“ → “Button für Signierprozess“ im Inhaltsfeld der E-Mail um die E-Mail zu komplettieren.',
          onCloseModal: {
            cancelButtonLabel: 'Abbrechen und digitale Signatur nicht starten',
            message:
              'ACHTUNG: Wenn du dieses Fenster schließt, wird die Digitale Signatur noch nicht gestartet und die Änderungen an der E-Mail gehen verloren. Du kannst die Digitale Signatur nachträglich in der Projektkartenansicht starten.',
            okButtonLabel: 'Zurück',
          },
          title: 'Benachrichtige die Signierer des Mandanten über den Start des Digitalen Signiervorgangs',
        },
        Discounts: {
          activeDiscountsLabel: 'Liste der aktiven Nachlässe',
          activeDiscountsListLabel: 'Folgende Nachlässe sind aktiv:',
          activeForCategory:
            '"{{discountName}}" im Bereich "{{categoryName}}" ({{discountValue}} {{discountUnitName}} Nachlass)',
        },
        DocumentTypes: {
          activeTooltip: 'Du hast den {{blockName}}-Block individualisiert. Klicke hier um ihn zu bearbeiten',
          blocks: {
            body: 'Personalized text',
          },
          customPersonalizedText: '{{blockName}}: individualisierter Text',
          inactiveTooltip: 'Klicke hier, um den {{blockName}}-Block zu individualisieren',
          label: 'Dokumente auswählen',
          sections: {
            global: 'Aktuelle Konfiguration',
            independent: 'Historische Konfiguration',
          },
          typesSelectLabel: 'Welche Dokumente sollen generiert werden?',
        },
        DocumentTypesTable: {
          actionsHeader: '',
          nameHeader: 'Dokumentenvorlage',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Neue Projektkarte anlegen',
            videoCaption: 'Erfahre, wie du die interaktive Projektkarte am Besten bedienst',
            videoUrl: 'https://vimeo.com/541269240',
          },
        },
        NextActions: {
          buttons: {
            backToOffer: 'Projektkarte bearbeiten',
            goToDashBoard: 'Zum Cockpit',
            goToView: 'Projektkarte betrachten',
          },
          title: 'Nächste Schritte',
        },
        ProjectInformation: {
          projectLabel: 'Projekt-Informationen eintragen',
        },
        SendEmail: {
          attachments: 'E-Mail Anhänge',
          attachmentsSwitch: 'Dokumente als E-Mail Anhang mitsenden',
          bcc: 'BCC',
          changeEmailTemplatesConfirmation: 'Sicher, dass du die E-Mail Vorlage laden möchtest?',
          fields: {
            body: {
              label: 'Inhalt',
            },
            emailTemplates: {
              label: 'E-Mail Vorlage',
            },
            recipients: {
              label: 'Empfänger',
            },
            signature: {
              label: 'Signatur',
            },
            subject: {
              label: 'Betreff',
            },
          },
          off: 'AUS',
          ok: 'E-Mail senden',
          on: 'AN',
          preview: 'E-Mail Vorschau',
          success: 'E-Mail erfolgreich versendet',
          successfulMessage: 'Dokument wurde gespeichert',
          title: 'E-Mail senden',
          unablePlaceholdersInBody: 'Die Platzhalter {{placeholders}} werden beim Speichern der Projektkarte gefüllt.',
        },
        SignatureCard: {
          companySigneesSelect: {
            error: 'Du musst mindestens eine Kontaktperson des Mandanten auswählen',
            label: 'Wer soll für den Mandanten signieren?',
          },
          digitalSignatureSwitch: {
            label: 'Nutze digitale Signatur für diese Projektkarte',
            off: 'AUS',
            on: 'AN',
          },
          errorMessage:
            'Eine oder mehrere Kontaktpersonen des Mandanten haben keine E-Mail-Adresse hinterlegt, obwohl sie als Signierer ausgewählt sind. Bitte füge für diese eine E-Mail-Adresse ein oder wähle sie nicht als Signierer aus.',
          label: {
            withDigitalSignature: 'Digitale Signatur konfigurieren',
            withoutDigitalSignature: 'Signierer',
          },
          monthlyPaymentActiveInfoMessage:
            'Du hast die monatliche Zahlung mit der optionalen Auswahl durch den Mandanten ausgewählt. Diese wird momentan im Signierprozess noch NICHT unterstützt. Diese Funktion kommt in der Zukunft.',
          sepaCustomSettings: {
            label: 'SEPA Standardeinstellungen individualisieren',
            tooltip:
              'Unter “Einstellungen > Menükarte > digitale Signatur > SEPA“ hast du deine bevorzugten Standard-Einstellungen gesetzt. An dieser Stelle kannst du sie individuell für den Mandanten anpassen',
          },
          tenantSigneesSelect: {
            error: 'Du musst mindestens einen Mitarbeiter der Kanzlei auswählen',
            label: 'Wer soll für die Kanzlei signieren?',
          },
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              VAT: '$t(common.vatType.{{vatType}}) ({{vatValue}} %)',
              interval: 'Intervall',
              notes: 'Notes',
              price: 'Honorar (netto)',
              priceWithVAT: 'Honorar (brutto)',
            },
          },
          error: 'Bitte behebe die Fehler im Formular, um das Honorar zu sehen',
          fixedMonthlyFeeLine_monthly:
            '{{count}} monatliche Leistungen ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_monthly_plural:
            '{{count}} monatliche Leistungen sind ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_yearly: '{{count}} jährliche Leistungen ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_yearly_plural:
            '{{count}} jährliche Leistungen sind ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeTypeHint: 'Kosten sind im Unternehmerhonorar beinhaltet',
          label: 'Investition',
        },
        buttonLabel: 'Optionen',
        categoriesCardTitle: 'Wunsch-Leistungen auswählen',
        compareOptions: {
          global: 'Vergleiche mit globaler Konfiguration',
          previous: '',
        },
        emptyCategoryListMessage: 'Es existieren bisher KEINE Bereiche',
        errorList: {
          scroll: 'Scroll zur richtigen Leistung und korrigiere die Eingabe',
          title:
            'Das Formular ist noch nicht korrekt ausgefüllt.<br/>Bitte klicke auf den Button, um zur richtigen Leistung zu scrollen',
        },
        globalModeAlert: {
          GlobalModeHelperWidget: {
            howUseButton: 'Erfahre, wie du eine Projektkarte mit der globale Konfiguration abgleichst',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Erfahre, wie du eine Projektkarte mit der globale Konfiguration abgleichst',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/713982308',
            },
          },
          title:
            'Du hast den Button "Bearbeite mit aktueller Konfiguration" gedrückt. KanzleiPilot hat nun den historischen Leistungskatalog durch den aktuellen Leistungskatalog ersetzt. Bitte überprüfe nun genau die einzelnen Positionen. Es kann z.B. vorkommen, dass im aktuellen Leistungskatalog bestimmte Leistungen bzw. Nachlässe nicht mehr vorhanden sind, oder sich eine Honorarformel geändert hat. Sobald du unten auf Speichern drückst, wird die Projektkarte mit der aktualisierten Konfiguration abgespeichert.',
        },
        inputs: {
          companyId: {
            label: 'Mandant',
          },
          companyTypeId: {
            label: 'Company type',
          },
          contacts: {
            label: 'Ansprechpartner',
          },
          email: {
            label: 'E-Mail-Address',
          },
          meetingAt: {
            errorLabel: 'Datum des Honorargesprächs',
            label: 'Gespräch geführt am',
          },
          name: {
            default: 'Projekt initiiert am {{date}}',
            label: '$t(common.fields.projectName.label)',
          },
          startOfContract: {
            label: 'Vertragsstart',
          },
        },
        options: {
          cancel: '$t(common.cancel)',
          debugMode: {
            label: 'Konfiguration anzeigen',
            tooltip:
              'Zeige die Kalkulationsdetails direkt unterhalb der Leistung an und überblicke, wie sich die Honorare der einzelnen Leistungen berechnen.',
          },
          feeType: {
            fixedMonthlyFee: 'Unternehmerhonorar Modus',
            label: 'Kalkulations-Modus',
            standardFee: 'Standard Modus',
            tooltip:
              'Kalkulations-Modus in dem KanzleiPilot die Honorare kalkuliert. Im Standard Modus ist alles wie gehabt: einmalige, monatliche und jährliche Leistungen werden transparent dargestellt. Beim Unternehmerhonorar Modus werden monatlich und jährliche Positionen zu einem monatlichen Honorar zusammengefasst und ihre Einzelhonorare werden mit dem Hinweis „Die Leistung ist im Unternehmerhonorar inklusive“ versteckt. Einmalige Leistungen werden auch im Unternehmerhonorar transparent dargestellt.',
          },
          hiddenNote: {
            placeholder: 'Versteckte Notizen',
            title: 'Versteckte Notizen',
          },
          hideHiddenNote: 'Notiz verbergen',
          ok: '$t(common.ok)',
          showDiscounts: {
            disabled:
              'Du kannst die Nachlässe nicht verstecken, da in der Projektkarte mindestens 1 Nachlass ausgewählt ist.',
            label: 'Nachlässe anzeigen',
            tooltip:
              'Sollen Nachlässe angezeigt werden? Wir empfehlen dir: Schalte die Anzeige von Nachlässen im Standard AUS und fokussiere dich darauf, dem Mandanten seinen Nutzen zu verdeutlichen (siehe “Einstellungen > Projektkarte > Projektkarte Voreinstellungen”).',
          },
          showHiddenNote: 'Notiz anzeigen',
          showPrices: {
            label: 'Honorare anzeigen',
            tooltip:
              'Sollen Honorare beim Öffnen der Projektkarte angezeigt werden? Wir empfehlen dir: Schalte die Honorareanzeige im Standard AUS (siehe “Einstellungen > Projektkarte > Projektkarte Voreinstellungen”).',
          },
          showVat: {
            label: 'Umsatzsteuer anzeigen',
            tooltip:
              'Aktiviere diese Option, um die Umsatzsteuer auszuweisen. Die Steuer wird in der interaktiven Projektkarte und in den Dokumenten ausgewiesen (in den Blöcken "Ihre Investition", "Details zu den ausgewählten Dienstleistungen" und "Strategische Empfehlungen")',
          },
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Optionen',
          vatType: {
            company: '$t(common.vatType.company)',
            label: 'Begriff verwenden',
            personal: '$t(common.vatType.personal)',
          },
        },
        somethingChangedWhenOpenedEdit:
          'Die Stamm-Konfiguration, auf der diese Projektkarte zum Zeitpunkt ihrer letzten Bearbeitung beruht hat, hat sich geändert. Es werden aktuelle Werte dargestellt. Bitte überprüfe diese Projektkarte genau.',
        titleAlt: 'Tenant Logo',
        validityContract: {
          thisMonth: 'Dieser Monat',
          title: 'Wann startet der Vertrag?',
        },
      },
      lockedIcon: {
        LockHelperWidget: {
          howUseButton: 'Erfahre, wie die festgeschriebene Konfiguration funktioniert',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Erfahre, wie die festgeschriebene Konfiguration funktioniert',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/715751588',
          },
        },
        confirmationTitle:
          'Achtung: Die Daten, die du gerade eingegeben hast gehen beim Abgleichen mit der globalen Konfiguration verloren. Fortfahren?',
        editButton: 'Bearbeite mit aktueller Konfiguration',
        title: 'Die Projektkarte basiert auf der historischen Konfiguration vom {{date}}.',
      },
    },
    validation: {
      simplifiedUrl: 'Bitte trage eine gültige URL ein (z.B. www.google.de)',
    },
    verify: {
      AcceptInvitation: {
        title: 'Aktive jetzt deinen KanzleiPilot Zugang',
      },
    },
    viewer: {
      ShoppingCartView: {
        BookedServices: {
          bookmarkedItems: 'Strategische Empfehlungen',
          categoryTotals: 'Kalkulation für Bereich',
          items: 'Beauftragte Leistungen',
          staticItems: 'Statische Leistungen',
        },
        ChangeExpirationDateOnSigningLinkModal: {
          addHours: {
            label: 'Stunden hinzufügen:',
            unit: 'Stunden',
          },
          buttons: {
            cancel: 'Abbrechen',
            ok: 'Änderung vornehmen',
          },
          currentDateLabel: 'Aktuell gültig bis:',
          newDateLabel: 'Nach Änderung gültig bis:',
          title: 'Gültigkeit des Links verlängern',
        },
        CompareToGlobal: {
          title: 'Vergleiche mit globaler Konfiguration',
          usedVariablesTitle: 'Verwendete Variablen',
        },
        CreatedDocuments: {
          addDocument: 'Weitere Dokumentenvorlage hinzufügen',
          addDocumentsModal: {
            placeholder: 'Wähle die Dokumentenvorlagen aus',
            title: 'Wähle weitere Dokumentenvorlagen aus',
          },
          disabledAddingTooltip: 'Du kannst keine Dokumentenvorlagen hinzufügen, da sich die Daten geändert haben.',
          disabledByDigitalSignatureAddingTooltip:
            'Du kannst keine neuen Dokument hinzufügen, da die digitale Signatur bereits eingeleitet wurde.',
          downloadAllPDFs: 'Alle Dokumente herunterladen',
          error: 'Generierung des Dokuments fehlgeschlagen',
          noAvailableDocumentsTooltip: 'Es sind keine Dokumentenvorlagen zum Hinzufügen zur Projektkarte vorhanden.',
          title: 'Dokumente',
          viewPDF: 'Vorschau ansehen (während PDF generiert wird)',
        },
        DigitalSignature: {
          additionalButtons: {
            remindAll: 'An alle Erinnerungsemail senden',
            revokeCart: 'Breche Signaturprozess für alle Signierer ab',
            startDigitalSignature: 'Starte Signaturprozess',
            terminateCart: 'Kündige Karte',
          },
          notReminded: ' - noch nicht erinnert',
          notSigned: 'noch nicht signiert',
          reminded: ' - zuletzt erinnert am {{lastRemindedDate}}',
          signed: 'signiert am {{signDate}}',
          signeeManagement: {
            copiedLink: 'Link wurde in Zwischenablage kopiert!',
            copiedPassword: 'Passwort wurde in Zwischenablage kopiert!',
            copyLink: 'Kopiere Link',
            copyPassword: 'Kopiere Passwort',
            expandLinkValidity: {
              button: 'Linkgültigkeit verlängern',
              dateNotValidError:
                'Das Gültigkeitsdatum kann nicht geändert werden, da es vor den aktuellen Datum liegt. Wähle ein neues Datum das nach den aktuellen Datum folgt.',
              notFound: 'Das Gültigkeitsdatum des Linkes kann nicht verändert werden',
              success: 'Die Gültigkeit des Linkes wurde erfolgreich verlängert.',
            },
            generateNewLink: {
              button: 'Neuen Link generieren',
              notFound: 'Es kann kein neuer Link generiert werden',
              success: 'Neuer Signierlink wurde erfolgreich generiert.',
            },
            generateNewPassword: {
              button: 'Neues Passwort generieren',
              notFound: 'Es kann kein neues Passwort generiert werden',
              success: 'Neues Passwort wurde erfolgreich generiert.',
              usePasswordNotEnabledError:
                'Es kann kein neues Passwort generiert werden, da dieser Signaturprozess kein Passwort verwendet.',
            },
            link: 'Link:',
            notFoundError: '{{specificMessage}}, da der Signierprozess nicht mehr aktiv ist.',
            password: 'Passwort:',
            resendPassword: {
              button: 'Passwort erneut senden',
              notFound: 'Passwort kann nicht versendet werden',
              success: 'Der Versand des Passwortes wurde erfolgreich angefragt.',
              usePasswordNotEnabledError:
                'Das Passwort kann nicht versendet werden, da dieser Signaturprozess kein Passwort verwendet.',
            },
            sendRemainderMail: {
              button: 'Erinnerungsemail senden',
              notFound: 'Es kann keine Benachrichtigung versendet werden',
              success: 'Der Versand einer Erinnerungsemail wurde erfolgreich angefragt.',
            },
            showLink: 'Zeige Link an',
            showPassword: 'Zeige Passwort an',
          },
          stateRowLabel: 'Digitaler Signatur Status',
          states: {
            DRAFT: 'Entwurf',
            REVOKED: 'zurückgezogen',
            SIGNED: 'signiert',
            STARTED: 'gestartet',
            TERMINATED: 'gekündigt',
          },
          title: 'Digitale Signatur',
          userHasntSignedYetStateAdditional: ' (Du hast noch nicht signiert)',
        },
        GeneralProjectData: {
          generalProjectData: 'Projekt',
          projectName: 'Projektname',
        },
        HelperWidget: {
          howUseButton: 'Anleitung: So findest du alle Informationen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Betrachten der Projektkarte',
            videoCaption: 'Erfahre, wie welche Informationen du in dieser Maske findest',
            videoUrl: 'https://vimeo.com/541269600',
          },
        },
        HistoricRevisions: {
          title: 'Revisionen',
        },
        Overview: {
          fields: {
            company: 'Mandant',
            companyIdentifier: 'Mandatsnummer',
            contacts: 'Ansprechpartner',
            createdAt: 'Erstellt am',
            createdBy: 'Erstellt durch',
            meetingAt: 'Gespräch geführt am',
            monthlyAdvance: 'Wert: {{monthlyPaymentTitle}}',
            monthlyFeeMode: 'Kalkulations-Modus',
            monthlyFixedFee: 'Wert monatlich (Unternehmerhonorar)',
            monthlyPaymentDecision: {
              decideLater: 'Noch nicht entschieden',
              no: 'Inaktiv',
              yes: 'Aktiv',
            },
            monthlyPaymentStatus: 'Auswahl: {{monthlyPaymentTitle}}',
            number: 'Projektkarte #',
            startOfContract: 'Vertragsstart',
            status: 'Status',
            valueMonthly: 'Wert monatlich',
            valueOneOff: 'Wert einmalig',
            valueYearly: 'Wert jährlich',
          },
          title: 'Details',
        },
        RemindAllSigneesModal: {
          buttons: {
            cancel: 'Abbrechen',
            openEmailEditor: 'E-Mail Editor öffnen',
            send: 'Senden',
          },
          emailDrawer: {
            onCloseModal: {
              cancelButtonLabel: 'Abbrechen und Erinnerung nicht senden',
              message: 'ACHTUNG: Wenn du dieses Fenster schließt, wird die Erinnerung nicht gesendet.',
              okButtonLabel: 'Zurück',
            },
            title: 'Erinnere mehrere Signierer über Signiervorgang',
          },
          emailTemplateSelect: {
            emailTemplate: 'E-Mail Vorlage',
            error: 'Wähle eine E-Mail Vorlage aus',
            label: 'Welche E-Mail Vorlage möchtest du verwenden?',
            placeholder: 'Keine E-Mail Vorlage ausgewählt',
          },
          onlyUnsignedSigneesSwitch: {
            label: 'Sollen nur Signierer, die noch nicht signiert haben, erinnert werden?',
          },
          signeeTypeSelect: {
            all: 'Alle',
            company: 'Nur Mandanten',
            label: 'Welche Signierer sollen benachrichtigt werden?',
            tenant: 'Nur Kanzleimitglieder',
          },
          title: 'Erinnerungsemail an alle Signierer senden',
        },
        RevokeDigitalSignatureModal: {
          buttons: {
            no: 'Nein',
            yes: 'Ja',
          },
          editModeAlert:
            'Diese Karte hat einen offenen digitalen Signaturprozess. Du musst diesen zuerst widerrufen, um diese Karte bearbeiten zu können.',
          internalNoteLabel: 'Optionale interne Notiz für Widerruf',
          isForEditShoppingCart: {
            buttons: {
              yes: 'Signaturprozess widerrufen und Projektkarte bearbeiten',
            },
            title: 'Projektkarte bearbeiten - offener Signaturprozess',
          },
          notificationSwitch: {
            label: 'Benachrichtige Signierer per E-Mail',
            off: 'Aus',
            on: 'An',
          },
          title: 'Möchtest du den Signaturprozess wirklich abbrechen?',
        },
        ShoppingCartHistoricItems: {
          DigitalSignature: {
            allSignedFailed: 'Digitaler Signaturprozess fehlgeschlagen (Dokumente konnten nicht signiert werden.)',
            autoSigned: 'Projektkarte automatisch signiert für <gray>{{signeeFirstName}} {{signeeLastName}}</gray>',
            bookmarkedItemsAdded: 'Strategische Empfehlung dazugebucht durch {{signeeFirstName}} {{signeeLastName}}',
            linkExpirationDateChanged:
              'Gültigkeit des Linkes für <gray>{{signeeEmail}}</gray> verlängert von <gray>{{oldDate}}</gray> auf <gray>{{newDate}}</gray>',
            linkRequested: 'Link für <gray>{{signeeEmail}}</gray> wurde abgerufen',
            monthlyPaymentDecisionTakenNo:
              '<gray>{{signeeEmail}}</gray> hat sich gegen eine monatliche Zahlung entschieden',
            monthlyPaymentDecisionTakenYes:
              '<gray>{{signeeEmail}}</gray> hat sich für eine monatliche Zahlung entschieden',
            newLink: 'Neuer Link für <gray>{{signeeEmail}}</gray> wurde erstellt',
            newPassword: 'Neues Passwort für <gray>{{signeeEmail}}</gray> wurde erstellt',
            passwordMailSend: 'E-Mail für Signierpasswort für digitalen Signaturprozess gesendet',
            passwordRequested: 'Passwort für <gray>{{signeeEmail}}</gray> wurde abgerufen',
            passwordResended: 'Passwortemail für <gray>{{signeeEmail}}</gray> wurde angefordert',
            remindAll: 'Erinnerungsemails für <gray>{{signeeEmails}}</gray> wurde angefordert',
            remindSignee: 'Erinnerungsmail für <gray>{{signeeEmail}}</gray> wurde angefordert',
            reminderMailSend: 'E-Mail für Erinnerung eines Signierers für digitalen Signaturprozess gesendet',
            revokeNotificationMailSend: 'E-Mail für das Zurückziehen der Projektkarte gesendet',
            revoked: 'Projektkarte wurde zurückgezogen',
            sepaMandateGranted: 'Mandant hat SEPA-Lastschriftmandat erteilt',
            sepaMandateGrantedCard: {
              accountOwnerName: 'Name des Kontoinhabers',
              bankaccountInformation: 'Bankaccount-Informationen',
            },
            sepaMandateNotGranted: 'Mandant hat SEPA-Lastschriftmandat abgelehnt',
            signed: 'Signiert durch',
            signedDocsMailSend: 'E-Mail für erfolgreichen Abschluss des digitalen Signaturprozess gesendet',
            signeeOpenedNotificationMailSend:
              'E-Mail für Benachrichtigung über das erste Öffnen durch einen Signierer gesendet',
            signeeSignedNotificationMailSend:
              'E-Mail Benachrichtigung darüber, dass ein Signierer signiert hat, gesendet',
            startFailedPdf:
              'Starten des Signaturprozesses fehlgeschlagen (Mindestens ein Dokument konnte nicht generiert werden.)',
            startMailSendForCompanySignee:
              'E-Mail für Mandantenbenachrichtigung für digitalen Signaturprozess gesendet',
            startMailSendForTenantSignee:
              'E-Mail für Kanzleimitgliederbenachrichtigung für digitalen Signaturprozess gesendet',
            started: 'Digitaler Signaturprozess gestartet',
            successfulFinished: 'Digitaler Signaturprozess erfolgreich abgeschlossen',
          },
          ShoppingCart: {
            changeStatus: 'Status hat sich von {{oldStatusName}} auf {{newStatusName}} geändert',
            created: 'Projektkarte{{revision}} erstellt',
            deleted: 'Projektkarte Revision ({{projectCardId}}) gelöscht',
            sentByEmail: 'E-Mail erfolgreich gesendet',
          },
          revision: ' Revision',
          sendingError: 'Fehler beim Versand der E-Mail',
          title: 'Historie',
        },
        columns: {
          actions: '$t(common.columns.actions)',
          category: '$t(common.columns.category)',
          createdAt: '$t(common.columns.createdAt)',
          createdBy: '$t(common.columns.createdBy)',
          data: '$t(common.columns.data)',
          document: '$t(common.columns.document)',
          inputFields: 'Honorarparameter',
          inputParameters: 'Honorarparameter',
          internalNote: '$t(common.columns.internalNote)',
          interval: '$t(common.columns.interval)',
          item: '$t(common.columns.item)',
          itemWasBookmarkedHint: 'Beauftrage strategische Empfehlung',
          price: '$t(common.columns.price)',
          selectedCategories: 'Ausgewählte Bereiche',
          selectedItems: 'Ausgewählte Leistungen',
          value: '$t(common.columns.value)',
          valueMonthly: '$t(common.columns.valueMonthly)',
          valueOneOff: '$t(common.columns.valueOneOff)',
          valueYearly: '$t(common.columns.valueYearly)',
        },
        customInternalValue: 'individuell',
        editShoppingCart: 'Projektkarte bearbeiten',
        feeMode: {
          fixedMonthly: '$t(onboarding.pricingMode.fixedMonthlyModeTitle)',
          standard: '$t(onboarding.pricingMode.standardModeTitle)',
        },
        menu: {
          delete: {
            disabledOnDsTooltip:
              'Diese Karte hat einen offenen digitalen Signaturprozess. Du musst diesen zuerst abbrechen, um diese Karte löschen zu können.',
            label: 'Projektkarte löschen',
          },
          disableDuplicate:
            'Du kannst diese Projektkarte NICHT duplizieren, da die sich die Basiskonfiguration (z.B. Honorarformel) geändert hat.',
          duplicate: 'Projektkarte duplizieren',
          remindAll: 'An alle Erinnerungsemail senden',
          revoke: 'Breche Signaturprozess ab',
        },
        migratedAlert:
          'Diese Projektkarte wurde am {{migrationDate}} migriert. Die PDF Dateien und die hier gespeicherten Daten sind nicht verändert worden. Über den Button "Projektkarte bearbeiten" kannst du die Projektkarte mit der "aktuelle Konfiguration" bearbeiten.',
        noRecipientsWithEmail:
          'Bitte hinterlege für mindestens einen Kontakt eine E-Mail Adresse, um eine E-Mail versenden zu können',
        rows: {
          calculationMode: '$t(common.fields.calculationMode.label)',
          highlightBindingness: '$t(common.fields.highlightBindingness.label)',
          maxPrice: '$t(common.fields.maxPrice.label)',
          minPrice: '$t(common.fields.minPrice.label)',
          name: '$t(common.fields.name.label)',
          paymentInterval: '$t(common.fields.paymentInterval.label)',
          pricingFormula: '$t(common.fields.pricingFormula.label)',
          scales: 'Staffeln',
        },
        sendEmailButton: 'E-Mail Editor öffnen',
        sendEmailConfirmationTitle: 'Möchtest du die E-Mail versenden?',
        sendReminderButton: 'Erinnerung senden',
        signCartButton: 'Jetzt selber signieren',
        startDigitalSignature: 'Starte Signaturprozess',
        subtitle: 'erstellt am {{createdAt}} von {{createdBy}}',
        title: 'Projektkarte betrachten',
        viewClient: 'Betrachte Mandant',
      },
    },
  },
};
