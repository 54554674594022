import { gql } from '@apollo/client';
import { adminContactFragment } from 'graphql/fragments';

const createContactMutation = gql`
  mutation createContactMutation($contact: ContactInput!, $_id: ID!) {
    createContact(contact: $contact, _id: $_id) {
      ...adminContactFragment
    }
  }
  ${adminContactFragment}
`;

export default createContactMutation;
