/* eslint-disable react/no-children-prop */
import AccountSetting from 'pages/user/AccountSetting/AccountSetting';
import AppLayout from 'components/layouts/AppLayout';
import OnboardingLayout from 'components/layouts/OnboardingLayout';
import CatalogueConfiguration from 'pages/admin/CatalogueConfiguration';
import ClientsListPage from 'ProjectCardManagement/ClientManagement/pages/ClientsListPage';
import ClientViewPage from 'ProjectCardManagement/ClientManagement/pages/ClientViewPage';
import CommonLibraryConfiguration from 'pages/admin/CommonLibraryConfiguration';
import CommonLibraryDocumentTemplate from 'pages/admin/CommonLibraryDocumentTemplate';
import CommonLibraryEmailTemplates from 'pages/admin/CommonLibraryEmailTemplates';
import CompanySetting from 'pages/admin/CompanySetting/CompanySetting';
import ConstantsAndPlaceholdersSetting from 'pages/admin/ConstantsAndPlaceholdersSetting/ConstantsAndPlaceholdersSetting';
import CorporateIdentitySetting from 'pages/admin/CorporateIdentitySetting';
import DashboardPage from 'pages/Dashboard';
import DocumentTemplate from 'pages/admin/DocumentTemplate';
import DocumentTemplateListPage from 'TenantInstanceConfiguration/DocumentTemplateManagement/pages/DocumentTemplateListPage';
import EmailSetting from 'pages/admin/EmailSetting';
import EmailTemplates from 'TenantInstanceConfiguration/EmailTemplateManagement/pages/EmailTemplates';
import ImportCompanies from 'pages/admin/ImportCompanies';
import LicenseOverviewSetting from 'pages/admin/LicenseOverviewSetting';
import ManageAllUsers from 'pages/superAdmin/ManageAllUsers';
import ManageDigitalSignaturePage from 'pages/superAdmin/ManageDigitalSignature/ManageDigitalSignature';
import ManagePersons from 'pages/admin/ManagePersons/ManagePersons';
import ManageShoppingCartStatus from 'pages/admin/ManageShoppingCartStatus';
import ManageTenants from 'pages/superAdmin/ManageTenants';
import ManageUsers from 'pages/admin/ManageUsers/ManageUsers';
import OtherSettings from 'pages/admin/OtherSettings';
import Migrations from 'pages/superAdmin/Dev/Migrations';
import DBCalls from 'pages/superAdmin/Dev/DBCalls';
import ProfileBackendCPU from 'pages/superAdmin/Dev/ProfileBackendCPU';
import CPUMEMUsage from 'pages/superAdmin/Dev/CPUMEMUsage';
import Logs from 'pages/superAdmin/Dev/Logs';
import DigitalSignaturePreferences from 'pages/admin/DigitalSignaturePreferences/DigitalSignaturePreferences';
import MonthlyPaymentPreferences from 'pages/admin/MonthlyPaymentPreferences/MonthlyPaymentPreferences';
import OnboardingCompletedPage from 'pages/onboarding/OnboardingCompletedPage';
import OnboardingPage from 'pages/onboarding/Onboarding';
import PrintShoppingCartPage from 'pages/shoppingCartManagement/ShoppingCartSuccessful/PrintShoppingCartPage';
import ROLES from 'constants/roles';
import ScrollToTop from 'components/common/ScrollToTop';
import SendEmailShoppingCartPage from 'pages/shoppingCartManagement/ShoppingCartSuccessful/SendEmailShoppingCartPage';
import ShoppingCartEntries from 'pages/shoppingCartManagement/ShoppingCartEntries';
import ShoppingCartInsertMode from 'pages/shoppingCartManagement/ShoppingCartInsertMode';
import ShoppingCartPreferences from 'pages/admin/ShoppingCartPreferences/ShoppingCartPreferences';
import ShoppingCartRevision from 'pages/shoppingCartManagement/ShoppingCartRevision';
import ShoppingCartView from 'pages/shoppingCartManagement/ShoppingCartView';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import usePreloadLogo from 'hooks/user/usePreloadLogo';
import { Routes, Navigate, Route, useLocation } from 'react-router-dom';
import { adminRoles, rolesWithoutViewer, isAdmin } from 'utils/role';
import { matchPath } from 'react-router';
import { useIsTenantOnboardingCompleted } from 'graphql/hooks';
import { useSubscription, gql } from '@apollo/client';
import GlobalTexts from 'pages/admin/GlobalTexts/GlobalTexts';
import { authRoutes } from './AuthRouter';
import routePaths, { usePreloadLogoRoutes } from './route-paths';

const routes = [
  {
    path: routePaths.catalogueConfiguration,
    component: CatalogueConfiguration,
    roles: adminRoles,
    addStar: true,
  },
  {
    path: routePaths.client,
    component: ClientViewPage,
  },
  {
    path: routePaths.clients,
    component: ClientsListPage,
  },
  {
    path: routePaths.companySetting,
    component: CompanySetting,
    roles: adminRoles,
  },
  {
    path: routePaths.commonLibraryDocumentTemplateList,
    component: DocumentTemplateListPage,
    roles: adminRoles,
  },
  {
    path: routePaths.documentTemplateList,
    component: DocumentTemplateListPage,
    roles: adminRoles,
  },
  {
    path: routePaths.documentTemplateConfiguration,
    component: DocumentTemplate,
    roles: adminRoles,
  },
  {
    path: routePaths.digitalSignaturePreferences,
    component: DigitalSignaturePreferences,
    roles: adminRoles,
  },
  {
    path: routePaths.monthlyPaymentPreferences,
    component: MonthlyPaymentPreferences,
    roles: adminRoles,
  },
  {
    path: routePaths.shoppingCartPreferences,
    component: ShoppingCartPreferences,
    roles: adminRoles,
  },
  {
    path: routePaths.commonLibraryEmailTemplates,
    component: CommonLibraryEmailTemplates,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.commonLibraryDocumentTemplateConfiguration,
    component: CommonLibraryDocumentTemplate,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.manageDigitalSignature,
    component: ManageDigitalSignaturePage,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.manageTenants,
    component: ManageTenants,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.globalTexts,
    component: GlobalTexts,
    roles: adminRoles,
  },
  {
    path: routePaths.otherSettings,
    component: OtherSettings,
    roles: adminRoles,
  },
  {
    path: routePaths.manageAllUsers,
    component: ManageAllUsers,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.corporateIdentitySetting,
    component: CorporateIdentitySetting,
    roles: adminRoles,
  },
  {
    path: routePaths.licenseOverviewSetting,
    component: LicenseOverviewSetting,
    roles: adminRoles,
  },
  {
    path: routePaths.manageUsers,
    component: ManageUsers,
    roles: adminRoles,
  },
  {
    path: routePaths.emailTemplates,
    component: EmailTemplates,
    roles: adminRoles,
  },
  {
    path: routePaths.manageShoppingCartStatus,
    component: ManageShoppingCartStatus,
    roles: adminRoles,
  },
  {
    path: routePaths.emailSettings,
    component: EmailSetting,
    roles: adminRoles,
  },
  {
    path: routePaths.importCompanies,
    component: ImportCompanies,
    roles: adminRoles,
  },
  {
    path: routePaths.managePersons,
    component: ManagePersons,
    roles: adminRoles,
  },
  {
    path: routePaths.printShoppingCart,
    component: PrintShoppingCartPage,
    roles: rolesWithoutViewer,
  },
  {
    path: routePaths.sendEmailShoppingCart,
    component: SendEmailShoppingCartPage,
    roles: rolesWithoutViewer,
  },
  {
    path: routePaths.constantsAndPlaceholdersSetting,
    component: ConstantsAndPlaceholdersSetting,
    roles: adminRoles,
  },
  {
    path: routePaths.commonLibraryConfiguration,
    component: CommonLibraryConfiguration,
    roles: [ROLES.SUPER_ADMIN],
    addStar: true,
  },
  {
    path: routePaths.migrations,
    component: Migrations,
    devRequired: true,
  },
  {
    path: routePaths.dbCalls,
    component: DBCalls,
    devRequired: true,
  },
  {
    path: routePaths.profileBackendCPU,
    component: ProfileBackendCPU,
    devRequired: true,
  },
  {
    path: routePaths.CPUMEMUsage,
    component: CPUMEMUsage,
    devRequired: true,
  },
  {
    path: routePaths.devLogging,
    component: Logs,
    devRequired: true,
  },
  {
    path: routePaths.dashboard,
    component: DashboardPage,
  },
  {
    path: routePaths.shoppingCartEntries,
    component: ShoppingCartEntries,
  },
  {
    path: routePaths.shoppingCart,
    component: ShoppingCartInsertMode,
    roles: rolesWithoutViewer,
  },
  {
    path: routePaths.accountSetting,
    component: AccountSetting,
  },
  {
    path: routePaths.shoppingCartView,
    component: ShoppingCartView,
  },
  {
    path: routePaths.shoppingCartRevision,
    routerPath: routePaths.routerShoppingCartRevision,
    component: ShoppingCartRevision,
    roles: rolesWithoutViewer,
  },
];

function CustomScrollToTop() {
  const location = useLocation();
  const isPrevItemEditPath =
    location.state?.prevLocation &&
    (matchPath(routePaths.itemCatalogueConfiguration, location.state?.prevLocation) ||
      matchPath(routePaths.commonLibraryItemConfiguration, location.state?.prevLocation));
  const isItemEditPath =
    matchPath(routePaths.itemCatalogueConfiguration, location.pathname) ||
    matchPath(routePaths.commonLibraryItemConfiguration, location.pathname);
  if (isPrevItemEditPath || isItemEditPath) return null;
  return <ScrollToTop />;
}
const PreloadLogo = () => {
  usePreloadLogo();
  return '';
};
const Subs = gql`
  subscription loggedOutByTimeout {
    _empty
  }
`;
const AppRouter = () => {
  const [me] = useCurrentUser();
  const role = me?.role;
  const filteredRoutes = routes.filter((r) => {
    if (r.devRequired) return me?.isDev;
    return r.roles ? r.roles.includes(role) : true;
  });
  const [tenantOnboardingCompleted] = useIsTenantOnboardingCompleted();

  const requiresTenantOnboarding = tenantOnboardingCompleted === false && isAdmin(role);

  const preloadLogoRoutes = usePreloadLogoRoutes();

  useSubscription(Subs, {
    onSubscriptionData: ({ subscriptionData }) => {
      console.log(subscriptionData);
      // const loggedOutByTimeout = get(subscriptionData, 'data.loggedOutByTimeout');
      // if (loggedOutByTimeout) window.setTimeout(() => window.routerHistory.push('/logout'));
      // console.log('Logged out by server');
    },
  });

  return (
    <Routes>
      <Route
        path={routePaths.tenantOnboarding}
        element={
          requiresTenantOnboarding ? (
            <OnboardingLayout>
              <OnboardingPage />
            </OnboardingLayout>
          ) : (
            <Navigate to={routePaths.tenantOnboardingCompleted} />
          )
        }
      />
      <Route
        path={routePaths.tenantOnboardingCompleted}
        element={
          requiresTenantOnboarding ? (
            <Navigate to={routePaths.tenantOnboarding} />
          ) : (
            <OnboardingLayout>
              <OnboardingCompletedPage />
            </OnboardingLayout>
          )
        }
      />
      {requiresTenantOnboarding ? (
        <Route path="*" element={<Navigate to={routePaths.tenantOnboarding} />} />
      ) : (
        <Route
          path="*"
          element={
            <>
              <Routes>
                {Object.values(preloadLogoRoutes).map((e) => (
                  <Route key={e} path={e} element={<PreloadLogo />} />
                ))}
                <Route path="*" element={null} />
              </Routes>
              <Routes>
                <Route
                  path="/secure/*"
                  element={
                    <AppLayout>
                      <CustomScrollToTop />
                      <Routes>
                        <Route path="*" element={<Navigate to={routePaths.dashboard} />} />
                        {filteredRoutes.map(({ path, routerPath, component: C, addStar, ...rest }) => (
                          <Route
                            key={routerPath || path}
                            path={(routerPath || path) + (addStar ? '/*' : '')}
                            element={<C />}
                            {...rest}
                          />
                        ))}
                      </Routes>
                    </AppLayout>
                  }
                />
                {authRoutes.map(({ path }) => (
                  <Route key={path} path={path} element={<Navigate to="/secure" />} />
                ))}
                <Route path="*" element={<Navigate to="/secure" />} />
              </Routes>
            </>
          }
        />
      )}
    </Routes>
  );
};

export default AppRouter;
