import { Col, Row } from 'antd';
import ContactFirstNameField from './ContactFirstNameField';
import ContactLastNameField from './ContactLastNameField';
import ContactSalutationSelect from './ContactSalutationSelect';

/**
 * Name fields for contact form
 * @returns {JSX.Element} Contact name fields
 */
const ContactNameFields = () => {
  return (
    <Row gutter={32}>
      <Col span={4}>
        <ContactSalutationSelect />
      </Col>
      <Col span={10}>
        <ContactFirstNameField />
      </Col>
      <Col span={10}>
        <ContactLastNameField />
      </Col>
    </Row>
  );
};

export default ContactNameFields;
