import { Transforms, Element as SlateElement, Node, Editor, Range } from 'slate';
import { Plugins } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { LIST_TYPES } from '../BlockButton';

const {
  withMultiLevelList: { getNextListType },
} = Plugins;

/**
 * Get the path before the current item list path.
 * @param {Editor} editor - The Slate editor instance.
 * @param {Array} itemListPath - The path of the current item list.
 * @returns {Array|null} The path before the current item list path or null if not found.
 */
const getPathBefore = (editor, itemListPath) => {
  const beforePath = [...itemListPath];
  const position = beforePath.length - 1;
  if (LIST_TYPES.includes(Node.parent(editor, itemListPath).children[beforePath[position] - 1]?.type)) {
    beforePath[position] -= 1;
    return beforePath;
  }
  return null;
};

/**
 * Get the path after the current item list path.
 * @param {Editor} editor - The Slate editor instance.
 * @param {Array} itemListPath - The path of the current item list.
 * @returns {Array|null} The path after the current item list path or null if not found.
 */
const getPathAfter = (editor, itemListPath) => {
  const afterPath = [...itemListPath];
  const position = afterPath.length - 1;
  if (LIST_TYPES.includes(Node.parent(editor, itemListPath).children[afterPath[position] + 1]?.type)) {
    afterPath[position] += 1;
    return afterPath;
  }
  return null;
};

/**
 * Delete the previous tab character if it exists.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {void} Returns nothing.
 */
const deletePreviousTab = (editor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);
    const before = Editor.before(editor, start, { unit: 'character' });

    if (before) {
      const charBefore = Editor.string(editor, { anchor: before, focus: start });

      if (charBefore === '\t') {
        Transforms.delete(editor, { at: { anchor: before, focus: start } });
      }
    }
  }
};

/**
 * Add a tab space at the current cursor position.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {void} Returns nothing.
 */
const addTabSpace = (editor) => {
  editor.insertText('\t');
};

/**
 * Decrease the depth of the current list item.
 * @param {Editor} editor - The Slate editor instance.
 * @param {Object} _ - Unused parameter.
 * @param {Object} options - Options for the function.
 * @param {string} options.rootElement - The root element type.
 * @returns {void} Returns nothing.
 */
export const decreaseListItemDepth = (editor, _, { rootElement }) => {
  const [, itemListPath] =
    Editor.above(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    }) || [];

  if (!itemListPath?.length) {
    deletePreviousTab(editor);
    return;
  }

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });

  const list = Editor.above(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
  });
  const newProperties = {
    type: list ? 'list-item' : rootElement,
  };
  Transforms.setNodes(editor, newProperties);
};

/**
 * Increase the depth of the current list item.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {void} Returns nothing.
 */
export const increaseListItemDepth = (editor) => {
  const [, itemListPath] =
    Editor.above(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    }) || [];

  if (!itemListPath?.length) {
    addTabSpace(editor);
    return;
  }

  const list = Node.parent(editor, itemListPath);

  const nextListType = getNextListType(list);

  const afterPath = getPathAfter(editor, itemListPath);
  const beforePath = getPathBefore(editor, itemListPath);
  const block = {
    type: list.type,
    listType: nextListType,
    level: (list.level ?? 1) + 1,
  };
  Transforms.wrapNodes(editor, block, {
    at: {
      anchor: {
        ...editor.selection.anchor,
        path: beforePath || editor.selection.anchor.path,
      },
      focus: {
        ...editor.selection.focus,
        path: afterPath || editor.selection.focus.path,
      },
    },
  });
  if (afterPath) {
    const afterOffset = 1 + (beforePath ? 1 : 0);
    afterPath[afterPath.length - 1] -= afterOffset;
    afterPath.push(afterOffset);
    Transforms.unwrapNodes(editor, { at: afterPath });
  }
  if (beforePath) {
    beforePath.push(0);
    Transforms.unwrapNodes(editor, { at: beforePath });
  }
};
