import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * Full salutation field for contact form
 * @returns {JSX.Element} A field to enter the contact full salutation
 */
const ContactFullSalutationField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="fullSalutation"
      label={t('ClientsListPage.addContactModal.form.fullSalutation.label')}
      placeholder={t('ClientsListPage.addContactModal.form.fullSalutation.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ContactFullSalutationField;
