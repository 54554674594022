import { useModal } from 'components/common/Modal/Modal';
import AddButton from 'components/common/AddButton';
import { useTranslation } from 'react-i18next';
import AddOrEditContactModal from '../AddOrEditContactModal';

/**
 * Renders a button for adding a new contact, along with a modal for contact creation. *
 * @component
 * @param {object} props - Component properties
 * @param {string} props.clientId - The ID of the client to which the contact will be associated
 * @returns {JSX.Element} A button for adding a contact, which opens a modal when clicked
 */
const AddContactButton = ({ clientId }) => {
  const { t } = useTranslation();
  const [addModalVisible, showAddModal, hideAddModal] = useModal();

  return (
    <>
      <AddButton onClick={showAddModal} text={t('ClientsListPage.addContactModal.insertNewContactButton')} />
      <AddOrEditContactModal clientId={clientId} closeModal={hideAddModal} visible={addModalVisible} />
    </>
  );
};

export default AddContactButton;
