import { Button, Dropdown, Menu, Switch } from 'antd';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  createCategoryItem,
  deleteCategoryItemWithConfirmation,
  duplicateCategoryItem,
  moveCategoryItem,
} from 'graphql/methods';
import { TableMemo, useFunctionToRefCB } from 'memo';
import { useNavigate } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import EmptyBox from 'components/common/EmptyBox';
import inputConfirmModal from 'utils/inputConfirmModal';
import { adminCategoryListQuery } from 'graphql/queries';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useLibraryContext } from 'contexts/LibraryContext';
import { moveCategoryItemCache } from 'graphql/cache';
import equal from 'fast-deep-equal/es6/react';
import { useMutation } from '@apollo/client';
import { updateItemMutation } from 'graphql/mutations';
import toast from 'utils/toast';
import classNames from 'classnames';
import apollo from 'graphql/apollo';
import { adminItemFragment } from 'graphql/fragments';
import { CalculationMode, HighlightBindingness, PaymentInterval } from 'constants/item';
import { formatPaymentIntervalAndHighlightBindingness, formatPricingFormula } from 'utils/formatValues';
import usePricingFormula from 'hooks/user/usePricingFormula';
import { useShowDigitsContext } from 'contexts/ShowDigitsContext';
import classes from './CollapsibleCategoryConfiguration.module.less';
import { NameFormikInput } from './itemModal/components';
import { textsSchema } from './itemModal/schema';
import { DragHandle, useDragTableComponents, useSortEnd } from './drag/DragTable';
import { copyCategoryItemModal, formatCopyItem } from './ItemsCopyModal';
import { isCategoryItemComplex } from './utils';

const itemForm = ({ nameLabel = i18n.t('admin.itemModal.inputs.name.label') } = {}) =>
  function ItemForm() {
    return (
      <div>
        <Form layout="vertical">
          <NameFormikInput label={nameLabel} />
        </Form>
      </div>
    );
  };
export const FieldDiv = (props) => [{ component: itemForm(props), label: '' }];

const addNewCategoryItem = ({ categoryId, isLibrary, editItem }) =>
  inputConfirmModal({
    fields: FieldDiv(),
    onSubmit: (modifier) =>
      createCategoryItem({
        categoryId,
        isLibrary,
        modifier,
      }).then(({ data }) => {
        const item = grabFirstGQLDataResult(data);
        editItem(item._id);
      }),
    value: {
      calculationMode: CalculationMode.VALUE,
      highlightBindingness: HighlightBindingness.NO_HIGHLIGHT,
      infoText: null,
      minPrice: null,
      name: '',
      paymentInterval: PaymentInterval.ONE_OFF,
      pricingFormula: '[{"type":"div","children":[{"text":"0"}]}]',
      scalePricingFormulaTitle: '',
      scaleTitle: '',
      scales: [],
      scalesEnabled: false,
    },
    errorResolver: { Duplicated: ['name', i18n.t('admin.itemModal.duplicatedErrorMessage')] },
    headerText: i18n.t('admin.itemModal.addTitle'),
    okText: i18n.t('admin.itemModal.ok'),
    cancelText: i18n.t('admin.itemModal.cancel'),
    categoryId,
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
      }),
    forceMultiField: true,
    width: '600px',
  });

const duplicateExistingCategoryItem = ({ _id, name: nameProps, isLibrary, editItem }) =>
  inputConfirmModal({
    fields: FieldDiv({ nameLabel: i18n.t('admin.CatalogueConfiguration.copyItem.inputs.name.label') }),
    onSubmit: ({ name }) =>
      duplicateCategoryItem({
        itemId: _id,
        isLibrary,
        name,
      }).then(({ data }) => editItem(grabFirstGQLDataResult(data)._id)),
    value: {
      name: formatCopyItem(nameProps),
    },
    errorResolver: { Duplicated: ['name', i18n.t('admin.duplicateItemConfirmation.duplicatedErrorMessage')] },
    headerText: i18n.t('admin.duplicateItemConfirmation.title'),
    okText: i18n.t('admin.duplicateItemConfirmation.ok'),
    cancelText: i18n.t('admin.duplicateItemConfirmation.cancel'),
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
      }),
    forceMultiField: true,
    width: '600px',
  });

const ActiveInput = ({ _id, active }) => {
  const { t } = useTranslation();
  const [updateItem] = useMutation(updateItemMutation);

  const onClick = useFunctionToRefCB(() => {
    updateItem({
      variables: { _id, modifier: { active: !active } },
      optimisticResponse: {
        updateCategoryItem: {
          _id,
          __typename: 'CategoryItem',
          active: !active,
        },
      },
    }).catch((e) => toast.error(getMessageFromGraphQLError(e)));
  });

  return (
    <Switch checked={active} onClick={onClick} checkedChildren={t('common.on')} unCheckedChildren={t('common.off')} />
  );
};
const ActiveInputMemo = memo(ActiveInput, equal);

export const ItemDropdown = ({ onClickDelete, onClickDuplicate, onClickCopy }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item onClick={onClickDuplicate} title="">
        <FileAddOutlined />
        {t('admin.duplicateItemConfirmation.openModalButtonTitle')}
      </Menu.Item>
      <Menu.Item onClick={onClickCopy} title="">
        <CopyOutlined />
        {t('admin.CatalogueConfiguration.copyItem.openModalButtonTitle')}
      </Menu.Item>
      <Menu.Item danger onClick={onClickDelete} title="">
        <DeleteOutlined />
        {t('admin.deleteItemConfirmation.openModalButtonTitle')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomCenter"
      onClick={(e) => e.preventDefault()}
      onKeyDown={(e) => e.preventDefault()}
    >
      <MoreOutlined
        className="options hover-background-light-primary display-table-cell margin-auto"
        style={{ padding: 6 }}
      />
    </Dropdown>
  );
};

/**
 * Replaces style placeholders with div elements.
 *
 * @param {string} formula - The formula containing style placeholders.
 * @returns {Array} - An array of div elements with the style placeholders replaced.
 */
const replaceStylePlaceholderWithDiv = (formula) => {
  const parts = formula.split(/__STYLE_START__|__STYLE_END__/);

  const result = parts.map((part, index) => {
    const key = `${index}-${part}`;

    if (index % 2 === 1) {
      return (
        <div key={key} className={classes.pricingFormulaHighlight}>
          {part.trim()}
        </div>
      );
    }
    return <div key={key}>{part.trim()}</div>;
  });

  return result;
};

/**
 * PricingFormula component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.calculationMode - The calculation mode.
 * @param {string} props.pricingFormula - The pricing formula.
 * @param {Array} props.inputFields - The input fields.
 * @param {boolean} [props.styled=false] - Indicates if the component should be styled, requires styledFormatted.
 * @returns {JSX.Element} The rendered PricingFormula component.
 */
export const PricingFormula = ({ calculationMode, pricingFormula, inputFields, styled = false }) => {
  const formula = usePricingFormula({ pricingFormula, inputFields });

  const formattedFormula = useMemo(() => {
    if (styled) return formula;
    return formatPricingFormula({ calculationMode, formula });
  }, [calculationMode, formula, styled]);

  return styled ? (
    <div className={classes.pricingFormula}>{replaceStylePlaceholderWithDiv(formattedFormula)}</div>
  ) : (
    formattedFormula
  );
};

const categoryItemsTableColumns = ({ t, editItem, isLibrary, showDigits }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
    dataIndex: '',
    shouldCellUpdate: () => false,
  },
  {
    className: classNames(classes.nameColumn, 'table-text'),
    title: t('admin.itemTable.columns.name'),
    dataIndex: 'name',
    key: 'name',
    shouldCellUpdate: (f, s) => f.name !== s.name,
  },
  {
    className: classNames(classes.pricingFormulaColumn, 'table-text'),
    title: t('admin.itemTable.columns.pricingFormula'),
    render: (e) => (
      <>
        <PricingFormula
          calculationMode={e.calculationMode}
          pricingFormula={e.pricingFormulaExtended.styledFormatted ?? e.pricingFormulaExtended.formatted}
          inputFields={e.inputFields}
          styled={!!e.pricingFormulaExtended.styledFormatted}
        />
        {Number.isFinite(e.minPrice) ? (
          <div className="info-text">
            {t('user.ShoppingCart.Category.Item.minPrice', {
              minPrice: Calc.formatCurrency(e.minPrice, { showDigits }),
            })}
          </div>
        ) : null}
        {Number.isFinite(e.maxPrice) ? (
          <div className="info-text">
            {t('user.ShoppingCart.Category.Item.maxPrice', {
              maxPrice: Calc.formatCurrency(e.maxPrice, { showDigits }),
            })}
          </div>
        ) : null}
      </>
    ),
    key: 'pricingFormula',
    shouldCellUpdate: (f, s) => f.pricingFormulaExtended !== s.pricingFormulaExtended,
  },
  {
    className: classNames(classes.paymentIntervalColumn, 'table-text'),
    title: t('admin.itemTable.columns.paymentInterval'),
    render: (e) =>
      formatPaymentIntervalAndHighlightBindingness({
        paymentInterval: e.paymentInterval,
        highlightBindingness: e.highlightBindingness,
        calculationMode: e.calculationMode,
      }),
    key: 'paymentInterval',
    shouldCellUpdate: (f, s) => f.paymentInterval !== s.paymentInterval,
  },
  {
    className: classes.activeColumn,
    key: 'active',
    title: t('admin.itemTable.columns.active'),
    render: (item) => (
      <div className="center">
        <ActiveInputMemo _id={item._id} active={item.active} />
      </div>
    ),
  },
  {
    className: 'action-column-2',
    title: t('admin.itemTable.columns.actions'),
    key: 'actions',
    shouldCellUpdate: (f, s) => f._id !== s._id,
    render: ({ _id, name, categoryId, inputFields, pricingFormulaExtended }) => {
      return (
        <div className="center">
          <Button
            className="ant-btn-default"
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editItem(_id)}
          />
          <ItemDropdown
            onClickCopy={() =>
              copyCategoryItemModal({
                _id,
                categoryId,
                name,
                isLibrary,
                isComplex: isCategoryItemComplex({ pricingFormulaExtended, inputFields }),
              })
            }
            onClickDelete={() => deleteCategoryItemWithConfirmation({ _id, isLibrary })}
            onClickDuplicate={() => duplicateExistingCategoryItem({ _id, name, isLibrary, editItem })}
          />
        </div>
      );
    },
  },
];

const ItemsContainer = ({ _id, items }) => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const showDigits = useShowDigitsContext();
  const navigate = useNavigate();
  const editItem = useCallback(
    (itemId) => {
      const item = apollo.readFragment({
        fragment: adminItemFragment,
        fragmentName: 'adminItemFragment',
        id: `CategoryItem___${itemId}`,
      });
      if (!item) return;
      let path = routePatterns.itemCatalogueConfiguration.stringify({ id: item._id, categoryId: item.categoryId });
      if (isLibrary)
        path = routePatterns.commonLibraryItemConfiguration.stringify({ id: item._id, categoryId: item.categoryId });
      navigate(path);
    },
    [isLibrary, navigate],
  );
  const columns = useMemo(
    () => categoryItemsTableColumns({ t, editItem, isLibrary, showDigits }),
    [editItem, isLibrary, showDigits, t],
  );

  const onClick = useCallback(
    (e) => {
      const itemId = e.target?.parentElement?.dataset?.rowKey;
      editItem(itemId);
    },
    [editItem],
  );

  const moveInCache = useCallback((newData) => moveCategoryItemCache({ _id, items: newData }), [_id]);

  const { onSortEnd } = useSortEnd({
    dataSource: items,
    moveInCache,
    moveMutation: moveCategoryItem,
    refetchQuery: adminCategoryListQuery,
    refetchQueryVariables: { isLibrary },
  });

  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: items, onSortEnd });

  return (
    <div className={classes.itemsContainer}>
      <h4>{t('admin.itemTable.title')}</h4>
      <div className="table-wrapper">
        <TableMemo
          className="table-cell-break-all pointer-not-last-cell"
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyItemListMessage')} /> }}
          expandable={false}
          rowKey="_id"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          onRow={() => ({
            onClick,
          })}
          pagination={false}
          dataSource={items}
          columns={columns}
          scroll={{
            x: 640,
          }}
        />
      </div>
      <br />
      <Button
        icon={<PlusOutlined />}
        onClick={() => addNewCategoryItem({ categoryId: _id, isLibrary, editItem })}
        type="primary"
      >
        {t('admin.addItemButton')}
      </Button>
    </div>
  );
};

export default memo(ItemsContainer, equal);
