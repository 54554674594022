import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useSigningContext from 'hooks/signing/useSigningContext';
import classNames from 'classnames';
import AgreementsForm from './AgreementsForm';
import DocumentsList from './DocumentsList';
import SigningButton from './SigningButton';
import CustomInfoAlert from '../CustomInfoAlert';

import classes from './ViewDocumentsAndSignContent.module.less';

/**
 * ViewDocumentsAndSignContent component is a step component for viewing and signing documents in the signing process.
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference object that gets assigned the onContinue and onBack functions of the step
 * @param {Object} inputParameters.stepState - The current saved state of the step
 * @param {Function} inputParameters.updateCurrentStepState - Function to update the current step state
 * @returns {JSX.Element} A step component for viewing documents and capturing signatures
 */
const ViewDocumentsAndSignContent = ({ submitReference, stepState, updateCurrentStepState }) => {
  const { t: translation } = useTranslation();
  const { signingContext } = useSigningContext();
  const submitReferenceForForm = useRef();
  const signingButtonReference = useRef();
  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    onContinue: async () => {
      if (signingButtonReference?.current?.onContinue) {
        const signatureImage = signingButtonReference?.current?.onContinue();
        if (signatureImage) {
          return {
            stepState: {
              checkNoticeOfCancellation: true,
              checkDocumentsSeen: true,
              signed: true,
              signatureImage,
            },
          };
        }
        return false;
      }
      return false;
    },
    onBack: async () => {
      if (signingButtonReference?.current?.onBack) return signingButtonReference.current.onBack();
      return true;
    },
  };

  const isTenantSignee = signingContext?.typeOfSignee === 'TENANT';
  const tenantEmail = signingContext?.officeEmail || '';
  const components = {
    br: <br />,
    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
    mailto: <a href={`mailto:${tenantEmail}`} />,
    p: <p className={classes.contactUsLine} />,
  };
  const values = {
    tenantEmail,
  };
  return (
    <>
      <div className={classes.introText}>
        <CustomInfoAlert
          message={translation('signing.viewDocumentsAndSignStep.introtext.headline')}
          description={
            <Trans i18nKey="signing.viewDocumentsAndSignStep.introtext.text" components={components} values={values} />
          }
          icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
        />
      </div>
      <div className={classes.stepWrapper}>
        <DocumentsList />
      </div>
      <div className={classNames(classes.stepWrapper, classes.formItemFix)}>
        <AgreementsForm
          isPrivatePerson={signingContext?.isPrivatePerson}
          submitReference={submitReferenceForForm}
          stepState={stepState}
          isTenantSignee={isTenantSignee}
        />
      </div>
      <div className={classes.stepWrapper}>
        <SigningButton
          agreementFormReference={submitReferenceForForm}
          buttonReference={signingButtonReference}
          signatureImage={stepState?.signatureImage}
          updateCurrentStepState={updateCurrentStepState}
        />
      </div>
    </>
  );
};

export default ViewDocumentsAndSignContent;
