import { gql } from '@apollo/client';
import adminInputFieldFragment from './adminInputFieldFragment';

const adminItemFragment = gql`
  fragment adminItemFragment on CategoryItem {
    _id
    active
    benefits
    calculationMode
    categoryId
    companyTypeIds
    descriptionForContract
    graduatedScaleMode
    guidanceText
    guidanceVideoId
    highlightBindingness
    infoText
    internalNote
    minPrice
    maxPrice
    name
    notesForFeeAgreement
    notesToEmployee
    order
    paymentInterval
    pleaseNote
    pricingFormula
    subTitle
    category {
      _id
      name
    }
    recursiveFormulaInfo {
      _id
      _apolloCacheKey
      usedItemIdsInFormula
      usedIdsInFormula
      usedCategoryTotalIds
      usedCategoryTotalItemIds
      formulaRequiredIds
      formulaRequiredItemIds
      moreInfo
    }
    inputFields {
      ...adminInputFieldFragment
    }
    scales {
      _id
      value
      pricingFormula
    }
    scalesEnabled
    scalesScaleUnitName
    scalesUnitName
    scaleTitle
    scalePricingFormulaTitle
    roundPriceId
    pricingFormulaExtended {
      _id
      _apolloCacheKey
      raw
      isComplex
      formatted
      styledFormatted
      inputFields {
        _id
        name
      }
    }
    commonLibraryCategoryId
    originalCommonLibraryId
  }
  ${adminInputFieldFragment}
`;

export default adminItemFragment;
