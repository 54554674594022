import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import WarningsComponent from 'components/common/WarningsComponent';
import UserList from './UserList';
import UserInvitation from './UserInvitation';
import ManageUsersHelperWidget from './ManageUsersHelperWidget';

/**
 * Renders the ManageUsers section with warnings, user invitations, and user list components.
 * @returns {JSX.Element} The ManageUsers component
 * @component
 */
const ManageUsers = () => {
  return (
    <>
      <WarningsComponent warningsTypes={['nrOfUsers']} />
      <UserInvitation />
      <UserList />
    </>
  );
};

/**
 * Layout component for managing users .
 * @returns {JSX.Element} The ManageUsersLayout component
 * @component
 */
const ManageUsersLayout = () => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.ManageUsers.title')} left={<ManageUsersHelperWidget />}>
      <ManageUsers />
    </PageContainer>
  );
};

export default memo(ManageUsersLayout, equal);
