import { useFormikContext } from 'formik';
import { useCallback } from 'react';

/**
 * Class to manage phone number parsing, formatting, and value storage.
 * @class
 */
class PhoneControllerClass {
  /**
   * Default phone value with code and phone fields.
   * @type {object}
   * @property {number} code - Country code
   * @property {string} phone - Phone number
   */
  phoneValue = { code: 49, phone: '' };

  /**
   * Parses a phone string into a structured object with code and phone fields.
   * @param {string} string - The phone string in format "code-phone".
   * @returns {object} Parsed phone value with code and phone fields.
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  #parse(string) {
    const tmpDefaultValue = { code: 49, phone: '' };

    if (!string) return tmpDefaultValue;

    const indexHyphen = string.indexOf('-');

    if (indexHyphen === -1) {
      return { ...tmpDefaultValue, phone: string };
    }
    return {
      code: +string.substr(0, indexHyphen) || 49,
      phone: string.substr(indexHyphen + 1),
    };
  }

  /**
   * Formats a phone object into a string.
   * @param {object} param - The phone data.
   * @param {number} param.code - Country code.
   * @param {string} param.phone - Phone number.
   * @returns {string} Formatted phone string in format code-phone.
   */
  // eslint-disable-next-line class-methods-use-this
  stringify({ code, phone }) {
    return `${code}-${phone}`;
  }

  /**
   * Initializes phone values based on input string, setting a default if needed.
   * @param {string} value - Initial phone string value.
   * @returns {object} The parsed phone object with code and phone fields.
   */
  getInitialPhoneValues(value) {
    this.phoneValue = this.#parse(value) || { code: 49, phone: '' };
    return this.phoneValue;
  }

  /**
   * Updates the stored phone value.
   * @param {object} newPhoneValue - New phone data to store.
   * @returns {object} Updated phone object.
   */
  updatePhoneValue(newPhoneValue) {
    this.phoneValue = newPhoneValue;
    return this.phoneValue;
  }
}

const PhoneController = new PhoneControllerClass();

/**
 * Custom hook for managing phone input state in Formik.
 * @param {string} name - The name of the Formik field to manage.
 * @returns {object} Phone input data and change handler.
 * @returns {object} return.phoneValue - Current phone value (code and phone).
 * @returns {function} return.onChange - Change handler for phone input.
 */
const usePhoneNumber = (name) => {
  const { values, setFieldValueAndTouched } = useFormikContext();

  PhoneController.getInitialPhoneValues(values[name]);

  const onChange = useCallback(
    ({ code, phone, short }) => {
      if (phone.length >= 60 || !phone.match(/^[0-9/-\s]*$/)) return;
      const newPhoneValue = { code, phone, short };
      PhoneController.updatePhoneValue(newPhoneValue);
      setFieldValueAndTouched(name, PhoneController.stringify(newPhoneValue));
    },
    [name, setFieldValueAndTouched],
  );

  return { phoneValue: PhoneController.phoneValue, onChange };
};

export default usePhoneNumber;
