// import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'formik-antd';
import { Row, Col, Card, Typography } from 'antd';
import * as Yup from 'yup';
import { updateDocumentTemplate } from 'graphql/methods';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import i18n from 'i18n';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useLibraryContext } from 'contexts/LibraryContext';
import AccessGroupsMultiSelect from 'components/common/AccessGroupsMultiSelect';
import { PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/placeholders';
import { DocumentFileNameRichEditor } from './DocumentFileNameRichEditor';

const getFormInitialValues = (documentTemplate) => {
  return {
    name: documentTemplate.name || '',
    description: documentTemplate.description || '',
    fileName: documentTemplate.fileName || '',
    commonLibraryAccessGroups: documentTemplate.commonLibraryAccessGroups || [],
  };
};

const generalInfoSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .label(i18n.t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nameInputLabel'))
      .required(),
    description: Yup.string().label(
      i18n.t('admin.DocumentTemplateConfigurationPage.generalInformationSection.descriptionInputLabel'),
    ),
    fileName: Yup.string().label(
      i18n.t('admin.DocumentTemplateConfigurationPage.generalInformationSection.fileNameInputLabel'),
    ),
  });
const GeneralInfoFormComponent = ({ templateId, initialValues, lastUsedDocumentNr, nrOfDocumentsCreated }) => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();

  return (
    <Card title={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.title')} className="xs-mb-20">
      <I18nFormik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          const { name, description, fileName, commonLibraryAccessGroups } = values;
          await updateDocumentTemplate({
            _id: templateId,
            modifier: {
              name,
              description,
              fileName,
              commonLibraryAccessGroups: isLibrary ? commonLibraryAccessGroups : undefined,
            },
          }).catch(formik.setErrors);
        }}
        validationSchema={generalInfoSchema()}
        enableReinitialize
      >
        <Form layout="vertical">
          <Row gutter={[10, 10]}>
            <Col xs={24} md={24}>
              <AlertFromFormik />
              <FormItem
                name="name"
                label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nameInputLabel')}
                tooltip={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nameInputTooltip')}
              >
                <Input name="name" />
              </FormItem>
              <FormItem
                name="description"
                label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.descriptionInputLabel')}
                tooltip={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.descriptionInputTooltip')}
              >
                <Input.TextArea name="description" autoSize />
              </FormItem>
              <DocumentFileNameRichEditor
                name="fileName"
                label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.fileNameInputLabel')}
                allowedUses={[PLACEHOLDER_USE.DOCUMENTS]}
                rootElement="paragraph"
                tooltip={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.fileNameInputTooltip')}
              />
              {isLibrary ? (
                <AccessGroupsMultiSelect
                  fieldName="commonLibraryAccessGroups"
                  label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.accessGroups.label')}
                  tooltip={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.accessGroups.tooltip')}
                  placeholder={t(
                    'admin.DocumentTemplateConfigurationPage.generalInformationSection.accessGroups.placeholder',
                  )}
                />
              ) : null}
            </Col>
            <Col xs={24} md={24}>
              <Typography.Text type="secondary">
                {lastUsedDocumentNr ? (
                  <>
                    {t('admin.DocumentTemplateConfigurationPage.generalInformationSection.lastUsedDocumentNr', {
                      n: lastUsedDocumentNr,
                    })}
                    <br />
                  </>
                ) : null}
                {t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nrOfDocumentsCreated', {
                  count: nrOfDocumentsCreated,
                })}
                <br />
                {/* {formatDateTime(new Date(documentTemplate.createdAt))} */}
              </Typography.Text>
            </Col>
          </Row>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
};
const MemoGeneralInfoFormComponent = memo(GeneralInfoFormComponent, equal);
export default function GeneralInfoForm({ generalInfo }) {
  const initialValues = getFormInitialValues(generalInfo);

  return (
    <MemoGeneralInfoFormComponent
      templateId={generalInfo._id}
      initialValues={initialValues}
      lastUsedDocumentNr={generalInfo.lastUsedDocumentNr}
      nrOfDocumentsCreated={generalInfo.nrOfDocumentsCreated}
    />
  );
}
