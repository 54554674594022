import React from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import useHealthCheck from 'hooks/outOfService/useHealthCheck';
import classes from './OutOfServiceCardContent.module.less';

const { Title, Text } = Typography;

/**
 * Card content for out of service page
 * @returns {JSX.Element} out of service card
 * @component
 */
const OutOfServiceCardContent = () => {
  const { t } = useTranslation();
  const { isChecking, countdown, handleButtonClick } = useHealthCheck();

  return (
    <div className={classes.flexContainer}>
      <Title level={1} className={classes.title}>
        {t('global.outOfService.title')}
      </Title>
      <Text className={classes.text}>{t('global.outOfService.message')}</Text>
      <Text className={classes.text}>
        <Trans i18nKey="global.outOfService.infoText">
          <button type="button" className={classes.button} onClick={handleButtonClick} disabled={isChecking}>
            klicke hier
          </button>
        </Trans>
      </Text>
      {countdown > 0 && (
        <Text className={classes.nextTry}>{t('global.outOfService.nextTry', { seconds: countdown })}</Text>
      )}
    </div>
  );
};

export default OutOfServiceCardContent;
