import React from 'react';
import AddOrEditContactForm from './AddOrEditContactForm';
import ContactNameFields from './ContactNameFields';
import ContactFullSalutationField from './ContactFullSalutationField';
import ContactPositionField from './ContactPositionField';
import ContactEmailField from './ContactEmailField';
import ContactPhoneNumberField from './ContactPhoneNumberField';
import ContactPhoneNumberAlternativeField from './ContactPhoneNumberAlternativeField';

/**
 * Component that renders the content for adding or editing a contact.
 * @param {object} props - The properties passed to the component.
 * @param {object} props.formikRef - Reference to Formik for form control and submission.
 * @param {object} props.errorRef - Reference to handle form error state.
 * @param {function} props.setSubmitting - Function to set the submitting state.
 * @param {string} props.contactId - ID of the contact to be edited; if not provided, a new contact will be added.
 * @param {string} props.clientId - ID of the client associated with the contact.
 * @returns {JSX.Element} The rendered AddOrEditContactContent component.
 */
const AddOrEditContactContent = ({ formikRef, errorRef, setSubmitting, contactId, clientId }) => {
  return (
    <AddOrEditContactForm
      formikRef={formikRef}
      setSubmitting={setSubmitting}
      errorRef={errorRef}
      contactId={contactId}
      clientId={clientId}
    >
      <ContactNameFields />
      <ContactFullSalutationField />
      <ContactPositionField />
      <ContactEmailField />
      <ContactPhoneNumberField />
      <ContactPhoneNumberAlternativeField />
    </AddOrEditContactForm>
  );
};

export default AddOrEditContactContent;
